'use client';

import { Stack, useMedia } from 'tamagui';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Alert } from '../Alert';
import { DisplayUploadedDoc } from './DisplayUploadedDoc';
import { MobileFileUpload } from './MobileFileUpload';
import { WebFileUpload } from './WebFileUpload';

export const FILE_SIZE_LIMIT = 5000000; // 5MB

type LoanDocumentCategory =
  | 'BANK_STATEMENTS'
  | 'BAS'
  | 'TAX_RETURN'
  | 'VEHICLE_TAX_INVOICE'
  | 'FINAL_TAX_INVOICE'
  | 'INSURANCE'
  | 'ATO_PORTAL_STATEMENT'
  | 'NOTICE_OF_ASSESSMENT'
  | 'OTHER_DOCUMENTS'
  | 'SELFIE'
  | 'DRIVER_LICENSE'
  | 'MANAGEMENT_ACCOUNT';

type OnboardingDocumentCategory = 'PARTNERSHIP_DEED' | 'TRUST_DEED' | 'TRUST_UNIT_HOLDERS_REGISTER';

type DocumentCategory = LoanDocumentCategory | OnboardingDocumentCategory;

//General utils to handle promises in dictionaries
interface AnyDictionary {
  [key: string]: unknown;
}
async function resolveDictPromises(dict: AnyDictionary) {
  const entries = Object.entries(dict);
  const resolvedEntries = await Promise.all(
    entries.map(async ([key, promise]) => {
      const resolvedValue = await promise;
      return [key, resolvedValue];
    })
  );
  const resolvedDict: AnyDictionary = Object.fromEntries(resolvedEntries);
  return resolvedDict;
}
async function resolveArrayOfDictPromises(arrayOfDicts: AnyDictionary[]) {
  const resolvedDicts = await Promise.all(arrayOfDicts.map(resolveDictPromises));
  return resolvedDicts;
}

//Doc format for sops
export interface Document {
  fileName: string;
  category: string;
  data: Uint8Array;
  size: number;
  type: string;
  visibleToUser?: boolean;
}

//Document from input element
interface InputDocument {
  fileName: string;
  category: string;
  data: ArrayBuffer;
  size: number;
  type: string;
}

//Custom type for form render element
export interface UploadedFile {
  id: string;
  name: string;
}

//Props for file upload
export interface FileUploadProps {
  testId?: string;
  /**
   * Metadata for files that have been uploaded. The list of names to be displayed.
   */
  files: UploadedFile[];
  /**
   * The category of the documents
   */
  category: DocumentCategory;
  /**
   * Types of files allowed
   */
  fileTypes: string[];
  /**
   * Error message to display
   */
  errorMessage?: string | undefined;
  /**
   * Remove a file by file name
   */
  onDeleteDoc?: (fileId: string) => Promise<void> | void;
  /**
   * Add a file by file name
   */
  onAddDoc: (formattedFiles: Document[]) => Promise<void> | void;
  /**
   * File upload is invalid. e.g wrong format from drag and drop - error in
   */
  onInvalidFileUploaded: (category: string, message: string) => void;
  /**
   * Whether file upload is disabled
   */
  disabled?: boolean;
  /**
   * Whether file input should accept multiple files selected at once
   */
  multiple?: boolean;
}

//Display Uploaded file
export const FileUpload = ({
  testId = 'file-upload',
  files,
  category,
  fileTypes,
  errorMessage,
  onDeleteDoc,
  onAddDoc,
  onInvalidFileUploaded,
  disabled,
  multiple = true,
}: FileUploadProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const media = useMedia();

  const { t } = useTranslation();

  //Prepare docs for upload
  const onFormatDocuments = async (newFiles: FileList | null) => {
    if (newFiles !== null) {
      //Check file size is valid
      if (Array.from(newFiles).some((file) => file.size > FILE_SIZE_LIMIT)) {
        onInvalidFileUploaded(
          category,
          'The document failed to be processed. Please make sure it is not larger than 5MB.'
        );
        return;
      }

      const blobFiles = Array.from(newFiles).map((file: File) => {
        return {
          fileName: file.name,
          category,
          data: file.arrayBuffer(),
          size: file.size,
          type: file.type,
        };
      });

      //Resolve array buffers
      const arrayBufferFiles = (await resolveArrayOfDictPromises(blobFiles)) as unknown as InputDocument[];

      //Convert to unit 8 array
      const formattedUnit8Files: Document[] = arrayBufferFiles.map((file: InputDocument) => {
        file.data = new Uint8Array(file.data);
        return file as Document;
      });
      setIsLoading(true);
      await onAddDoc(formattedUnit8Files);
      setIsLoading(false);
    } else {
      onInvalidFileUploaded(category, t('lending.origination.conditionalOffer.documentUpload.AVfail_MALWARE'));
    }
  };

  return (
    <Stack gap="$space.lg">
      {files.length > 0 ? (
        <DisplayUploadedDoc category={category} files={files} onDeleteDoc={onDeleteDoc} disabled={disabled} />
      ) : null}
      {errorMessage ? (
        <Alert severity="danger" variant="inline-transparent">
          {errorMessage}
        </Alert>
      ) : null}
      {media.laptop || media.desktop ? (
        <WebFileUpload
          testId={`${testId}-web-upload`}
          fileTypes={fileTypes}
          processingFile={isLoading}
          onChangeUpload={onFormatDocuments}
          error={errorMessage !== undefined}
          disabled={disabled}
          multiple={multiple}
        />
      ) : (
        <MobileFileUpload
          testId={`${testId}-mobile-upload`}
          fileTypes={fileTypes}
          processingFile={isLoading}
          onChangeUpload={onFormatDocuments}
          disabled={disabled}
          multiple={multiple}
        />
      )}
    </Stack>
  );
};
