import { Svg, Path } from 'react-native-svg';
import type { IconProps } from '@tamagui/helpers-icon';
import { themed } from '@tamagui/helpers-icon';
import { memo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- from tamagui/lucide-icons
const Icon = (props: any) => {
  const { color = 'black', size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 96 960 960" stroke={color} {...otherProps}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M 323.557 944.644 C 372.159 965.628 424.09 976.122 479.351 976.122 C 534.61 976.122 586.541 965.628 635.143 944.644 C 683.747 923.683 726.023 895.231 761.976 859.277 C 797.928 823.326 826.383 781.048 847.343 732.446 C 868.327 683.84 878.822 631.91 878.822 576.652 C 878.822 521.391 868.327 469.46 847.343 420.857 C 826.383 372.256 797.928 329.977 761.976 294.026 C 726.023 258.075 683.747 229.605 635.143 208.619 C 586.541 187.66 534.61 177.181 479.351 177.181 C 424.09 177.181 372.159 187.66 323.557 208.619 C 274.953 229.605 232.676 258.075 196.723 294.026 C 160.772 329.977 132.317 372.256 111.356 420.857 C 90.372 469.46 79.878 521.391 79.878 576.652 C 79.878 631.91 90.372 683.84 111.356 732.446 C 132.317 781.048 160.772 823.326 196.723 859.277 C 232.676 895.231 274.953 923.683 323.557 944.644 Z M 427.499 473.815 L 299.587 473.815 L 299.587 395.344 L 375.985 395.344 L 375.985 316.996 L 452.258 316.996 L 452.258 395.302 L 524.267 395.302 C 598.353 395.302 650.192 437.912 650.192 512.982 C 650.192 541.181 635.66 569.794 616.457 587.289 C 647.188 607.415 667.63 642.743 667.63 683.035 C 667.63 744.474 620.132 794.399 560.761 796.361 L 387.065 796.361 C 380.368 795.734 375.985 791.48 375.985 783.347 L 375.985 493.131 L 427.499 473.815 Z M 451.129 471.691 L 534.016 471.691 C 555.82 471.691 573.378 490.836 573.378 513.089 C 573.378 535.424 555.82 553.526 534.016 553.526 L 519.688 554.296 C 511.998 554.61 507.999 559.26 507.736 566.518 L 507.736 616.196 C 508.284 624.1 512.973 628.206 520.339 628.206 L 555.942 628.206 C 579.584 628.206 598.726 647.918 598.726 672.106 C 598.726 695.048 581.474 713.776 559.714 715.648 L 463.265 715.732 C 456.101 715.543 451.415 711.29 451.129 703.219 L 451.129 471.691 Z"
      />
    </Svg>
  );
};

Icon.displayName = 'BPay';

export const BPay = memo<IconProps>(themed(Icon));
