import { Svg, Path } from 'react-native-svg';
import type { IconProps } from '@tamagui/helpers-icon';
import { themed } from '@tamagui/helpers-icon';
import { memo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- from tamagui/lucide-icons
const Icon = (props: any) => {
  const { color = 'black', size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...otherProps}>
      <Path
        d="M7.5 16V17C7.5 17.2833 7.59567 17.5207 7.787 17.712C7.979 17.904 8.21667 18 8.5 18C8.78333 18 9.021 17.904 9.213 17.712C9.40433 17.5207 9.5 17.2833 9.5 17V16H10.5C10.7833 16 11.021 15.904 11.213 15.712C11.4043 15.5207 11.5 15.2833 11.5 15C11.5 14.7167 11.4043 14.479 11.213 14.287C11.021 14.0957 10.7833 14 10.5 14H9.5V13C9.5 12.7167 9.40433 12.479 9.213 12.287C9.021 12.0957 8.78333 12 8.5 12C8.21667 12 7.979 12.0957 7.787 12.287C7.59567 12.479 7.5 12.7167 7.5 13V14H6.5C6.21667 14 5.979 14.0957 5.787 14.287C5.59567 14.479 5.5 14.7167 5.5 15C5.5 15.2833 5.59567 15.5207 5.787 15.712C5.979 15.904 6.21667 16 6.5 16H7.5ZM18.75 14.5C18.9667 14.5 19.146 14.429 19.288 14.287C19.4293 14.1457 19.5 13.9667 19.5 13.75C19.5 13.5333 19.4293 13.354 19.288 13.212C19.146 13.0707 18.9667 13 18.75 13H14.25C14.0333 13 13.8543 13.0707 13.713 13.212C13.571 13.354 13.5 13.5333 13.5 13.75C13.5 13.9667 13.571 14.1457 13.713 14.287C13.8543 14.429 14.0333 14.5 14.25 14.5H18.75ZM16.75 17.5C16.9667 17.5 17.146 17.4293 17.288 17.288C17.4293 17.146 17.5 16.9667 17.5 16.75C17.5 16.5333 17.4293 16.354 17.288 16.212C17.146 16.0707 16.9667 16 16.75 16H14.25C14.0333 16 13.8543 16.0707 13.713 16.212C13.571 16.354 13.5 16.5333 13.5 16.75C13.5 16.9667 13.571 17.146 13.713 17.288C13.8543 17.4293 14.0333 17.5 14.25 17.5H16.75ZM15.5 7H20.5C21.05 7 21.521 7.19567 21.913 7.587C22.3043 7.979 22.5 8.45 22.5 9V20C22.5 20.55 22.3043 21.021 21.913 21.413C21.521 21.8043 21.05 22 20.5 22H4.5C3.95 22 3.47933 21.8043 3.088 21.413C2.696 21.021 2.5 20.55 2.5 20V9C2.5 8.45 2.696 7.979 3.088 7.587C3.47933 7.19567 3.95 7 4.5 7H9.5V4C9.5 3.45 9.696 2.979 10.088 2.587C10.4793 2.19567 10.95 2 11.5 2H13.5C14.05 2 14.521 2.19567 14.913 2.587C15.3043 2.979 15.5 3.45 15.5 4V7ZM11.5 9H13.5V4H11.5V9ZM9.5 9H4.5V20H20.5V9H15.5C15.5 9.55 15.3043 10.0207 14.913 10.412C14.521 10.804 14.05 11 13.5 11H11.5C10.95 11 10.4793 10.804 10.088 10.412C9.696 10.0207 9.5 9.55 9.5 9Z"
        fill={color}
      />
    </Svg>
  );
};

Icon.displayName = 'Medicare';

export const Medicare = memo<IconProps>(themed(Icon));
