'use client';

import { XStack } from 'tamagui';
import { Stack, styled, withStaticProperties, createStyledContext, getTokenValue } from '@tamagui/web';
import { forwardRef, useId } from 'react';

export interface SwitchProps {
  disabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
  testID?: string;
  checked?: boolean;
  onPress?: () => Promise<void> | void;
}

type TamaguiButtonElement = HTMLButtonElement;

export type SwitchElement = TamaguiButtonElement;
interface SwitchContextValues {
  disabled: boolean;
}

const SwitchStyledContext = createStyledContext<SwitchContextValues>({
  disabled: false,
});

// Switch height token doesn't include the border; without + 2 it looks squished
const SWITCH_BORDER_HEIGHT_OFFSET = 2;

export const SwitchFrame = styled(XStack, {
  context: SwitchStyledContext,
  cursor: 'pointer',
  width: 'max-content',
  borderWidth: 1,
  borderRadius: 1000,
  alignItems: 'center',
  paddingHorizontal: '$xxs',
  style: { transition: 'all 0.25s' },

  variants: {
    checked: {
      true: {
        backgroundColor: '$switch/color/switch-bg-selected',
        borderColor: '$switch/color/switch-border-selected',
      },
      false: {
        backgroundColor: '$switch/color/switch-bg-default',
        borderColor: '$switch/color/switch-border-default',
      },
    },
    disabled: {
      true: {
        backgroundColor: '$background/disabled',
        borderColor: '$border/disabled',
        cursor: 'auto',
      },
    },
    size: {
      sm: {
        height: getTokenValue('$switch/size/sm') + SWITCH_BORDER_HEIGHT_OFFSET,
        width: getTokenValue('$switch/size/sm') - getTokenValue('$xxs') + getTokenValue('$xl'),
      },
      md: {
        height: getTokenValue('$switch/size/md') + SWITCH_BORDER_HEIGHT_OFFSET,
        width: getTokenValue('$switch/size/md') - getTokenValue('$xxs') + getTokenValue('$2xl'),
      },
      lg: {
        height: getTokenValue('$switch/size/lg') + SWITCH_BORDER_HEIGHT_OFFSET,
        width: getTokenValue('$switch/size/lg') - getTokenValue('$xxs') + getTokenValue('$2xl'),
      },
    },
  } as const,
});

export const SwitchThumb = styled(Stack, {
  context: SwitchStyledContext,
  borderRadius: 1000,
  position: 'absolute',
  style: { transition: 'all 0.25s' },

  variants: {
    checked: {
      true: {
        backgroundColor: '$switch/color/switch-icon-selected',
        left: `calc(100% - ${getTokenValue('$xxs')}px)`,
        transform: 'translateX(-100%)',
      },
      false: {
        backgroundColor: '$switch/color/switch-icon-default',
        left: getTokenValue('$xxs'),
        transform: 'translateX(0)',
      },
    },
    disabled: {
      true: {
        backgroundColor: '$foreground/disabled',
      },
    },
    size: {
      sm: {
        width: getTokenValue('$switch/size/sm') - 2 * getTokenValue('$xxs'),
        height: getTokenValue('$switch/size/sm') - 2 * getTokenValue('$xxs'),
      },
      md: {
        width: getTokenValue('$switch/size/md') - 2 * getTokenValue('$xxs'),
        height: getTokenValue('$switch/size/md') - 2 * getTokenValue('$xxs'),
      },
      lg: {
        width: getTokenValue('$switch/size/lg') - 2 * getTokenValue('$xxs'),
        height: getTokenValue('$switch/size/lg') - 2 * getTokenValue('$xxs'),
      },
    },
  } as const,
});

const SwitchInternal = forwardRef<SwitchElement, SwitchProps>(function Switch(props, forwardedRef) {
  const { testID, size = 'sm', disabled, onPress, checked, ...switchProps } = props;

  return (
    <SwitchFrame
      testID={testID}
      checked={checked}
      aria-labelledby={useId()}
      aria-checked={checked}
      data-state={checked ? 'on' : 'off'}
      data-disabled={disabled ? '' : undefined}
      size={size}
      disabled={disabled}
      {...switchProps}
      ref={forwardedRef}
      onPress={() => {
        void onPress?.();
      }}
    >
      <SwitchThumb size={size} checked={checked} disabled={disabled} />
    </SwitchFrame>
  );
});

export const Switch = withStaticProperties(SwitchInternal, {
  Thumb: SwitchThumb,
});
