import { Svg, Path } from 'react-native-svg';
import type { IconProps } from '@tamagui/helpers-icon';
import { themed } from '@tamagui/helpers-icon';
import { memo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- from tamagui/lucide-icons
const Icon = (props: any) => {
  const { color = 'black', size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 96 960 960" stroke={color} {...otherProps}>
      <Path
        d="M 676.483 217.017 L 283.138 217.017 C 194.466 217.017 119.637 289.031 119.637 380.518 L 119.637 771.046 C 119.637 859.718 191.629 934.569 283.138 934.569 L 676.483 934.569 C 765.176 934.569 840.005 862.554 840.005 771.046 L 840.005 380.518 C 840.005 289.031 768.013 217.017 676.483 217.017 Z M 427.145 391.524 L 446.64 388.689 C 477.164 385.853 507.668 388.689 538.149 394.363 C 596.341 402.512 648.837 441.546 674.005 496.878 L 632.516 513.537 C 602.012 441.546 516.178 433.374 477.502 433.374 L 477.502 649.396 L 430.341 649.396 L 430.341 391.886 L 427.145 391.886 L 427.145 391.524 Z M 280.303 422.029 C 280.303 402.512 296.983 385.853 316.478 385.853 C 335.996 385.853 352.655 402.512 352.655 422.029 C 352.655 441.546 335.996 458.205 316.478 458.205 C 294.147 455.369 280.303 441.546 280.303 422.029 Z M 682.154 618.868 C 679.339 632.712 676.483 646.557 671.169 660.38 C 640.689 735.208 565.816 771.046 452.333 771.046 L 288.811 771.046 L 288.811 516.037 L 335.996 516.037 L 335.996 726.722 L 455.172 726.722 C 546.681 726.722 607.687 699.056 629.679 643.699 C 632.516 635.55 635.355 627.04 635.355 618.868 L 596.678 618.868 L 663.018 535.891 L 729.339 618.868 C 729.339 618.868 682.154 618.868 682.154 618.868 Z"
        fill={color}
      />
    </Svg>
  );
};

Icon.displayName = 'PayId';

export const PayId = memo<IconProps>(themed(Icon));
