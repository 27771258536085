import type { PopoverContentProps } from 'tamagui';
import { Adapt, Popover, XStack, Stack, withStaticProperties, isWeb } from 'tamagui';
import type { ReactNode } from 'react';
import React from 'react';
import type { IconProps } from '@cxnpl/ui/icons';
import { Text } from '../Text';
import type { ElevationProps } from '../Elevation';
import { Elevation } from '../Elevation';

export interface MenuProps {
  header?: React.ReactElement;
  items: { label: string; icon?: React.NamedExoticComponent<IconProps>; onPress: () => void; id: string }[];
  children: React.ReactElement;
  elevation?: ElevationProps['level'];
  popoverProps?: PopoverContentProps;
  testID?: string;
}

// Not sure why this is needed but native doesn't trigger if the props aren't passed to the children
const TriggerWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Popover.Trigger asChild>
      <Stack asChild={!isWeb}>{children}</Stack>
    </Popover.Trigger>
  );
};

const MenuBase = ({ header, items, children, elevation = 1, popoverProps, testID }: MenuProps) => {
  return (
    <Popover size="$xs" allowFlip placement="bottom">
      <TriggerWrapper>{children}</TriggerWrapper>

      <Adapt when="mobile" platform="touch">
        <Popover.Sheet modal dismissOnSnapToBottom snapPointsMode="fit">
          <Popover.Sheet.Frame padding="$md">
            <Adapt.Contents />
          </Popover.Sheet.Frame>
          <Popover.Sheet.Overlay
            animation="lazy"
            enterStyle={{ opacity: 0 }}
            exitStyle={{ opacity: 0 }}
            opacity={0.4}
          />
        </Popover.Sheet>
      </Adapt>

      <Popover.Content
        disableFocusScope
        marginTop="$sm"
        padding={0}
        borderWidth={0}
        borderRadius="$surface/radius/surface-radius"
        enterStyle={{ y: -10, opacity: 0 }}
        exitStyle={{ y: -10, opacity: 0 }}
        elevate
        animation={[
          'quick',
          {
            opacity: {
              overshootClamping: true,
            },
          },
        ]}
        {...popoverProps}
      >
        <Elevation
          testID={testID}
          level={elevation}
          borderRadius="$surface/radius/surface-radius"
          backgroundColor="$background/surface"
          overflow="hidden"
        >
          {header ? (
            <XStack padding="$lg" borderBottomWidth={3} borderColor="$border/surface-subdued">
              {header}
            </XStack>
          ) : null}
          {items.map((item, index, arr) => {
            return (
              <React.Fragment key={item.id}>
                <Popover.Close asChild flexDirection="row">
                  <XStack
                    alignItems="center"
                    testID={item.id}
                    padding="$lg"
                    onPress={() => {
                      item.onPress();
                    }}
                    flexGrow={1}
                    cursor="pointer"
                    hoverStyle={{
                      backgroundColor: '$background/primary-hover',
                    }}
                  >
                    <Stack width="$lg">{item.icon ? <item.icon size="$icon/size/sm" /> : null}</Stack>
                    <Text>{item.label}</Text>
                  </XStack>
                </Popover.Close>
                {index < arr.length - 1 && <Stack borderBottomColor="$border/app-subdued" borderBottomWidth={1} />}
              </React.Fragment>
            );
          })}
        </Elevation>
      </Popover.Content>
    </Popover>
  );
};

export const Menu = withStaticProperties(MenuBase, {
  Close: Popover.Close,
});
