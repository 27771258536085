import { Fieldset, YStack } from 'tamagui';
import type { TypeaheadFieldProps } from '../TypeaheadField';
import { TypeaheadField } from '../TypeaheadField';

export type PayeesFieldProps<T> = TypeaheadFieldProps<T>;

export function PayeesField<T>(props: PayeesFieldProps<T>): JSX.Element {
  return (
    <Fieldset>
      <YStack space="$lg">
        <TypeaheadField<T> {...props} />
      </YStack>
    </Fieldset>
  );
}
