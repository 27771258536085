import { withStaticProperties } from '@tamagui/web';
import { styled, type VariantSpreadFunction } from '@tamagui/core';
import type { StackProps } from '@tamagui/web/types/types';
import { XStack } from 'tamagui';
import { Text } from '../Text';

const USER_NUMBER_FORMAT = 'au';

//Frame for main text and decimal component to sit in
const CurrencyDisplayBaseFrame = styled(XStack, {
  name: 'CurrencyDisplay',
  alignItems: 'baseline',
} as const);

type Appearance = 'danger' | 'info' | 'success' | 'warning' | 'attention' | 'default' | 'subdued';

interface CurrencyDisplayVariants {
  appearance?: Appearance;
  inverse?: true | false;
  size?: 'md' | 'lg' | 'xl';
}

const variantStylesGetter: VariantSpreadFunction<StackProps & CurrencyDisplayVariants, string> = (_value, extras) => {
  const { appearance, inverse } = extras.props as Required<CurrencyDisplayVariants>;

  let color: string;
  switch (appearance) {
    case 'danger':
      color = !inverse ? '$alert/color/danger/alert-text-danger' : '$alert/color/danger/alert-bg-danger';
      break;
    case 'info':
      color = !inverse ? '$alert/color/info/alert-fg-info' : '$alert/color/info/alert-bg-info';
      break;
    case 'success':
      color = !inverse ? '$alert/color/success/alert-fg-success' : '$alert/color/success/alert-bg-success';
      break;
    case 'warning':
      color = !inverse ? '$alert/color/warning/alert-fg-warning' : '$alert/color/warning/alert-bg-warning';
      break;
    case 'attention':
      color = !inverse ? '$alert/color/attention/alert-fg-attention' : '$alert/color/attention/alert-bg-attention';
      break;
    case 'subdued':
      color = !inverse ? '$text/surface-subdued' : '$text/surface-inverse-subdued';
      break;
    default:
      color = !inverse ? '$foreground/surface-default' : '$foreground/surface-inverse';
      break;
  }

  return {
    color,
  } as StackProps;
};

//Main text
const CurrencyDisplayMainText = styled(Text, {
  name: 'CurrencyDisplayMainText',
  variants: {
    size: {
      md: {
        variant: 'bodyMediumEm',
      },
      lg: {
        variant: 'bodyLargeEm',
      },
      xl: {
        variant: 'titleLarge',
      },
    },
    inverse: {
      true: {},
      false: {},
    },
    appearance: {
      danger: variantStylesGetter,
      info: variantStylesGetter,
      success: variantStylesGetter,
      warning: variantStylesGetter,
      attention: variantStylesGetter,
      default: variantStylesGetter,
      subdued: variantStylesGetter,
    },
  },
} as const);

//Smaller decimal text
const CurrencyDisplayDecimalText = styled(Text, {
  name: 'CurrencyDisplayDecimalText',
  variants: {
    size: {
      md: {
        variant: 'bodySmallEm',
      },
      lg: {
        variant: 'bodySmallEm',
      },
      xl: {
        variant: 'titleSmall',
      },
    },
    inverse: {
      true: {},
      false: {},
    },
    appearance: {
      danger: variantStylesGetter,
      info: variantStylesGetter,
      success: variantStylesGetter,
      warning: variantStylesGetter,
      attention: variantStylesGetter,
      default: variantStylesGetter,
      subdued: variantStylesGetter,
    },
  },
} as const);

const CurrencyDisplayBase = withStaticProperties(CurrencyDisplayBaseFrame, {
  MainText: CurrencyDisplayMainText,
  DecimalText: CurrencyDisplayDecimalText,
});

//Props for main currency display component
export interface CurrencyDisplayProps {
  testID?: string;
  children: number;
  decimals?: number;
  showPlus?: boolean;
  size?: 'md' | 'lg' | 'xl';
  inverse?: boolean;
  appearance?: Appearance;
}

//Main currency display component
export const CurrencyDisplay = ({
  testID = 'currencyDisplay',
  children,
  decimals = 2,
  size = 'md',
  showPlus = false,
  inverse = false,
  appearance = 'default',
}: CurrencyDisplayProps) => {
  const { mainText, decimalText } = currencyFormat(decimals, children, showPlus);
  return (
    <CurrencyDisplayBase testID={testID}>
      <CurrencyDisplayBase.MainText inverse={inverse} size={size} appearance={appearance}>
        {mainText}
      </CurrencyDisplayBase.MainText>
      {decimals !== 0 && (
        <CurrencyDisplayBase.DecimalText inverse={inverse} size={size} appearance={appearance}>
          {decimalText}
        </CurrencyDisplayBase.DecimalText>
      )}
    </CurrencyDisplayBase>
  );
};

//Util function to split and process float numbers
const currencyFormat = (decimals: number, num: number, showPlus?: boolean) => {
  const formattedChildren = Intl.NumberFormat(USER_NUMBER_FORMAT).format(num).replace('-', '');
  const plusSign = showPlus ? '+' : '';
  const mainText = `${num < 0 ? '-' : plusSign}$${formattedChildren.split('.')[0]}`;
  const decimalText = `.${num.toFixed(decimals).split('.')[1]}`;
  return { mainText, decimalText };
};
