import { styled, Text as TamaguiText } from 'tamagui';

export const Text = styled(TamaguiText, {
  name: 'Text',
  accessibilityRole: 'text',
  variants: {
    variant: {
      bodySmall: {
        fontSize: '$sm',
        lineHeight: '$sm',
        fontWeight: '$sm',
        fontFamily: '$body',
      },
      bodyMedium: {
        fontSize: '$md',
        lineHeight: '$md',
        fontWeight: '$md',
        fontFamily: '$body',
      },
      bodyLarge: {
        fontSize: '$lg',
        lineHeight: '$lg',
        fontWeight: '$lg',
        fontFamily: '$body',
      },
      titleSmall: {
        fontSize: '$md',
        lineHeight: '$md',
        fontWeight: '$md',
        fontFamily: '$heading',
      },
      titleLarge: {
        fontSize: '$3xl',
        lineHeight: '$3xl',
        fontWeight: '$3xl',
        fontFamily: '$heading',
      },
      bodySmallEm: {
        fontSize: '$sm',
        lineHeight: '$sm',
        fontWeight: '$sm',
        fontFamily: '$bodyEmphasised',
      },
      bodyMediumEm: {
        fontSize: '$md',
        lineHeight: '$md',
        fontWeight: '$md',
        fontFamily: '$bodyEmphasised',
      },
      bodyLargeEm: {
        fontSize: '$lg',
        lineHeight: '$lg',
        fontWeight: '$lg',
        fontFamily: '$bodyEmphasised',
      },
      linkSmall: {
        fontSize: '$sm',
        lineHeight: '$sm',
        fontWeight: '$sm',
        fontFamily: '$link',
        textDecorationLine: 'underline',
        hoverStyle: {
          color: '$button/color/button-primary-text-hover',
        },
        focusStyle: {
          color: '$button/color/button-primary-text-hover',
          borderColor: '$background/transparent',
          outlineWidth: 2,
          outlineStyle: 'solid',
          outlineColor: '$button/color/button-focus-border',
        },
        pressStyle: {
          color: '$button/color/button-primary-text-press',
        },
      },
      linkMedium: {
        fontSize: '$md',
        lineHeight: '$md',
        fontWeight: '$md',
        fontFamily: '$link',
        textDecorationLine: 'underline',
        hoverStyle: {
          color: '$button/color/button-primary-text-hover',
        },
        focusStyle: {
          color: '$button/color/button-primary-text-hover',
          borderColor: '$background/transparent',
          outlineWidth: 2,
          outlineStyle: 'solid',
          outlineColor: '$button/color/button-focus-border',
        },
        pressStyle: {
          color: '$button/color/button-primary-text-press',
        },
      },
      linkLarge: {
        fontSize: '$lg',
        lineHeight: '$lg',
        fontWeight: '$lg',
        fontFamily: '$link',
        textDecorationLine: 'underline',
        hoverStyle: {
          color: '$button/color/button-primary-text-hover',
        },
        focusStyle: {
          color: '$button/color/button-primary-text-hover',
          borderColor: '$background/transparent',
          outlineWidth: 2,
          outlineStyle: 'solid',
          outlineColor: '$button/color/button-focus-border',
        },
        pressStyle: {
          color: '$button/color/button-primary-text-press',
        },
      },
      linkSmallEm: {
        fontSize: '$sm',
        lineHeight: '$sm',
        fontWeight: '$sm',
        fontFamily: '$linkEmphasised',
        textDecorationLine: 'underline',
        hoverStyle: {
          color: '$button/color/button-primary-text-hover',
        },
        focusStyle: {
          color: '$button/color/button-primary-text-hover',
          borderColor: '$background/transparent',
          outlineWidth: 2,
          outlineStyle: 'solid',
          outlineColor: '$button/color/button-focus-border',
        },
        pressStyle: {
          color: '$button/color/button-primary-text-press',
        },
      },
      linkMediumEm: {
        fontSize: '$md',
        lineHeight: '$md',
        fontWeight: '$md',
        fontFamily: '$linkEmphasised',
        textDecorationLine: 'underline',
        hoverStyle: {
          color: '$button/color/button-primary-text-hover',
        },
        focusStyle: {
          color: '$button/color/button-primary-text-hover',
          borderColor: '$background/transparent',
          outlineWidth: 2,
          outlineStyle: 'solid',
          outlineColor: '$button/color/button-focus-border',
        },
        pressStyle: {
          color: '$button/color/button-primary-text-press',
        },
      },
      linkLargeEm: {
        fontSize: '$lg',
        lineHeight: '$lg',
        fontWeight: '$lg',
        fontFamily: '$linkEmphasised',
        textDecorationLine: 'underline',
        hoverStyle: {
          color: '$button/color/button-primary-text-hover',
        },
        focusStyle: {
          color: '$button/color/button-primary-text-hover',
          borderColor: '$background/transparent',
          outlineWidth: 2,
          outlineStyle: 'solid',
          outlineColor: '$button/color/button-focus-border',
        },
        pressStyle: {
          color: '$button/color/button-primary-text-press',
        },
      },
    },
    link: {
      //using lowercased 'accessibilityrole' instead of 'accessibilityRole' because react throws warnings. Both render to lowercase anyway.
      true: { cursor: 'pointer', role: 'link', accessibilityrole: 'link' },
    },
  } as const,
});

export type TextProps = React.ComponentProps<typeof Text>;

export default Text;
