import { Svg, Path } from 'react-native-svg';
import type { IconProps } from '@tamagui/helpers-icon';
import { themed } from '@tamagui/helpers-icon';
import { memo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- from tamagui/lucide-icons
const Icon = (props: any) => {
  const { color = 'black', size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 25 24" fill="none" stroke="none" {...otherProps}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 17C16.5 16.4477 16.0523 16 15.5 16H10.5C9.94772 16 9.5 16.4477 9.5 17C9.5 17.5523 9.94772 18 10.5 18H15.5C16.0523 18 16.5 17.5523 16.5 17Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1876 10L16.5463 4.45134C16.3614 4.16966 16.0472 4 15.7103 4H10.5001C9.94782 4 9.50011 4.44772 9.50011 5V10H2.5C1.39543 10 0.5 10.8954 0.5 12V16C0.5 17.1046 1.39543 18 2.5 18H3.67071C4.08254 19.1652 5.19378 20 6.5 20C7.80622 20 8.91746 19.1652 9.32929 18H15.6707C16.0825 19.1652 17.1938 20 18.5 20C19.8062 20 20.9175 19.1652 21.3293 18H22.5C23.6046 18 24.5 17.1046 24.5 16V14C24.5 11.7909 22.7091 10 20.5 10H20.1876ZM17.7954 10L15.1704 6H11.5001V10H17.7954ZM20.5 12C21.6046 12 22.5 12.8954 22.5 14V16H21.3293C20.9175 14.8348 19.8062 14 18.5 14C17.1938 14 16.0825 14.8348 15.6707 16H9.32929C8.91746 14.8348 7.80622 14 6.5 14C5.19378 14 4.08254 14.8348 3.67071 16H2.5V12H20.5ZM19.5 17C19.5 16.4477 19.0523 16 18.5 16C17.9477 16 17.5 16.4477 17.5 17C17.5 17.5523 17.9477 18 18.5 18C19.0523 18 19.5 17.5523 19.5 17ZM7.5 17C7.5 16.4477 7.05228 16 6.5 16C5.94772 16 5.5 16.4477 5.5 17C5.5 17.5523 5.94772 18 6.5 18C7.05228 18 7.5 17.5523 7.5 17Z"
        fill={color}
      />
    </Svg>
  );
};

Icon.displayName = 'Ute';

export const Ute = memo<IconProps>(themed(Icon));
