import { Svg, G, Path } from 'react-native-svg';
import type { IconProps } from '@tamagui/helpers-icon';
import { themed } from '@tamagui/helpers-icon';
import { memo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- from tamagui/lucide-icons
const Icon = (props: any) => {
  const { color = 'black', size = 24, ...otherProps } = props;
  return (
    <Svg width={size * 2} height={size} viewBox="0 96 1920 960" stroke={color} {...otherProps}>
      <G transform="matrix(2.794766, 0, 0, 2.794766, 200, 217.278168)" style={{}}>
        <Path
          d="M 0 70.22 L 40.54 70.22 C 47.024 70.157 53.496 70.804 59.84 72.15 C 65.126 73.25 70.139 75.399 74.58 78.47 C 78.634 81.356 81.895 85.219 84.06 89.7 C 86.407 94.876 87.546 100.519 87.39 106.2 C 87.39 113.1 86.103 118.89 83.53 123.57 C 81.05 128.156 77.461 132.048 73.09 134.89 C 68.392 137.88 63.186 139.985 57.73 141.1 C 51.637 142.436 45.417 143.106 39.18 143.1 L 22.18 143.1 L 22.18 194.52 L 0 194.52 L 0 70.22 Z M 37.55 124.45 C 41.019 124.464 44.483 124.2 47.91 123.66 C 50.966 123.204 53.928 122.255 56.68 120.85 C 59.174 119.579 61.291 117.675 62.82 115.33 C 64.451 112.573 65.243 109.4 65.1 106.2 C 65.238 103.086 64.444 100.001 62.82 97.34 C 61.315 95.067 59.248 93.221 56.82 91.98 C 54.121 90.644 51.212 89.785 48.22 89.44 C 44.93 89.027 41.616 88.823 38.3 88.83 L 22.11 88.83 L 22.11 124.45 L 37.55 124.45 Z"
          fill={color}
        />
        <Path
          d="M 148.46 183.77 L 147.93 183.77 C 145.53 187.782 141.99 190.988 137.76 192.98 C 132.793 195.447 127.305 196.682 121.76 196.58 C 118.194 196.555 114.643 196.112 111.18 195.26 C 107.554 194.409 104.108 192.922 101 190.87 C 97.857 188.789 95.22 186.032 93.28 182.8 C 91.112 179.047 90.038 174.762 90.18 170.43 C 90.18 164.11 91.963 159.08 95.53 155.34 C 99.375 151.419 104.098 148.471 109.31 146.74 C 115.353 144.656 121.631 143.329 128 142.79 C 134.84 142.123 141.537 141.79 148.09 141.79 L 148.09 139.69 C 148.09 134.42 146.19 130.54 142.39 128.02 C 138.336 125.43 133.599 124.113 128.79 124.24 C 124.367 124.255 119.999 125.231 115.99 127.1 C 112.22 128.761 108.776 131.081 105.82 133.95 L 94.94 121.1 C 99.721 116.664 105.365 113.26 111.52 111.1 C 117.616 108.91 124.043 107.784 130.52 107.77 C 138.013 107.77 144.183 108.823 149.03 110.93 C 153.466 112.723 157.409 115.552 160.53 119.18 C 163.301 122.504 165.308 126.395 166.41 130.58 C 167.51 134.704 168.071 138.952 168.08 143.22 L 168.08 194.46 L 148.46 194.46 L 148.46 183.77 Z M 148.11 156.04 L 143.37 156.04 C 139.983 156.04 136.417 156.187 132.67 156.48 C 129.154 156.724 125.678 157.372 122.31 158.41 C 119.417 159.26 116.729 160.693 114.41 162.62 C 112.282 164.499 111.123 167.244 111.26 170.08 C 111.198 171.839 111.654 173.577 112.57 175.08 C 113.438 176.417 114.61 177.531 115.99 178.33 C 117.462 179.171 119.064 179.763 120.73 180.08 C 122.465 180.429 124.23 180.607 126 180.61 C 133.253 180.61 138.75 178.68 142.49 174.82 C 146.23 170.96 148.103 165.693 148.11 159.02 L 148.11 156.04 Z"
          fill={color}
        />
        <Path
          d="M 174.38 110.24 L 197.72 110.24 L 221.23 171.48 L 221.59 171.48 L 242.47 110.24 L 264.41 110.24 L 224.57 212.55 C 223.201 216.092 221.56 219.522 219.66 222.81 C 218.003 225.672 215.865 228.226 213.34 230.36 C 210.775 232.463 207.823 234.043 204.65 235.01 C 200.752 236.138 196.707 236.671 192.65 236.59 C 190.89 236.59 189.11 236.5 187.3 236.33 C 185.435 236.134 183.586 235.799 181.77 235.33 L 183.5 217.1 C 184.842 217.564 186.225 217.898 187.63 218.1 C 188.88 218.265 190.139 218.352 191.4 218.36 C 193.304 218.408 195.203 218.141 197.02 217.57 C 198.487 217.07 199.811 216.222 200.88 215.1 C 202.012 213.872 202.955 212.484 203.68 210.98 C 204.5 209.34 205.38 207.41 206.32 205.19 L 210.53 194.48 L 174.38 110.24 Z"
          fill={color}
        />
        <Path
          d="M 485.83 0 L 345.18 0 C 313.12 0.028 287.152 26.04 287.18 58.1 L 287.18 198.69 C 287.18 230.742 313.178 256.718 345.23 256.69 L 485.83 256.69 C 517.863 256.69 543.83 230.723 543.83 198.69 L 543.83 58.1 C 543.885 26.028 517.902 0 485.83 0 Z M 436.38 191.58 C 398.698 189.876 376.991 148.02 397.308 116.238 C 405.889 102.814 420.463 94.4 436.38 93.68 L 491.6 93.68 L 491.6 78.48 L 520.01 102.1 L 491.57 125.77 L 491.57 110.24 L 435.67 110.24 C 418.56 110.24 404.41 125.53 404.41 142.63 C 403.344 167.241 429.319 183.777 451.165 172.395 C 462.188 166.652 468.888 155.047 468.35 142.63 C 468.257 135.337 466.077 128.224 462.07 122.13 L 480.83 122.13 C 483.789 128.56 485.324 135.552 485.33 142.63 C 485.566 169.761 463.511 191.816 436.38 191.58 Z M 368.38 174.58 C 372.296 174.389 376.165 173.641 379.87 172.36 L 379.87 189.19 C 376.212 190.665 372.322 191.481 368.38 191.6 C 351.511 191.462 337.936 177.699 338.03 160.83 L 338.03 110.24 L 318.89 110.24 L 318.89 93.68 L 338.05 93.68 L 338.05 72.68 L 354.66 72.68 L 354.66 93.68 L 399.99 93.68 C 393.52 98.12 387.943 103.738 383.55 110.24 L 354.66 110.24 L 354.66 160.81 C 354.704 168.382 360.828 174.511 368.4 174.56 L 368.38 174.58 Z"
          fill={color}
        />
      </G>
    </Svg>
  );
};

Icon.displayName = 'PayToLarge';

export const PayToLarge = memo<IconProps>(themed(Icon));
