'use client';
import { FileText } from '@tamagui/lucide-icons';
import type { ChangeEvent } from 'react';
import { useRef } from 'react';
import { Spinner, Stack } from 'tamagui';
import { useTranslation } from 'react-i18next';
import type { ButtonProps } from '../Button';
import { Button } from '../Button';

//Props for mobile upload
interface MobileFileUploadProps {
  /**
   * Test ID
   */
  testId?: string;
  /**
   * A list of accepted file types.
   * More information: https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept#unique_file_type_specifiers
   */
  fileTypes: string[];
  /**
   * Processing file boolean to change style
   */
  processingFile: boolean;
  /**
   * Callback to return file data for processing
   */
  onChangeUpload: (files: FileList | null) => Promise<void>;
  /**
   * Whether file upload is disabled
   */
  disabled?: boolean;
  /**
   * Whether file input should accept multiple files selected at once
   */
  multiple?: boolean;
  /**
   * Button variant override
   */
  buttonVariant?: ButtonProps['variant'];
}

//Web file upload component
export const MobileFileUpload = ({
  testId = 'webDocumentUpload',
  fileTypes,
  processingFile,
  onChangeUpload,
  disabled,
  multiple = true,
  buttonVariant = 'outlined',
}: MobileFileUploadProps) => {
  const { t } = useTranslation();

  const fileTypesString = fileTypes.join(',');
  const fileInputField = useRef<HTMLInputElement>(null);

  //Drive file input from the main component
  const onChangeClick = () => {
    if (fileInputField.current !== null) {
      fileInputField.current.click();
    }
  };

  //Handle upload item
  const onChangeHandleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const droppedFiles: FileList | null = event.target.files;
    await onChangeUpload(droppedFiles);
    event.target.value = ''; //Reset the input to enable onChange to reupload the same file
  };

  return (
    <Stack>
      <input
        data-testID={`${testId}-input`}
        type="file"
        style={{ display: 'none' }}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          if (disabled) {
            return;
          }
          void onChangeHandleUpload(event);
        }}
        ref={fileInputField}
        id={`${testId}-input`}
        accept={fileTypesString}
        multiple={multiple}
        disabled={disabled}
      />
      <Stack flexShrink={0}>
        <Button
          testID={testId}
          variant={buttonVariant}
          mode="primary"
          onPress={() => {
            if (disabled) {
              return;
            }
            onChangeClick();
          }}
          disabled={disabled || processingFile}
          startIcon={processingFile ? <Spinner size="small" color="$foreground/primary-default" /> : <FileText />}
          fullWidth
        >
          {processingFile ? t('fields.fileUpload.processing') : t('fields.fileUpload.uploadDoc')}
        </Button>
      </Stack>
    </Stack>
  );
};
