import type { Options } from 'react-lottie';
import Lottie from 'react-lottie';
import { YStack } from 'tamagui';
import type { YStackProps, SizeTokens } from 'tamagui';
import { Stack } from '@tamagui/core';
import type { ReactNode } from 'react';
import branding from '@cxnpl/ui/brand';

export interface LoaderProps extends YStackProps {
  height?: SizeTokens;
  testID?: string;
  children?: ReactNode;
  isLoading?: boolean;
}

export const Loader = ({ height = '$6xl', isLoading = true, children, testID, ...props }: LoaderProps) => {
  const lottieOptions: Options = {
    loop: true,
    autoplay: true,
    animationData: branding.assets.loader,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return isLoading ? (
    <YStack
      flexGrow={1}
      justifyContent="center"
      alignItems="center"
      testID={`${testID ? `${testID}-` : ''}loader`}
      {...props}
    >
      <Stack height={height} overflow="hidden">
        <Lottie options={lottieOptions} />
      </Stack>
    </YStack>
  ) : (
    <>{children}</>
  );
};
