import { Svg, Path } from 'react-native-svg';
import type { IconProps } from '@tamagui/helpers-icon';
import { themed } from '@tamagui/helpers-icon';
import { memo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- from tamagui/lucide-icons
const Icon = (props: any) => {
  const { color = 'black', size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 96 960 960" stroke={color} {...otherProps}>
      <Path
        d="M 677.551 217 L 282.797 217 C 192.919 217.078 120.078 289.919 120 379.797 L 120 774.692 C 120 864.603 192.888 937.489 282.797 937.489 L 677.551 937.489 C 767.462 937.489 840.348 864.603 840.348 774.692 L 840.348 379.937 C 840.427 289.972 767.517 217 677.551 217 Z M 538.753 754.735 C 432.996 756.247 365.265 642.708 416.832 550.366 C 441.451 506.282 488.266 479.251 538.753 479.973 L 693.746 479.973 L 693.746 437.281 L 773.433 503.607 L 693.605 570.016 L 693.605 526.426 L 536.76 526.426 C 488.735 526.426 449.017 569.343 449.017 617.341 C 446.025 686.419 518.934 732.834 580.251 700.886 C 611.192 684.766 629.998 652.193 628.487 617.341 C 628.226 596.869 622.107 576.905 610.861 559.8 L 663.517 559.8 C 671.823 577.848 676.131 597.473 676.148 617.341 C 676.811 693.494 614.908 755.398 538.753 754.735 Z M 347.888 707.018 C 358.876 706.477 369.733 704.389 380.138 700.816 L 380.138 748.026 C 369.871 752.169 358.951 754.46 347.888 754.791 C 300.539 754.406 262.436 715.773 262.7 668.425 L 262.7 526.426 L 209.006 526.426 L 209.006 479.973 L 262.756 479.973 L 262.756 421.029 L 309.377 421.029 L 309.377 479.973 L 436.611 479.973 C 418.451 492.424 402.798 508.184 390.467 526.426 L 309.377 526.426 L 309.377 668.368 C 309.515 689.616 326.696 706.811 347.943 706.963 L 347.888 707.018 Z"
        fill={color}
      />
    </Svg>
  );
};

Icon.displayName = 'PayTo';

export const PayTo = memo<IconProps>(themed(Icon));
