import type { IconProps } from '@tamagui/helpers-icon';
import { themed } from '@tamagui/helpers-icon';
import { memo } from 'react';
import { Path, Svg } from 'react-native-svg';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- from tamagui/lucide-icons
const Icon = (props: any) => {
  const { color = '#0F5D5D', size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...otherProps}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.707 1.293a1 1 0 01.237 1.037l-7 20a1 1 0 01-1.858.076l-3.844-8.648-8.648-3.844a1 1 0 01.076-1.858l20-7a1 1 0 011.037.237zM4.711 9.11l6.695 2.975a1 1 0 01.508.508l2.975 6.695L20.37 3.63 4.711 9.11z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.707 1.293a1 1 0 010 1.414l-11 11a1 1 0 11-1.414-1.414l11-11a1 1 0 011.414 0z"
        fill={color}
      />
    </Svg>
  );
};

Icon.displayName = 'Resend';

export const Resend = memo<IconProps>(themed(Icon));
