import { createTamagui } from '@tamagui/core';
import { createMedia } from '@tamagui/react-native-media-driver';
import { shorthands } from '@tamagui/shorthands';
import { animations } from './animations';
import { designSystemsTokens, designSystemThemes } from './tokens';
import { body, bodyEmphasised, heading, link, linkEmphasised } from './fonts';

export const themes = designSystemThemes;

const appConfig = createTamagui({
  defaultFont: 'body',
  animations,
  shouldAddPrefersColorThemes: true,
  themeClassNameOnRoot: true,
  shorthands,
  fonts: {
    body,
    bodyEmphasised,
    heading,
    link,
    linkEmphasised,
  },
  themes,
  // TODO: We probably should wrap the tokens creation with "createTokens(designSystemsTokens)" but this is causing
  //       TypeScript errors related to a long interfaces. The TS compiler is throwing the following error:
  //       TS7056 "The inferred type of this node exceeds the maximum length the compiler will serialize"
  tokens: designSystemsTokens,
  media: createMedia({
    // For width < 320, use styling without any of these media queries
    // Mobile is 320 - 767
    mobile: { minWidth: 320 },
    // Tablet is 768 - 1023
    tablet: { minWidth: 768 },
    // Laptop is 1024 - 1279
    laptop: { minWidth: 1024 },
    // Desktop is >= 1280
    desktop: { minWidth: 1280 },
  }),
  settings: {
    allowedStyleValues: 'somewhat-strict-web',
  },
} satisfies Parameters<typeof createTamagui>['0']);

appConfig.mediaQueryDefaultActive = {
  mobile: false,
  tablet: false,
  laptop: false,
  desktop: false,
};

export type Config = typeof appConfig;

declare module '@tamagui/core' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface -- We need to extend this interface for Tamagui
  interface TamaguiCustomConfig extends Config {}
}

export default appConfig;
