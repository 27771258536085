import type { Variable } from '@tamagui/web';
import { createVariable } from '@tamagui/web';

const sanitizeTokenName = (tokenName: string) => tokenName.replace(/-/g, '_').replace(/\//g, '-');

export function linkVariablesToTokens<T extends Record<string, string | number>>(
  coreTokensRaw: T,
  baseTokenName?: string
) {
  type CoreTokenNames = keyof T;

  const entries: [CoreTokenNames, Variable][] = Object.entries(coreTokensRaw).map(([tokenName, value]) => {
    const tokenNameEscaped = sanitizeTokenName(tokenName);
    const tokenNameWithBase = baseTokenName ? `${baseTokenName}-${tokenNameEscaped}` : tokenNameEscaped;

    return [
      tokenName as CoreTokenNames,
      createVariable({
        key: tokenNameWithBase,
        name: tokenNameWithBase,
        val: value as T[typeof tokenName],
      }),
    ];
  });

  const coreTokens = Object.fromEntries(entries) as {
    [K in CoreTokenNames]: Variable<T[K]>;
  };

  return coreTokens;
}

export type VariablesCollection<T extends Record<string, Variable>> = Record<keyof T, Variable>;

export type { Variable };
