import type { IconProps } from '@tamagui/helpers-icon';
import { themed } from '@tamagui/helpers-icon';
import { memo } from 'react';
import { Path, Svg } from 'react-native-svg';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- from tamagui/lucide-icons
const Icon = (props: any) => {
  const { color = 'black', size = 24, ...otherProps } = props;

  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...otherProps}>
      <Path
        d="M11 9H10C9.73478 9 9.48043 9.10536 9.29289 9.29289C9.10536 9.48043 9 9.73478 9 10C9 10.2652 9.10536 10.5196 9.29289 10.7071C9.48043 10.8946 9.73478 11 10 11H11V9Z"
        fill={color}
      />
      <Path
        d="M13 13V15H14C14.2652 15 14.5196 14.8946 14.7071 14.7071C14.8946 14.5196 15 14.2652 15 14C15 13.7348 14.8946 13.4804 14.7071 13.2929C14.5196 13.1054 14.2652 13 14 13H13Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.47427 1.14935C3.76909 0.967144 4.13723 0.95058 4.44721 1.10557L6 1.88197L7.55279 1.10557C7.83431 0.96481 8.16569 0.96481 8.44721 1.10557L10 1.88197L11.5528 1.10557C11.8343 0.96481 12.1657 0.96481 12.4472 1.10557L14 1.88197L15.5528 1.10557C15.8343 0.96481 16.1657 0.96481 16.4472 1.10557L18 1.88197L19.5528 1.10557C19.8628 0.95058 20.2309 0.967144 20.5257 1.14935C20.8205 1.33156 21 1.65342 21 2V22C21 22.3466 20.8205 22.6684 20.5257 22.8507C20.2309 23.0329 19.8628 23.0494 19.5528 22.8944L18 22.118L16.4472 22.8944C16.1657 23.0352 15.8343 23.0352 15.5528 22.8944L14 22.118L12.4472 22.8944C12.1657 23.0352 11.8343 23.0352 11.5528 22.8944L10 22.118L8.44721 22.8944C8.16569 23.0352 7.83431 23.0352 7.55279 22.8944L6 22.118L4.44721 22.8944C4.13723 23.0494 3.76909 23.0329 3.47427 22.8507C3.17945 22.6684 3 22.3466 3 22V2C3 1.65342 3.17945 1.33156 3.47427 1.14935ZM13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7H10C9.20435 7 8.44129 7.31607 7.87868 7.87868C7.31607 8.44129 7 9.20435 7 10C7 10.7957 7.31607 11.5587 7.87868 12.1213C8.44129 12.6839 9.20435 13 10 13H11V15H8C7.44772 15 7 15.4477 7 16C7 16.5523 7.44772 17 8 17H11C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17H14C14.7957 17 15.5587 16.6839 16.1213 16.1213C16.6839 15.5587 17 14.7957 17 14C17 13.2043 16.6839 12.4413 16.1213 11.8787C15.5587 11.3161 14.7957 11 14 11H13V9H16C16.5523 9 17 8.55228 17 8C17 7.44772 16.5523 7 16 7H13Z"
        fill={color}
      />
    </Svg>
  );
};

Icon.displayName = 'ReceiptFilled';

export const ReceiptFilled = memo<IconProps>(themed(Icon));
