// Source: https://github.com/tamagui/tamagui/blob/master/packages/toggle-group/src/Toggle.tsx

import { useControllableState, type TamaguiComponent } from 'tamagui';
import {
  Stack,
  type StackProps,
  styled,
  withStaticProperties,
  Text,
  createStyledContext,
  type GetProps,
} from '@tamagui/web';
import { forwardRef, useId, type NamedExoticComponent, type ReactNode } from 'react';
import type { IconProps } from '@cxnpl/ui/icons';

/* -------------------------------------------------------------------------------------------------
 * Toggle
 * -----------------------------------------------------------------------------------------------*/

/**
 * Public API for a Toggle component
 */
export interface ToggleProps extends Pick<StackProps, 'maxWidth'> {
  focusable?: boolean;
  children: ReactNode;
  defaultValue?: string;
  disabled?: boolean;
  pressed?: boolean;
  defaultPressed?: boolean;
  onPressedChange: (pressed: boolean) => void;
  icon?: TamaguiComponent<IconProps> | NamedExoticComponent<IconProps>;
  size?: 'md' | 'lg';
  variant: 'button' | 'card';
  circular?: boolean;
  testID?: string;
}

const NAME = 'Toggle';
type TamaguiButtonElement = HTMLButtonElement;

export type ToggleElement = TamaguiButtonElement;

interface ToggleContextValues {
  variant: 'card' | 'button';
  disabled: boolean;
  active: boolean;
}

const ToggleStyledContext = createStyledContext<ToggleContextValues>({
  variant: 'card',
  disabled: false,
  active: false,
});

export const ToggleFrame = styled(Stack, {
  name: NAME,
  tag: 'button',
  role: 'button',
  context: ToggleStyledContext,
  cursor: 'pointer',
  backgroundColor: '$toggle/color/toggle-bg-default',
  borderColor: '$toggle/color/toggle-border-default',
  borderWidth: 1,
  gap: '$space.sm',
  paddingHorizontal: '$space.lg',
  alignItems: 'center',
  hoverStyle: {
    backgroundColor: '$toggle/color/toggle-bg-hover',
    borderColor: '$toggle/color/toggle-border-default',
  },
  focusStyle: {
    outlineWidth: 2,
    outlineColor: '$button/color/button-focus-border',
    outlineStyle: 'solid',
  },
  variants: {
    hasIcon: {
      true: (_, { props }) => {
        const { variant } = props as GetProps<typeof Stack> & ToggleProps & { hasIcon: boolean };
        return {
          justifyContent: 'flex-start',
          $laptop: {
            alignItems: variant === 'card' ? 'flex-start' : 'center',
          },
        };
      },
      false: (_, { props }) => {
        const { variant } = props as GetProps<typeof Stack> & ToggleProps;
        return {
          justifyContent: variant === 'button' ? 'center' : 'flex-start',
        };
      },
    },
    active: {
      true: {
        zIndex: 1,
        color: '$toggle/color/toggle-fg-selected',
        backgroundColor: '$toggle/color/toggle-bg-selected',
        borderColor: '$toggle/color/toggle-border-selected',
        hoverStyle: {
          backgroundColor: '$toggle/color/toggle-bg-selected',
          borderColor: '$toggle/color/toggle-border-selected',
        },
      },
      false: {
        color: '$toggle/color/toggle-fg-default',
      },
    },
    disabled: {
      true: (_, { props }: { props: { active: boolean } }) => ({
        color: '$toggle/color/toggle-fg-disabled',
        borderColor: props.active ? '$toggle/color/toggle-fg-disabled' : '$border/disabled',
        backgroundColor: props.active ? '$toggle/color/toggle-fg-disabled' : '$toggle/color/toggle-bg-disabled',
        cursor: 'auto',
        hoverStyle: {
          borderColor: '$toggle/color/toggle-border-disabled',
          backgroundColor: '$toggle/color/toggle-bg-disabled',
        },
        focusStyle: {
          borderColor: '$toggle/color/toggle-border-disabled',
          outlineWidth: 0,
          backgroundColor: '$toggle/color/toggle-bg-disabled',
        },
      }),
    },

    size: {
      md: {
        paddingVertical: '$space.md',
      },
      lg: {
        paddingVertical: '$space.lg',
      },
    },
    variant: {
      card: (_, { props }) => {
        const { maxWidth } = props as ToggleProps;
        const { hasIcon } = props as { hasIcon: boolean };
        return {
          borderRadius: '$toggle/radius/toggle-radius-multi',
          flexGrow: 1,
          flexShrink: 1,
          flexBasis: '100%',
          $mobile: {
            flexDirection: 'row',
          },
          $tablet: {
            flexDirection: 'row',
          },
          $laptop: {
            flexDirection: hasIcon ? 'column' : 'row',
          },
          maxWidth,
        };
      },
      button: (_, { props }) => {
        const { size, circular } = props as ToggleProps;
        return circular
          ? {
              borderRadius: '$button/radius/button-radius',
              height: size === 'lg' ? '$button/size/lg' : '$button/size/md',
              width: size === 'lg' ? '$button/size/lg' : '$button/size/md',
            }
          : {
              borderRadius: '$button/radius/button-radius',
              height: size === 'lg' ? '$button/size/lg' : '$button/size/md',
              flexDirection: 'row',
            };
      },
    },
  } as const,
  defaultVariants: {},
});

const ToggleText = styled(Text, {
  name: 'ToggleText',
  context: ToggleStyledContext,
  userSelect: 'none',
  ellipse: false,

  variants: {
    active: {
      true: {
        color: '$toggle/color/toggle-fg-selected',
      },
      false: {
        color: '$toggle/color/toggle-fg-default',
      },
    },
    disabled: {
      true: (_, { props }: { props: { active: boolean } }) => ({
        color: props.active ? '$toggle/color/toggle-bg-disabled' : '$toggle/color/toggle-fg-disabled',
      }),
    },
    hasIcon: {
      true: {},
      false: {},
    },

    variant: {
      card: {},
      button: (_, { props }) => {
        const { hasIcon } = props as { hasIcon: boolean };

        return {
          textAlign: hasIcon ? 'left' : 'center',
        };
      },
    },
  } as const,
  defaultVariants: {
    active: false,
    hasIcon: false,
  },
});

const ToggleIcon = ({
  icon: IconComponent,
  active,
  disabled,
}: Required<Pick<ToggleProps, 'icon'>> & { active: boolean; disabled: boolean }) => {
  return (
    <IconComponent
      size={24}
      color={(() => {
        if (disabled) {
          return active ? '$toggle/color/toggle-bg-disabled' : '$toggle/color/toggle-fg-disabled';
        }
        return active ? '$toggle/color/toggle-fg-selected' : '$toggle/color/toggle-fg-default';
      })()}
      // @ts-expect-error -- We can use flexShrink here
      flexShrink={0} // Keeps icon the same size
    />
  );
};

const ToggleInternal = forwardRef<ToggleElement, ToggleProps>(function Toggle(props, forwardedRef) {
  const {
    children,
    pressed: pressedProp,
    defaultPressed = false,
    onPressedChange,
    icon: IconComponent,
    testID,
    maxWidth,
    ...buttonProps
  } = props;

  const [pressed = false, setPressed] = useControllableState({
    prop: pressedProp,
    onChange: onPressedChange,
    defaultProp: defaultPressed,
  });

  const labelId = useId();

  const hasIcon = !!props.icon;
  const circular =
    buttonProps.variant === 'button' && typeof children === 'string' && children.length === 1 && !hasIcon;

  return (
    <ToggleFrame
      testID={testID}
      active={pressed}
      aria-labelledby={labelId}
      aria-pressed={pressed}
      data-state={pressed ? 'on' : 'off'}
      data-disabled={props.disabled ? '' : undefined}
      hasIcon={hasIcon}
      circular={circular}
      maxWidth={maxWidth}
      {...buttonProps}
      ref={forwardedRef}
      onPress={() => {
        if (!props.disabled) {
          setPressed(!pressed);
        }
      }}
      disabled={!!props.disabled}
    >
      {IconComponent ? <ToggleIcon active={pressed} icon={IconComponent} disabled={!!props.disabled} /> : null}
      {typeof children === 'string' ? (
        <ToggleText id={labelId} hasIcon={hasIcon} active={pressed} disabled={!!props.disabled}>
          {children}
        </ToggleText>
      ) : (
        children
      )}
    </ToggleFrame>
  );
});

export const Toggle = withStaticProperties(ToggleInternal, {
  Text: ToggleText,
  Icon: ToggleIcon,
});
