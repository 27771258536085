/* eslint-disable @typescript-eslint/no-explicit-any -- any used for generic */
/* eslint-disable @typescript-eslint/no-unsafe-member-access -- any used for generic */

import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { YStack } from 'tamagui';
import { Button } from '../Button/Button';
import type { TaskRef } from './withTaskRef';
import type { CDPage, ComponentMap, ComponentProps } from './types';

interface MultiTaskPageProps {
  tasks: CDPage[];
  componentMap: ComponentMap;
  props: ComponentProps & { onSubmit: (x: unknown, dataId: string) => Promise<void> | void; config: object[] };
  finalStep: boolean;
  disabled?: boolean;
}

export const MultiTaskPage = ({ tasks, componentMap, props, finalStep, disabled }: MultiTaskPageProps) => {
  const { t } = useTranslation();
  // Create refs array for tasks
  const taskRefs = useRef<React.RefObject<TaskRef>[]>(tasks.map(() => React.createRef<TaskRef>()));
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  return (
    <YStack gap="$4xl">
      {tasks.map((task, index) => {
        return componentMap[task.name]?.component.multiTask(
          {
            ...props,
            onSubmit: (x: unknown) => props.onSubmit(x, task.dataId),
            config: props.config[index],
            initialValues: task.initialValues,
          },
          taskRefs.current[index]
        );
      })}
      <YStack alignSelf="flex-start">
        <Button
          mode="primary"
          variant="filled"
          size="lg"
          disabled={isSubmitting || disabled}
          onPress={async () => {
            setIsSubmitting(true);
            await Promise.all(
              taskRefs.current.map((ref) => ref.current?.onSubmit()) // Trigger each child submit function
            );
            await props.onSubmit();
            setIsSubmitting(false);
          }}
          loading={isSubmitting}
        >
          {/* Show submit if it is the final step in the flow */}
          {finalStep ? t('common.buttons.submit') : t('common.buttons.continue')}
        </Button>
      </YStack>
    </YStack>
  );
};
