import type { ImageImport, ImageSrc } from '../components';

export function getBrandImageSrc<T extends ImageImport>(image: T): ImageSrc {
  if (typeof image === 'number') {
    // png imported as numbers in native
    return image;
  } else if (typeof image === 'function') {
    // svg imported as functions in native
    return image;
  } else if (typeof image === 'object') {
    // all images imported as objects in web
    return image.src;
  }

  throw new Error('Invalid image type.');
}

export interface Branding {
  /**
   * A full name of the brand
   */
  brandName: string;
  /**
   * A full name of the product
   */
  productName: string;

  /**
   * A short name of the brand (might be an acronym)
   */

  brandNameShort: string;

  /**
   * A title of the page. It's used in the <title> tag in Next.js app.
   * {@link https://nextjs.org/docs/app/api-reference/functions/generate-metadata#title}
   */
  pageTitle: string;

  /**
   * A page description. It's used in the <meta name="description"> tag in Next.js app.
   * {@link https://nextjs.org/docs/app/api-reference/functions/generate-metadata#description}
   */
  pageDescription: string;

  /**
   * link to the apple store page for the app
   */
  appleAppStoreUrl: string;

  /**
   * link to the google play store page for the app
   */
  googlePlayUrl: string;

  /**
   * link to transactions account webpage
   */
  transactionsAccountMoreInfo: string;

  /**
   * link to savings account webpage
   */
  savingsAccountMoreInfo: string;

  /**
   * link to overdraft webpage
   */
  overdraftMoreInfo: string;

  /**
   * link to loan webpage
   */
  loanMoreInfo: string;

  /**
   * link to vehicle loan webpage
   */
  vehicleLoanMoreInfo: string;

  /**
   * link to invoices webpage
   */
  invoicesMoreInfo: string;

  assets: {
    /**
     * Brand lottie loader.
     */
    loader: Record<string, unknown>;

    /**
     * Brand logo. Currently, it's used on the top navigation bar of the app.
     */
    logo: {
      src: ImageSrc;
      width: number;
      height: number;
    };

    /**
     * Background image pattern used on some pages e.g. Login screen
     * It should be a vector SVG image.
     */
    background: {
      src: ImageSrc;
    };

    /**
     * Favicons. Each entry should include a path to favicon that can be used by Next.js app
     * {@link https://nextjs.org/docs/app/api-reference/functions/generate-metadata#icons}
     */
    favicon: {
      png: ImageSrc;
      svg: ImageSrc;
    };

    /*
     * business transaction image
     */
    businessTransaction: {
      src: ImageSrc;
      width: number;
      height: number;
    };

    /*
     * savings image
     */
    savings: {
      src: ImageSrc;
      width: number;
      height: number;
    };

    /*
     * business loan image
     */
    businessLoan: {
      src: ImageSrc;
      width: number;
      height: number;
    };

    /*
     * business overdraft image
     */
    businessOverdraft: {
      src: ImageSrc;
      width: number;
      height: number;
    };
    /*
     * card image
     */
    card: {
      src: ImageSrc;
      width: number;
      height: number;
    };

    /*
     * card back image
     */
    cardBack: {
      src: ImageSrc;
      width: number;
      height: number;
    };
    /*
     * card back image
     */
    cardBackCvc: {
      src: ImageSrc;
      width: number;
      height: number;
    };
    /*
     * calculator image
     */
    lendingCalc: {
      src: ImageSrc;
      width: number;
      height: number;
    };
    /**
     * business image
     */
    business: {
      src: ImageSrc;
      width: number;
      height: number;
    };
    /**
     * invite image
     */
    invite: {
      src: ImageSrc;
      width: number;
      height: number;
    };
    /*
     * chat icon
     */
    chat: {
      src: ImageSrc;
      width: number;
      height: number;
    };

    /*
     * general card image
     */
    cardGeneral: {
      src: ImageSrc;
      width: number;
      height: number;
    };
    /*
     * send money image
     */
    sendMoney: {
      src: ImageSrc;
      width: number;
      height: number;
    };
    /*
     * profile image
     */
    profile: {
      src: ImageSrc;
      width: number;
      height: number;
    };
    /*
     * bpay image
     */
    bpay: {
      src: ImageSrc;
      width: number;
      height: number;
    };
    /*
     * card number ACT image
     */
    cardNumberACT: {
      src: ImageSrc;
      width: number;
      height: number;
    };
    /*
     * card number NT post 01 November 2020 image
     */
    cardNumberNTPost01Nov2020: {
      src: ImageSrc;
      width: number;
      height: number;
    };
    /*
     * card number NT pre 01 November 2020 image
     */
    cardNumberNTPre01Nov2020: {
      src: ImageSrc;
      width: number;
      height: number;
    };
    /*
     * card number QLD post 12 June 2019 image front
     */
    cardNumberQLDFront: {
      src: ImageSrc;
      width: number;
      height: number;
    };
    /*
     * card number QLD post 12 June 2019 image front back
     */
    cardNumberQLDBack: {
      src: ImageSrc;
      width: number;
      height: number;
    };
    /*
     * card number NSW image
     */
    cardNumberNSW: {
      src: ImageSrc;
      width: number;
      height: number;
    };
    /*
     * card number SA image
     */
    cardNumberSA: {
      src: ImageSrc;
      width: number;
      height: number;
    };
    /*
     * card number TAS image
     */
    cardNumberTAS: {
      src: ImageSrc;
      width: number;
      height: number;
    };
    /*
     * card number VIC post 07 November 2022 image
     */
    cardNumberVICPost07Nov2022: {
      src: ImageSrc;
      width: number;
      height: number;
    };
    /*
     * card number VIC pre 07 November 2022 image
     */
    cardNumberVICPre07Nov2022: {
      src: ImageSrc;
      width: number;
      height: number;
    };
    /*
     * card number WA image
     */
    cardNumberWA: {
      src: ImageSrc;
      width: number;
      height: number;
    };
    /*
     * merchant account image
     */
    merchantAccount: {
      src: ImageSrc;
      height: number;
      width: number;
    };
    /*
     * Xero Account Image
     */
    xeroAccount: {
      src: ImageSrc;
      height: number;
      width: number;
    };
    /*
     * MYOB Account Image
     */
    myobAccount: {
      src: ImageSrc;
      height: number;
      width: number;
    };
    /*
     * lifeRing image
     */
    lifeRing: {
      src: ImageSrc;
      width: number;
      height: number;
    };
  };
}
