// eslint-disable-next-line no-restricted-imports -- Creation of the image component
import NextImage from 'next/image';
import type { ImageProps } from './types';

export function Image(props: ImageProps) {
  const { src, alt, width, height, webProps, ...rest } = props;
  if (typeof src === 'string') {
    return <NextImage src={src} alt={alt} width={width} height={height} {...webProps} {...rest} />;
  }

  throw Error('Image src should always be string in web. Please contact a developer if you see this');
}
