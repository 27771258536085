// Source: https://github.com/tamagui/tamagui/blob/master/packages/toggle-group/src/ToggleGroup.tsx

import { registerFocusable } from '@tamagui/focusable';
import { RovingFocusGroup } from '@tamagui/roving-focus';
import { useDirection } from '@tamagui/use-direction';
import type { GetProps } from '@tamagui/web';
import { createStyledContext, isWeb, styled, withStaticProperties } from '@tamagui/web';
import * as React from 'react';
import { Group, useControllableState, useGroupItem, type GroupProps, type TamaguiComponent } from 'tamagui';
import type { IconProps } from '@cxnpl/ui/icons';
import { Toggle, ToggleFrame, type ToggleProps } from '../Toggle';

const TOGGLE_GROUP_NAME = 'ToggleGroup';

/* -------------------------------------------------------------------------------------------------
 * ToggleGroupItem
 * -----------------------------------------------------------------------------------------------*/

const TOGGLE_GROUP_ITEM_NAME = 'ToggleGroupItem';

const TOGGLE_GROUP_CONTEXT = 'ToggleGroup';

interface ToggleGroupItemContextValue {
  disabled?: boolean;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars -- from Tamagui original implementation
const { Provider: ToggleGroupItemProvider, useStyledContext: useToggleGroupItemContext } =
  createStyledContext<ToggleGroupItemContextValue>();

export const { Provider: ToggleGroupContext, useStyledContext: useToggleGroupContext } =
  createStyledContext<ToggleGroupContextValue>();

type ToggleGroupItemElement = ToggleGroupItemImplElement;

type ToggleGroupItemProps = GetProps<typeof ToggleFrame> & {
  value: string;
  id?: string;
  disabled?: boolean;
  size?: 'md' | 'lg';
  icon?: TamaguiComponent<IconProps> | React.NamedExoticComponent<IconProps>;
  /**
   * Used to disable passing styles down to children.
   */
  disablePassStyles?: boolean;
};
const ToggleGroupItem = ToggleFrame.extractable(
  // eslint-disable-next-line react/display-name -- from original Tamagui implementation
  React.forwardRef<ToggleGroupItemElement, ToggleGroupItemProps>(
    (props: ScopedProps<ToggleGroupItemProps>, forwardedRef) => {
      const valueContext = useToggleGroupValueContext(props.__scopeToggleGroup);
      const context = useToggleGroupContext(props.__scopeToggleGroup);
      const pressed = valueContext.value.includes(props.value);
      const disabled = context.disabled || props.disabled || false;
      const groupItemProps = useGroupItem({ disabled });
      const size = props.size ?? context.size;
      const variant = context.variant;
      const children = props.children;

      const commonProps = { pressed, disabled, ...props, children };

      const inner = (
        <ToggleGroupItemImpl
          {...commonProps}
          ref={forwardedRef}
          focusable={!disabled}
          disabled={disabled}
          {...groupItemProps}
          size={size}
          variant={variant}
        />
      );

      return <ToggleGroupItemProvider scope={props.__scopeToggleGroup}>{inner}</ToggleGroupItemProvider>;
    }
  )
);
ToggleGroupItem.displayName = TOGGLE_GROUP_ITEM_NAME;

/* -----------------------------------------------------------------------------------------------*/

type ToggleGroupItemImplElement = React.ElementRef<typeof Toggle>;
type ToggleGroupItemImplProps = Omit<ToggleProps, 'defaultPressed' | 'onPressedChange' | 'size'> &
  Pick<ToggleGroupItemProps, 'value' | 'size' | 'maxWidth'>;
// eslint-disable-next-line react/display-name -- from original Tamagui implementation
const ToggleGroupItemImpl = React.forwardRef<ToggleGroupItemImplElement, ScopedProps<ToggleGroupItemImplProps>>(
  (props: ScopedProps<ToggleGroupItemImplProps>, forwardedRef) => {
    const { __scopeToggleGroup, value, ...itemProps } = props;

    const valueContext = useToggleGroupValueContext(__scopeToggleGroup);
    const singleProps = {
      'aria-pressed': undefined,
    };
    const typeProps = valueContext.type === 'single' ? singleProps : undefined;

    return (
      <Toggle
        {...typeProps}
        {...itemProps}
        ref={forwardedRef}
        onPressedChange={(pressed) => {
          if (pressed) {
            valueContext.onItemActivate(value);
          } else {
            valueContext.onItemDeactivate(value);
          }
        }}
        icon={props.icon}
        size={props.size}
        variant={props.variant}
      />
    );
  }
);

/* -----------------------------------------------------------------------------------------------*/

/* -------------------------------------------------------------------------------------------------
 * ToggleGroup
 * -----------------------------------------------------------------------------------------------*/

type ScopedProps<P> = P & { __scopeToggleGroup?: string };

type ToggleGroupElement = ToggleGroupImplSingleElement;
interface ToggleGroupSingleProps extends ToggleGroupImplSingleProps {
  type: 'single';
}
interface ToggleGroupMultipleProps extends ToggleGroupImplMultipleProps {
  type: 'multiple';
}

type ToggleGroupProps = ToggleGroupSingleProps | ToggleGroupMultipleProps;

const ToggleGroup = withStaticProperties(
  // eslint-disable-next-line react/display-name -- from original Tamagui implementation
  React.forwardRef<ToggleGroupElement, ScopedProps<ToggleGroupProps>>((props, forwardedRef) => {
    const { type, ...toggleGroupProps } = props;

    if (!isWeb) {
      // eslint-disable-next-line react-hooks/rules-of-hooks -- Ignore web-only hook
      React.useEffect(() => {
        if (!props.id) {
          return;
        }
        return registerFocusable(props.id, {
          // eslint-disable-next-line @typescript-eslint/no-empty-function -- from original Tamagui implementation
          focus: () => {},
        });
      }, [props.id]);
    }

    if (type === 'single') {
      const singleProps = toggleGroupProps as ToggleGroupImplSingleProps;
      return <ToggleGroupImplSingle {...singleProps} ref={forwardedRef} />;
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- keep else-if for clarity
    } else if (type === 'multiple') {
      const multipleProps = toggleGroupProps as ToggleGroupImplMultipleProps;
      return <ToggleGroupImplMultiple {...multipleProps} ref={forwardedRef} />;
    }

    throw new Error(`Missing prop \`type\` expected on \`${TOGGLE_GROUP_NAME}\``);
  }),
  {
    Item: ToggleGroupItem,
  }
);

ToggleGroup.displayName = TOGGLE_GROUP_NAME;

/* -----------------------------------------------------------------------------------------------*/

interface ToggleGroupValueContextValue {
  type: 'single' | 'multiple';
  variant: 'button' | 'card';
  defaultValue?: string | string[];
  value: string[];
  onItemActivate: (value: string) => void;
  onItemDeactivate: (value: string) => void;
}

const { Provider: ToggleGroupValueProvider, useStyledContext: useToggleGroupValueContext } =
  createStyledContext<ToggleGroupValueContextValue>();

type ToggleGroupImplSingleElement = ToggleGroupImplElement;
interface ToggleGroupImplSingleProps extends ToggleGroupImplProps {
  /**
   * The controlled stateful value of the item that is pressed.
   */
  value?: string;
  /**
   * The value of the item that is pressed when initially rendered. Use
   * `defaultValue` if you do not need to control the state of a toggle group.
   */
  defaultValue?: string;
  /**
   * The callback that fires when the value of the toggle group changes.
   */
  onValueChange?: (value: string) => void;
  /**
   * Won't let the user turn the active item off.
   */
  disableDeactivation?: boolean;
}
// eslint-disable-next-line react/display-name -- from original Tamagui implementation
const ToggleGroupImplSingle = React.forwardRef<ToggleGroupImplSingleElement, ScopedProps<ToggleGroupImplSingleProps>>(
  (props: ScopedProps<ToggleGroupImplSingleProps>, forwardedRef) => {
    const {
      value: valueProp,
      defaultValue,
      // eslint-disable-next-line @typescript-eslint/no-empty-function -- from original Tamagui implementation
      onValueChange = () => {},
      disableDeactivation = false,
      ...toggleGroupSingleProps
    } = props;

    const [value, setValue] = useControllableState({
      prop: valueProp,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- defaultValue can be undefined
      defaultProp: defaultValue!,
      onChange: onValueChange,
    });

    return (
      <ToggleGroupValueProvider
        scope={props.__scopeToggleGroup}
        type="single"
        value={value ? [value] : []}
        defaultValue={value}
        onItemActivate={setValue}
        onItemDeactivate={React.useCallback(
          () =>
            disableDeactivation
              ? null
              : () => {
                  setValue('');
                },
          [setValue, disableDeactivation]
        )}
      >
        <ToggleGroupImpl {...toggleGroupSingleProps} ref={forwardedRef} />
      </ToggleGroupValueProvider>
    );
  }
);

type ToggleGroupImplMultipleElement = ToggleGroupImplElement;
interface ToggleGroupImplMultipleProps extends ToggleGroupImplProps {
  /**
   * The controlled stateful value of the items that are pressed.
   */
  value?: string[];
  /**
   * The value of the items that are pressed when initially rendered. Use
   * `defaultValue` if you do not need to control the state of a toggle group.
   */
  defaultValue?: string[];
  /**
   * The callback that fires when the state of the toggle group changes.
   */
  onValueChange?: (value: string[]) => void;
}
// eslint-disable-next-line react/display-name -- from original Tamagui implementation
const ToggleGroupImplMultiple = React.forwardRef<ToggleGroupImplMultipleElement, ToggleGroupImplMultipleProps>(
  (props: ScopedProps<ToggleGroupImplMultipleProps>, forwardedRef) => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function -- keep empty function for clarity
    const { value: valueProp, defaultValue, onValueChange = () => {}, ...toggleGroupMultipleProps } = props;

    const [value = [], setValue] = useControllableState({
      prop: valueProp,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- defaultValue can be undefined
      defaultProp: defaultValue!,
      onChange: onValueChange,
    });

    const handleButtonActivate = React.useCallback(
      (itemValue: string) => {
        setValue((prevValue = []) => [...prevValue, itemValue]);
      },
      [setValue]
    );

    const handleButtonDeactivate = React.useCallback(
      (itemValue: string) => {
        setValue((prevValue = []) => prevValue.filter((currentItemValue) => currentItemValue !== itemValue));
      },
      [setValue]
    );

    return (
      <ToggleGroupValueProvider
        scope={props.__scopeToggleGroup}
        type="multiple"
        value={value}
        defaultValue={value}
        onItemActivate={handleButtonActivate}
        onItemDeactivate={handleButtonDeactivate}
      >
        <ToggleGroupImpl {...toggleGroupMultipleProps} ref={forwardedRef} />
      </ToggleGroupValueProvider>
    );
  }
);

ToggleGroup.displayName = TOGGLE_GROUP_NAME;

/* -----------------------------------------------------------------------------------------------*/

interface ToggleGroupContextValue {
  rovingFocus: boolean;
  disabled: boolean;
  size: 'md' | 'lg';
  variant: 'button' | 'card';
}

type RovingFocusGroupProps = React.ComponentPropsWithoutRef<typeof RovingFocusGroup>;
type TamaguiElement = HTMLElement;
type ToggleGroupImplElement = TamaguiElement;

const ToggleGroupImplElementFrame = styled(Group, {
  name: TOGGLE_GROUP_NAME,
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  spaceDirection: 'horizontal',
  gap: '$space.sm',
  variants: {
    unstyled: {},
  } as const,

  defaultVariants: {},
});

type ToggleGroupImplProps = GetProps<typeof ToggleGroupImplElementFrame> &
  GroupProps & {
    rovingFocus?: boolean;
    dir?: RovingFocusGroupProps['dir'];
    loop?: RovingFocusGroupProps['loop'];
    sizeAdjust?: number;
    variant: 'button' | 'card';
  };

const ToggleGroupImpl = ToggleGroupImplElementFrame.extractable(
  // eslint-disable-next-line react/display-name -- from original Tamagui implementation
  React.forwardRef<ToggleGroupImplElement, ToggleGroupImplProps>(
    (props: ScopedProps<ToggleGroupImplProps>, forwardedRef) => {
      const {
        __scopeToggleGroup,
        disabled = false,
        variant = 'card',
        dir,
        rovingFocus = true,
        loop = true,
        ...toggleGroupProps
      } = props;
      const direction = useDirection(dir);
      const commonProps: ToggleGroupImplProps = {
        role: 'group',
        dir: direction,
        variant: 'card',
        ...toggleGroupProps,
      };
      return (
        <ToggleGroupContext
          scope={__scopeToggleGroup}
          rovingFocus={rovingFocus}
          disabled={disabled}
          size={props.size}
          variant={variant}
        >
          {rovingFocus ? (
            <RovingFocusGroup
              asChild="except-style"
              __scopeRovingFocusGroup={__scopeToggleGroup || TOGGLE_GROUP_CONTEXT}
              dir={direction}
              loop={loop}
            >
              <ToggleGroupImplElementFrame
                ref={forwardedRef}
                data-disabled={disabled ? '' : undefined}
                {...commonProps}
              />
            </RovingFocusGroup>
          ) : (
            <ToggleGroupImplElementFrame
              ref={forwardedRef}
              data-disabled={disabled ? '' : undefined}
              {...commonProps}
            />
          )}
        </ToggleGroupContext>
      );
    }
  )
);

export { ToggleGroup };
export type { ToggleGroupItemProps, ToggleGroupMultipleProps, ToggleGroupProps, ToggleGroupSingleProps };

// Helper types
export type ContentItem = { label: string } & Pick<ToggleGroupItemProps, 'value' | 'icon' | 'disabled'>;
