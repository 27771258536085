/* eslint-disable @typescript-eslint/no-explicit-any -- from Tamagui's Anchor, except using our Text component */

import { isWeb, styled } from '@tamagui/core';
import { Linking } from 'react-native';
import type { TextProps } from '../Text';
import { Text } from '../Text';

export type AnchorProps = TextProps & {
  href?: string;
  target?: string;
  rel?: string;
  download?: string;
};

const AnchorFrame = styled(Text, {
  name: 'Anchor',
  tag: 'a',
  accessibilityRole: 'link',
  color: '$button/color/button-primary-text',
  hoverStyle: {
    color: '$button/color/button-primary-text-hover',
  },
});

export const Anchor = AnchorFrame.styleable<AnchorProps>(({ href, target, ...props }, ref) => {
  return (
    <AnchorFrame
      {...props}
      {...(isWeb
        ? {
            href,
            target,
          }
        : {
            onPress: (event) => {
              props.onPress?.(event);
              if (href !== undefined) {
                void Linking.openURL(href);
              }
            },
          })}
      ref={ref as any}
    />
  );
});
