import { Svg, Path } from 'react-native-svg';
import type { IconProps } from '@tamagui/helpers-icon';
import { themed } from '@tamagui/helpers-icon';
import { memo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- from tamagui/lucide-icons
const Icon = (props: any) => {
  const { color = 'black', size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" stroke="none" {...otherProps}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.39 13.13l.12.06c1.45.84 1.94 2.68 1.12 4.11l-.21.38a3.01 3.01 0 01-4.1 1.11l-.12-.06c-.34-.2-.72-.2-1.03-.02l-.43.25c-.31.18-.5.51-.5.87v.18c0 1.65-1.35 3-3 3h-.44c-1.65 0-3-1.35-3-3v-.18a1 1 0 00-.5-.86l-.43-.25a.993.993 0 00-1 0l-.18.1c-1.4.81-3.24.32-4.07-1.11l-.22-.38a3.002 3.002 0 011.09-4.1l.13-.08c.33-.19.52-.52.52-.88v-.51c0-.34-.19-.67-.5-.85l-.2-.13C2.07 9.99 1.58 8.15 2.4 6.72l.22-.38c.83-1.43 2.67-1.93 4.1-1.1l.12.06c.34.2.72.2 1.03.02l.43-.25c.31-.18.5-.52.5-.87v-.18c0-1.65 1.35-3 3-3h.44c1.65 0 3 1.35 3 3v.18a1 1 0 00.5.86l.43.25c.31.18.69.18 1 0l.18-.1c1.4-.81 3.24-.32 4.07 1.11l.22.38c.82 1.44.33 3.27-1.1 4.1l-.13.08c-.33.19-.52.52-.52.88v.5c0 .36.19.69.5.87zM10.02 12c0 1.1.9 2 2 2s2-.9 2-2-.9-2-2-2-2 .9-2 2z"
        fill={color}
      />
    </Svg>
  );
};

Icon.displayName = 'SettingsFilled';

export const SettingsFilled = memo<IconProps>(themed(Icon));
