import { getTokens } from 'tamagui';
import type { InputBaseProps } from './Input';

export const inputLabelPaddingTop = (size: InputBaseProps['size']): number => {
  const tokens = getTokens();
  switch (size) {
    case '$sm':
      return tokens.space['input/space/vertical-padding-sm'].val;
    case '$md':
      return tokens.space['input/space/vertical-padding-md'].val;
    case '$lg':
      return tokens.space['input/space/vertical-padding-lg'].val;
    case '$xl':
      return tokens.space['input/space/vertical-padding-xl'].val;
    default:
      return 0;
  }
};

export const inputLabelPaddingLeft = (size: InputBaseProps['size'], includeIconSize?: boolean): number => {
  const tokens = getTokens();
  let paddingLeft = 0;
  switch (size) {
    case '$sm':
      paddingLeft += tokens.space['input/space/horizontal-padding-sm'].val;
      break;
    case '$md':
      paddingLeft += tokens.space['input/space/horizontal-padding-md'].val;
      break;
    case '$lg':
      paddingLeft += tokens.space['input/space/horizontal-padding-lg'].val;
      break;
    case '$xl':
      paddingLeft += tokens.space['input/space/horizontal-padding-xl'].val;
      break;
    default:
      break;
  }

  if (includeIconSize) {
    paddingLeft += iconSize(size) + tokens.space['$input/space/icon-gap'].val;
  }
  return paddingLeft;
};

export const floatingLabelPaddingTop = (size: InputBaseProps['size'], includeBorder = true): number => {
  const tokens = getTokens();
  let paddingTop = 0;
  switch (size) {
    case '$sm':
      paddingTop += tokens.space['input/space/vertical-padding-with-label-sm'].val;
      break;
    case '$md':
      paddingTop += tokens.space['input/space/vertical-padding-with-label-md'].val;
      break;
    case '$lg':
      paddingTop += tokens.space['input/space/vertical-padding-with-label-lg'].val;
      break;
    case '$xl':
      paddingTop += tokens.space['input/space/vertical-padding-with-label-xl'].val;
      break;
    default:
      break;
  }

  if (includeBorder) {
    paddingTop += tokens.size['input/size/border-width'].val;
  }
  return paddingTop;
};

// Floating label will have the same amount of padding as the input, plus the border width
export const floatingLabelPaddingLeft = (size: InputBaseProps['size'], includeIconSize?: boolean): number => {
  const tokens = getTokens();

  return inputLabelPaddingLeft(size, includeIconSize) + tokens.size['input/size/border-width'].val;
};

export const iconSize = (inputSize: InputBaseProps['size']): number => {
  const tokens = getTokens();
  switch (inputSize) {
    case '$sm':
    case '$md':
      return tokens.size['icon/size/xs'].val;
    case '$lg':
    case '$xl':
      return tokens.size['icon/size/sm'].val;
    default:
      return 0;
  }
};
