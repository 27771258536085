'use client';

import { useStringFieldInfo, useTsController } from '@ts-react/form';
import { useId, useState } from 'react';
import { Fieldset, YStack } from 'tamagui';
import { Eye, EyeOff } from '@tamagui/lucide-icons';
import { Input, type InputProps } from '../../Input';

export type PasswordFieldProps = Pick<InputProps, 'size' | 'onChangeText' | 'status' | 'disabled' | 'hint' | 'testID'>;

export function PasswordField({
  size = '$lg',
  onChangeText,
  status,
  disabled,
  hint,
  testID,
}: PasswordFieldProps): JSX.Element {
  const {
    field,
    error, // zod error message
    formState,
  } = useTsController<string>();
  const { isSubmitting } = formState;
  const zodFieldInfo = useStringFieldInfo();
  const { label, placeholder, maxLength } = zodFieldInfo;
  const id = useId();
  const isDisabled = disabled || isSubmitting;
  const [secureTextEntry, setSecureTextEntry] = useState(true);

  return (
    <Fieldset>
      <YStack>
        <Input
          size={size}
          ref={field.ref}
          id={id}
          testID={`${testID || field.name}-input`}
          autoCapitalize="none"
          autoComplete="current-password"
          autoCorrect={false}
          inputMode="text"
          textContentType="password"
          disabled={isDisabled}
          onBlur={field.onBlur}
          onChangeText={(text: string) => {
            onChangeText && onChangeText(text);
            field.onChange(text);
          }}
          maxLength={maxLength}
          placeholder={placeholder}
          label={label}
          value={field.value ?? ''} // default empty string to prevent "uncontrolled to controlled" react warning
          width="100%"
          secureTextEntry={secureTextEntry}
          error={error?.errorMessage}
          hint={hint}
          status={status}
          endIcon={secureTextEntry ? <Eye /> : <EyeOff />}
          endIconContainerProps={{
            cursor: 'pointer',
            onPress: () => {
              setSecureTextEntry((prev) => !prev);
            },
            role: 'button',
          }}
          prioritiseEndIcon
        />
      </YStack>
    </Fieldset>
  );
}
