import { type Branding, getBrandImageSrc } from '../brandingTypes';
import { default as cardNumberACT } from './ACT-card-number.svg';
import { default as cardNumberNSW } from './NSW-card-number.svg';
import { default as cardNumberNTPost01Nov2020 } from './NT-card-number-post-01-nov-2020.svg';
import { default as cardNumberNTPre01Nov2020 } from './NT-card-number-pre-01-nov-2020.svg';
import { default as cardNumberQLDBack } from './QLD-card-number-back.svg';
import { default as cardNumberQLDFront } from './QLD-card-number-front.svg';
import { default as cardNumberSA } from './SA-card-number.svg';
import { default as cardNumberTAS } from './TAS-card-number.svg';
import { default as cardNumberVICPost07Nov2022 } from './VIC-card-number-post-07-nov-2022.svg';
import { default as cardNumberVICPre07Nov2022 } from './VIC-card-number-pre-07-nov-2022.svg';
import { default as cardNumberWA } from './WA-card-number.svg';
import { default as backgroundImage } from './background.svg';
import { default as bpay } from './bpay.svg';
import { default as businessTransaction } from './business-transaction-icon.svg';
import { default as business } from './business.svg';
import { default as cardGeneral } from './card-general.svg';
import { default as cardImage } from './card.png';
import { default as cardBackImage } from './card-back.png';
import { default as cardBackCvcImage } from './card-back-cvc.png';
import { default as chat } from './chat-icon.svg';
import { default as faviconPng } from './favicon.png';
import { default as faviconSvg } from './favicon.svg';
import { default as invite } from './invite.svg';
import { default as lendingCalc } from './lending-calc-icon.svg';
import { default as loader } from './loader.json';
import { default as logoImage } from './logo.svg';
import { default as merchantAccount } from './merchant-account-icon.svg';
import { default as myob } from './myob.svg';
import { default as profile } from './profile.svg';
import { default as savings } from './savings-icon.svg';
import { default as sendMoney } from './send-money.svg';
import { default as businessLoan } from './unsecured-business-loan.svg';
import { default as businessOverdraft } from './unsecured-business-overdraft.svg';
import { default as xero } from './xero.svg';
import { default as lifeRing } from './life-ring.svg';

export const brandName = 'Great Southern Bank';
export const productName = 'Business+';

const branding: Branding = {
  brandName,
  productName,
  brandNameShort: 'GSB',
  pageTitle: `${brandName} | ${productName} Banking Services`,
  pageDescription:
    'What’s our main Difference? We’re committed to providing over 500,000 Australians with consistently better value banking. We’re proud to be award winners.',
  transactionsAccountMoreInfo:
    'https://www.greatsouthernbank.com.au/business-banking/business-bank-accounts/business-plus-account',
  savingsAccountMoreInfo:
    'https://www.greatsouthernbank.com.au/business-banking/business-bank-accounts/business-plus-saver',
  overdraftMoreInfo: 'https://www.greatsouthernbank.com.au/business-banking/loans-finance/business-plus-overdraft',
  loanMoreInfo: 'https://www.greatsouthernbank.com.au/business-banking/loans-finance/business-plus-loan',
  vehicleLoanMoreInfo: 'https://www.greatsouthernbank.com.au/business-banking/loans-finance/business-plus-vehicle-loan',
  invoicesMoreInfo:
    'https://www.greatsouthernbank.com.au/business-banking/business-plus-digital-banking/business-plus-invoices',
  appleAppStoreUrl: 'https://apps.apple.com/au/app/great-southern-bank-business/id6469234503',
  googlePlayUrl: 'https://play.google.com/store/apps/details?id=au.com.gsb.business&hl=en_AU&gl=AU',
  assets: {
    loader,
    chat: {
      src: getBrandImageSrc(chat),
      height: 48,
      width: 48,
    },

    logo: {
      src: getBrandImageSrc(logoImage),
      width: 53,
      height: 40,
    },
    background: {
      src: getBrandImageSrc(backgroundImage),
    },
    favicon: {
      png: getBrandImageSrc(faviconPng),
      svg: getBrandImageSrc(faviconSvg),
    },
    businessTransaction: {
      src: getBrandImageSrc(businessTransaction),
      height: 80,
      width: 120,
    },
    businessLoan: {
      src: getBrandImageSrc(businessLoan),
      height: 80,
      width: 120,
    },
    businessOverdraft: {
      src: getBrandImageSrc(businessOverdraft),
      height: 80,
      width: 120,
    },
    savings: {
      src: getBrandImageSrc(savings),
      height: 80,
      width: 120,
    },
    card: {
      src: getBrandImageSrc(cardImage),
      height: 207,
      width: 329,
    },
    cardBack: {
      src: getBrandImageSrc(cardBackImage),
      height: 197,
      width: 312,
    },
    cardBackCvc: {
      src: getBrandImageSrc(cardBackCvcImage),
      height: 197,
      width: 300,
    },
    lendingCalc: {
      src: getBrandImageSrc(lendingCalc),
      height: 80,
      width: 120,
    },
    business: {
      src: getBrandImageSrc(business),
      height: 80,
      width: 80,
    },
    invite: {
      src: getBrandImageSrc(invite),
      height: 80,
      width: 80,
    },
    cardGeneral: {
      src: getBrandImageSrc(cardGeneral),
      height: 48,
      width: 48,
    },
    sendMoney: {
      src: getBrandImageSrc(sendMoney),
      height: 48,
      width: 48,
    },
    profile: {
      src: getBrandImageSrc(profile),
      height: 48,
      width: 48,
    },
    bpay: {
      src: getBrandImageSrc(bpay),
      height: 48,
      width: 48,
    },
    cardNumberACT: {
      src: getBrandImageSrc(cardNumberACT),
      height: 142,
      width: 222,
    },
    cardNumberNTPost01Nov2020: {
      src: getBrandImageSrc(cardNumberNTPost01Nov2020),
      height: 142,
      width: 222,
    },
    cardNumberNTPre01Nov2020: {
      src: getBrandImageSrc(cardNumberNTPre01Nov2020),
      height: 142,
      width: 222,
    },
    cardNumberQLDFront: {
      src: getBrandImageSrc(cardNumberQLDFront),
      height: 142,
      width: 222,
    },
    cardNumberQLDBack: {
      src: getBrandImageSrc(cardNumberQLDBack),
      height: 142,
      width: 222,
    },
    cardNumberNSW: {
      src: getBrandImageSrc(cardNumberNSW),
      height: 142,
      width: 222,
    },
    cardNumberSA: {
      src: getBrandImageSrc(cardNumberSA),
      height: 142,
      width: 222,
    },
    cardNumberTAS: {
      src: getBrandImageSrc(cardNumberTAS),
      height: 142,
      width: 222,
    },
    cardNumberVICPost07Nov2022: {
      src: getBrandImageSrc(cardNumberVICPost07Nov2022),
      height: 142,
      width: 222,
    },
    cardNumberVICPre07Nov2022: {
      src: getBrandImageSrc(cardNumberVICPre07Nov2022),
      height: 142,
      width: 222,
    },
    cardNumberWA: {
      src: getBrandImageSrc(cardNumberWA),
      height: 142,
      width: 222,
    },
    merchantAccount: {
      src: getBrandImageSrc(merchantAccount),
      height: 80,
      width: 120,
    },
    xeroAccount: {
      src: getBrandImageSrc(xero),
      height: 32,
      width: 32,
    },
    myobAccount: {
      src: getBrandImageSrc(myob),
      height: 24,
      width: 45.55,
    },
    lifeRing: {
      src: getBrandImageSrc(lifeRing),
      height: 88,
      width: 88,
    },
  },
};

export default branding;
