'use client';

import { Moon, MoonStar, Sun, SunDim } from '@tamagui/lucide-icons';
import { XStack, Switch } from 'tamagui';

export interface ThemeSwitchProps {
  theme: 'light' | 'dark';
  toggleTheme: () => void;
}

export const ThemeSwitch = ({ theme, toggleTheme }: ThemeSwitchProps) => {
  const isDarkMode = theme === 'dark';

  return (
    <XStack alignItems="center" gap={8}>
      {isDarkMode ? <SunDim /> : <Sun />}
      <Switch
        backgroundColor="$background/app"
        defaultChecked
        aria-label="Switch theme"
        checked={isDarkMode}
        onCheckedChange={() => {
          toggleTheme();
        }}
      >
        <Switch.Thumb animation="100ms" backgroundColor="$background/surface-highlight" />
      </Switch>
      {isDarkMode ? <MoonStar /> : <Moon />}
    </XStack>
  );
};
