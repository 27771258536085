import { Svg, Path } from 'react-native-svg';
import type { IconProps } from '@tamagui/helpers-icon';
import { themed } from '@tamagui/helpers-icon';
import { memo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- from tamagui/lucide-icons
const Icon = (props: any) => {
  const { color = 'black', size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 25 24" fill="none" stroke="none" {...otherProps}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.91021 7.00053H16.1557C16.762 7.00645 17.3522 7.196 17.8487 7.54411C18.341 7.8893 18.7181 8.37438 18.9313 8.93632L19.8597 11.2265L20.7928 10.2934C21.1833 9.9029 21.8165 9.9029 22.207 10.2934C22.5975 10.6839 22.5975 11.3171 22.207 11.7076L20.207 13.7076C19.9699 13.9447 19.6297 14.0472 19.3011 13.9806C18.9725 13.9139 18.6991 13.687 18.5731 13.3762L17.0731 9.67623C17.0697 9.6677 17.0663 9.65911 17.0631 9.65048C16.9924 9.46115 16.8659 9.29769 16.7005 9.18166C16.5361 9.06639 16.3408 9.0033 16.1402 9.00053H8.89989L8.87692 9.00026C8.67318 8.99558 8.47286 9.05327 8.30281 9.1656C8.13276 9.27792 8.00108 9.43953 7.92542 9.62876L6.42838 13.3719C6.30355 13.684 6.03026 13.9125 5.70097 13.9801C5.37169 14.0477 5.03047 13.9453 4.79278 13.7076L2.79278 11.7076C2.40225 11.3171 2.40225 10.6839 2.79278 10.2934C3.1833 9.9029 3.81647 9.9029 4.20699 10.2934L5.13458 11.221L6.06835 8.88629C6.29533 8.31863 6.69038 7.83375 7.2005 7.49679C7.70714 7.16213 8.30332 6.98915 8.91021 7.00053Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.49989 17.0005C6.49989 16.4482 6.9476 16.0005 7.49989 16.0005H7.50989C8.06217 16.0005 8.50989 16.4482 8.50989 17.0005C8.50989 17.5528 8.06217 18.0005 7.50989 18.0005H7.49989C6.9476 18.0005 6.49989 17.5528 6.49989 17.0005Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4999 17.0005C16.4999 16.4482 16.9476 16.0005 17.4999 16.0005H17.5099C18.0622 16.0005 18.5099 16.4482 18.5099 17.0005C18.5099 17.5528 18.0622 18.0005 17.5099 18.0005H17.4999C16.9476 18.0005 16.4999 17.5528 16.4999 17.0005Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.49989 14.0005C4.9476 14.0005 4.49989 14.4482 4.49989 15.0005V19.0005C4.49989 19.5528 4.9476 20.0005 5.49989 20.0005H19.4999C20.0522 20.0005 20.4999 19.5528 20.4999 19.0005V15.0005C20.4999 14.4482 20.0522 14.0005 19.4999 14.0005H5.49989ZM2.49989 15.0005C2.49989 13.3437 3.84303 12.0005 5.49989 12.0005H19.4999C21.1567 12.0005 22.4999 13.3437 22.4999 15.0005V19.0005C22.4999 20.6574 21.1567 22.0005 19.4999 22.0005H5.49989C3.84303 22.0005 2.49989 20.6574 2.49989 19.0005V15.0005Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.49989 20.0005C6.05217 20.0005 6.49989 20.4482 6.49989 21.0005V23.0005C6.49989 23.5528 6.05217 24.0005 5.49989 24.0005C4.9476 24.0005 4.49989 23.5528 4.49989 23.0005V21.0005C4.49989 20.4482 4.9476 20.0005 5.49989 20.0005Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4999 20.0005C20.0522 20.0005 20.4999 20.4482 20.4999 21.0005V23.0005C20.4999 23.5528 20.0522 24.0005 19.4999 24.0005C18.9476 24.0005 18.4999 23.5528 18.4999 23.0005V21.0005C18.4999 20.4482 18.9476 20.0005 19.4999 20.0005Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4899 3C16.4899 2.44772 16.9376 2 17.4899 2H17.4999C18.0522 2 18.4999 2.44772 18.4999 3C18.4999 3.55228 18.0522 4 17.4999 4H17.4899C16.9376 4 16.4899 3.55228 16.4899 3Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.42838 3C6.42838 2.44772 6.87609 2 7.42838 2H7.43838C7.99066 2 8.43838 2.44772 8.43838 3C8.43838 3.55228 7.99066 4 7.43838 4H7.42838C6.87609 4 6.42838 3.55228 6.42838 3Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5 3C22.0523 3 22.5 3.44772 22.5 4V5H23.5C24.0523 5 24.5 5.44772 24.5 6C24.5 6.55228 24.0523 7 23.5 7H22.5V8C22.5 8.55228 22.0523 9 21.5 9C20.9477 9 20.5 8.55228 20.5 8V4C20.5 3.44772 20.9477 3 21.5 3Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 3C4.05228 3 4.5 3.44772 4.5 4V5H5.5C6.05228 5 6.5 5.44772 6.5 6C6.5 6.55228 6.05228 7 5.5 7H4.5V8C4.5 8.55228 4.05228 9 3.5 9C2.94772 9 2.5 8.55228 2.5 8V4C2.5 3.44772 2.94772 3 3.5 3Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 0C13.0523 0 13.5 0.447715 13.5 1V2H14.5C15.0523 2 15.5 2.44772 15.5 3C15.5 3.55228 15.0523 4 14.5 4H13.5V5C13.5 5.55228 13.0523 6 12.5 6C11.9477 6 11.5 5.55228 11.5 5V1C11.5 0.447715 11.9477 0 12.5 0Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 6C18.5 5.44772 18.9477 5 19.5 5H21.5C22.0523 5 22.5 5.44772 22.5 6C22.5 6.55228 22.0523 7 21.5 7H19.5C18.9477 7 18.5 6.55228 18.5 6Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 6C0.5 5.44772 0.947715 5 1.5 5H3.5C4.05228 5 4.5 5.44772 4.5 6C4.5 6.55228 4.05228 7 3.5 7H1.5C0.947715 7 0.5 6.55228 0.5 6Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 3C9.5 2.44772 9.94771 2 10.5 2H12.5C13.0523 2 13.5 2.44772 13.5 3C13.5 3.55228 13.0523 4 12.5 4H10.5C9.94771 4 9.5 3.55228 9.5 3Z"
        fill={color}
      />
    </Svg>
  );
};

Icon.displayName = 'NewCar';

export const NewCar = memo<IconProps>(themed(Icon));
