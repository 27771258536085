import { XStack, styled, Button as TamaguiButton } from 'tamagui';
import type { TamaguiComponent, XStackProps } from 'tamagui';
import type { ReactNode, NamedExoticComponent } from 'react';
import { Check, Info, AlertTriangle, type IconProps, X, AlertCircle } from '@cxnpl/ui/icons';
import type { TextProps } from '../Text';
import { Text } from '../Text';

export interface AlertProps {
  severity: 'danger' | 'info' | 'success' | 'warning' | 'attention';
  variant?: 'full-width' | 'inline' | 'inline-transparent' | 'bottom-box';
  children: ReactNode;
  testID?: string;
  textVariant?: TextProps['variant'];
  multiline?: boolean;
  onDismiss?: () => void;
  icon?: TamaguiComponent<IconProps> | NamedExoticComponent<IconProps>;
}

const isInlineTransparent = (props: AlertProps) => props.variant === 'inline-transparent';

const colorVariants = {
  severity: {
    danger: (_: unknown, { props }: { props: AlertProps }) => ({
      color: isInlineTransparent(props)
        ? '$alert/color/danger/alert-text-danger'
        : '$alert/color/danger/alert-fg-danger',
      backgroundColor: '$alert/color/danger/alert-bg-danger',
      borderColor: '$alert/color/danger/alert-border-danger',
    }),
    info: (_: unknown, { props }: { props: AlertProps }) => ({
      color: isInlineTransparent(props) ? '$alert/color/info/alert-text-info' : '$alert/color/info/alert-fg-info',
      backgroundColor: '$alert/color/info/alert-bg-info',
      borderColor: '$alert/color/info/alert-border-info',
    }),

    success: (_: unknown, { props }: { props: AlertProps }) => ({
      color: isInlineTransparent(props)
        ? '$alert/color/success/alert-text-success'
        : '$alert/color/success/alert-fg-success',
      backgroundColor: '$alert/color/success/alert-bg-success',
      borderColor: '$alert/color/success/alert-border-success',
    }),
    warning: (_: unknown, { props }: { props: AlertProps }) => ({
      color: isInlineTransparent(props)
        ? '$alert/color/warning/alert-text-warning'
        : '$alert/color/warning/alert-fg-warning',
      backgroundColor: '$alert/color/warning/alert-bg-warning',
      borderColor: '$alert/color/warning/alert-border-warning',
    }),
    attention: (_: unknown, { props }: { props: AlertProps }) => ({
      color: isInlineTransparent(props)
        ? '$alert/color/attention/alert-text-attention'
        : '$alert/color/attention/alert-fg-attention',
      backgroundColor: '$alert/color/attention/alert-bg-attention',
      borderColor: '$alert/color/attention/alert-border-attention',
    }),
  },

  variant: {
    'inline-transparent': {
      // We only set the background color, and we set the text color with the "severity" variant
      backgroundColor: 'transparent',
      padding: 0,
      borderWidth: 0,
    },
    inline: {
      borderRadius: '$alert/radius/alert',
      minWidth: '100%',
      maxWidth: '100%',
    },
    'full-width': {
      justifyContent: 'center',
    },
    'bottom-box': {
      borderRadius: '$alert/radius/alert',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  },
} as const;

const AlertInternal = styled(XStack, {
  paddingVertical: '$space.lg',
  paddingHorizontal: '$space.lg',

  gap: '$space.sm',
  alignItems: 'center',
  width: '100%',
  borderWidth: 1,

  variants: colorVariants,
  defaultVariants: {
    severity: 'info',
    variant: 'full-width',
  },
});

const exhaustiveGuard = (): never => {
  throw new Error('Unhandled case');
};

const getIconComponent = (severity: AlertProps['severity']): NamedExoticComponent<IconProps> => {
  switch (severity) {
    case 'info':
    case 'attention':
      return Info;
    case 'success':
      return Check;
    case 'danger':
      return AlertCircle;
    case 'warning':
      return AlertTriangle;

    default:
      return exhaustiveGuard();
  }
};

const AlertIcon = ({ severity, color }: Pick<AlertProps, 'severity'> & { color: string }) => {
  const IconComponent = getIconComponent(severity);

  return <IconComponent color={color} />;
};

export const Alert = ({
  severity = 'info',
  variant = 'full-width',
  children,
  testID,
  textVariant,
  multiline,
  onDismiss,
  icon: CustomIcon,
}: AlertProps & XStackProps) => {
  const textColor = colorVariants.severity[severity](null, { props: { variant, severity, children: null } }).color;
  const alertText = (
    // @ts-expect-error - assigning a known color
    <Text color={textColor} variant={textVariant} whiteSpace={multiline ? 'pre-wrap' : 'normal'} flexShrink={1}>
      {children}
    </Text>
  );

  return (
    /* The "severity" and "variant" props order is important here */
    <AlertInternal severity={severity} variant={variant} testID={testID}>
      <XStack flexShrink={0}>
        {CustomIcon ? <CustomIcon color={textColor} /> : <AlertIcon severity={severity} color={textColor} />}
      </XStack>
      {onDismiss ? <XStack flex={1}>{alertText}</XStack> : alertText}
      {onDismiss ? (
        <TamaguiButton unstyled onPress={onDismiss} testID={`${testID}-dismiss`} aria-label="Dismiss" cursor="pointer">
          <X color={textColor} />
        </TamaguiButton>
      ) : null}
    </AlertInternal>
  );
};
