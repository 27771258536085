import { useComposedRefs } from '@tamagui/compose-refs';
import type { TamaguiElement } from '@tamagui/core';
import { getTokenValue } from '@tamagui/core';
import type { ListItemProps } from '@tamagui/list-item';
import { ListItem } from '@tamagui/list-item';
import * as React from 'react';
import { useSelectContext, useSelectItemParentContext } from './context';
import type { ScopedProps, SelectStatus } from './types';

/* -------------------------------------------------------------------------------------------------
 * SelectTrigger
 * ## This is the field container where the selected value is ##
 * -----------------------------------------------------------------------------------------------*/
const TRIGGER_NAME = 'SelectTrigger';

export type SelectTriggerProps = ListItemProps & {
  status?: SelectStatus;
};

const statusToBorderColor: (
  status: SelectStatus
) => '$form/color/form-border-success' | '$form/color/form-border-danger' | '$form/color/form-border-default' = (
  status
) => {
  switch (status) {
    case 'error':
      return '$form/color/form-border-danger';
    case 'success':
    default:
      return '$form/color/form-border-default';
  }
};

export const SelectTrigger = React.forwardRef<TamaguiElement, SelectTriggerProps>(function SelectTrigger(
  props: ScopedProps<SelectTriggerProps>,
  forwardedRef
) {
  const { __scopeSelect, disabled = false, unstyled = false, status = 'default', ...triggerProps } = props;

  const context = useSelectContext(TRIGGER_NAME, __scopeSelect);
  const itemParentContext = useSelectItemParentContext(TRIGGER_NAME, __scopeSelect);
  const composedRefs = useComposedRefs(
    forwardedRef,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any -- From Tamagui implementation
    context.floatingContext?.refs.setReference as any
  );

  // const getItems = useCollection(__scopeSelect)
  // const labelId = useLabelContext(context.trigger)
  // const labelledBy = ariaLabelledby || labelId
  if (itemParentContext.shouldRenderWebNative) {
    return null;
  }

  return (
    <ListItem
      componentName={TRIGGER_NAME}
      unstyled={unstyled}
      tag="button"
      id={itemParentContext.id}
      {...(!unstyled && {
        backgrounded: true,
        radiused: true,
        hoverTheme: true,
        pressTheme: true,
        focusable: true,
        focusStyle: {
          borderColor: '$form/color/form-border-selected',
          outlineColor: '$form/color/form-border-selected',
          outlineWidth: '$size.input/size/border-focus-width',
          outlineStyle: 'solid',
          outlineOffset: -getTokenValue('$size.input/size/border-width'), // borderWidth is still 1 for input text spacing. Offsetting so the outline overlaps the border.
        },
        borderWidth: 1,
        borderColor: statusToBorderColor(status),
        backgroundColor: disabled ? '$background/disabled' : '$form/color/form-bg-default', //use form-bg-default because it's transparent and for the trigger is correct
        size: itemParentContext.size,
      })}
      // aria-controls={context.contentId}
      aria-expanded={context.open}
      aria-autocomplete="none"
      dir={context.dir}
      disabled={disabled}
      data-disabled={disabled ? '' : undefined}
      {...triggerProps}
      ref={composedRefs}
      {...(process.env.TAMAGUI_TARGET === 'web' && itemParentContext.interactions
        ? {
            ...itemParentContext.interactions.getReferenceProps(),
            onMouseDown() {
              context.floatingContext?.update();
              itemParentContext.setOpen(!context.open);
            },
          }
        : {
            onPress() {
              itemParentContext.setOpen(!context.open);
            },
          })}
    />
  );
});
