import type { GetThemeValueForKey } from '@tamagui/web';

/**
 * Creates a valid maxWidth prop value for Toggle card columns
 * @param gap -- column gap. //TODO: change from number to number or SpaceToken
 * @param columns -- number of columns. 0 will be ignored and 1 is going to be used instead
 * @returns -- a string valid for maxWidth prop
 */
export const columnMaxWidth = (gap: number, columns?: number): GetThemeValueForKey<'maxWidth'> => {
  if (!columns || columns === 1) {
    return '100%';
  }

  if (gap <= 0) {
    //Truncate on 3 decimals, as less than that would be visually imperceptible
    return `${Math.floor(100000 / columns) / 1000}%`;
  }
  //Truncate on 3 decimals for size's sake, as it will be imperceptible visually
  return `calc(${Math.floor(100000 / columns) / 1000}% - ${
    Math.floor((gap * 1000 * (columns - 1)) / columns) / 1000
  }px)`;
};
