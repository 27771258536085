'use client';

import { useFieldInfo, useTsController } from '@ts-react/form';
import { useId } from 'react';
import { Fieldset } from 'tamagui';
import { z } from 'zod';
import type { InputProps } from '../../Input';
import { Input } from '../../Input';

export const AUS_COUNTRY_CODE_PREFIX = `+61`;

export const createAustralianPhoneNumberSchema = (type: 'PHONE' | 'MOBILE', props?: Parameters<typeof z.string>[0]) =>
  type === 'PHONE'
    ? z
        .string(props)
        /**
         * 1. Phone number must start with +61 or 0
         * 2. Followed by area number:
         *   - 2 (NSW/ACT)
         *   - 3 (VIC/TAS)
         *   - 4 (Mobile number, Australia wide)
         *   - 7 (QLD)
         *   - 8 (WA/ST/NT)
         *   - 9 (Not valid, but for dev)
         * 3. Followed by 8 digits
         */
        // Regex must still pass after transform, due to react-ts-form limitation which may pass the transformed value to the regex again.
        .regex(/^(?:\+61|0)?[2|3|4|7|8|9]\d{8}$/, {
          message: props?.invalid_type_error,
        })
        // Transform must return the same result after running transform on itself, due to react-ts-form limitation, which may pass the transformed value to itself again.
        .transform<string>((value) => `${AUS_COUNTRY_CODE_PREFIX}${value.replace(/^(?:\+61|0)/, '')}`)
    : // For MOBILE, only difference is regex
      z
        .string(props)
        /**
         * 1. Mobile number must start with +61 or 0
         * 2. Followed by either:
         *   - 4 (Mobile number, Australia wide)
         *   - 9 (Not valid, but for dev)
         * 3. Followed by 8 digits
         */
        // Regex must still pass after transform, due to react-ts-form limitation which may pass the transformed value to the regex again.
        .regex(/^(?:\+61|0)?[4|9]\d{8}$/, {
          message: props?.invalid_type_error,
        })
        // Transform must return the same result after running transform on itself, due to react-ts-form limitation, which may pass the transformed value to itself again.
        .transform<string>((value) => `${AUS_COUNTRY_CODE_PREFIX}${value.replace(/^(?:\+61|0)/, '')}`);

export type AusMobileNumberFieldProps = Pick<
  InputProps,
  | 'size'
  | 'status'
  | 'disabled'
  | 'hint'
  | 'secureTextEntry'
  | 'testID'
  | 'fieldLabel'
  | 'onBlur'
  | 'endIcon'
  | 'autoFocus'
>;

// This component can be reused for either a phone number or mobile number zod schema from createAustralianPhoneNumberSchema
export function AustralianPhoneNumberField(props: AusMobileNumberFieldProps): JSX.Element {
  const {
    size = '$lg',
    status,
    disabled,
    hint,
    secureTextEntry,
    testID,
    fieldLabel,
    onBlur,
    endIcon,
    autoFocus = false,
  } = props;
  const {
    field,
    error, // zod error message
    formState,
  } = useTsController<string>();
  const { isSubmitting } = formState;

  const zodFieldInfo = useFieldInfo();
  const { label, placeholder } = zodFieldInfo;

  const id = useId();
  const isDisabled = disabled || isSubmitting;

  return (
    <Fieldset>
      <Input
        ref={field.ref}
        id={id}
        size={size}
        // eslint-disable-next-line jsx-a11y/no-autofocus -- this is a hidden input that may need to be focused
        autoFocus={autoFocus}
        testID={`${testID || field.name}-input`}
        disabled={isDisabled}
        inputMode="tel"
        onBlur={(e) => {
          field.onBlur();
          onBlur?.(e);
        }}
        onChangeText={(text) => {
          field.onChange(text);
        }}
        prefix={AUS_COUNTRY_CODE_PREFIX}
        label={label ? label : undefined}
        fieldLabel={fieldLabel ? fieldLabel : undefined}
        placeholder={placeholder}
        secureTextEntry={secureTextEntry}
        value={field.value?.replace(/^\+61/, '') ?? ''} // remove country code prefix when entered. required for UX, as there is already a +61 prefix and the zod schema's .transform also adds +61
        error={error?.errorMessage}
        hint={hint}
        status={status}
        endIcon={endIcon}
        prioritiseEndIcon
      />
    </Fieldset>
  );
}
