'use client';
import { useStringFieldInfo, useTsController } from '@ts-react/form';
import dayjs, { extend } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { t } from 'i18next';
import { useId } from 'react';
import { Fieldset, YStack } from 'tamagui';
import { z } from 'zod';
import type { InputProps } from '../../Input';
import { Input } from '../../Input';

extend(customParseFormat);

export type DateFieldProps = Pick<InputProps, 'size' | 'status' | 'disabled' | 'hint' | 'testID' | 'fieldLabel'> & {
  dateFormat?: 'DD/MM/YYYY' | 'MM/YYYY';
};

export function DateField({
  size = '$lg',
  status,
  disabled,
  hint,
  testID,
  fieldLabel,
  dateFormat = 'DD/MM/YYYY',
}: DateFieldProps): JSX.Element {
  const { field, error, formState } = useTsController<string>();
  const { isSubmitting } = formState;
  const zodFieldInfo = useStringFieldInfo();
  const { label, placeholder } = zodFieldInfo;
  const id = useId();
  const isDisabled = disabled || isSubmitting;

  const onChangeText = (text: string) => {
    const passDateFormat =
      dateFormat === 'DD/MM/YYYY' ? partialDateRegexDDMMYYYY.test(text) : partialDateRegexMMYYYY.test(text);
    if (!passDateFormat) {
      return;
    }
    const backspacedText = text.length < Number(field.value?.length);
    if (backspacedText) {
      field.onChange(text);
      return;
    }
    if (dayFinishedRegex.test(text) || (dayMonthFinishedRegex.test(text) && dateFormat === 'DD/MM/YYYY')) {
      field.onChange(`${text}/`);
      return;
    }
    field.onChange(text);
  };

  return (
    <Fieldset>
      <YStack>
        <Input
          selectTextOnFocus
          maxLength={10}
          ref={field.ref}
          id={id}
          size={size}
          testID={`${testID || field.name}-input`}
          disabled={isDisabled}
          inputMode="numeric"
          onChangeText={onChangeText}
          fieldLabel={fieldLabel}
          label={label ? label : undefined}
          placeholder={placeholder}
          value={field.value ?? ''}
          error={error?.errorMessage}
          hint={hint}
          status={status}
        />
      </YStack>
    </Fieldset>
  );
}

const partialDateRegexDDMMYYYY = /^\d{0,2}\/?$|^\d{0,2}\/{1}\d{0,2}$|^\d{0,2}\/{1}\d{0,2}\/{1}\d{0,4}$/;
const partialDateRegexMMYYYY = /^\d{0,2}\/?$|^\d{0,2}\/{1}\d{0,4}$/;
const dayFinishedRegex = /^\d{2}$/;
const dayMonthFinishedRegex = /^\d{1,2}\/\d{2}$/;

// Base date validators to add into the refine for better control. Please see storybook example for usage
function ValidDDMMYYYFormat<T extends string>(
  key: T,
  val: Partial<Record<T, string>>,
  ctx: z.RefinementCtx,
  formats = ['DD/MM/YYYY']
) {
  const date = dayjs(val[key], formats, true);
  if (!date.isValid()) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: t('fields.date.invalidDateFormat'),
      path: [key],
    });
  }
}

function ValidMMYYYYFormat<T extends string>(key: T, val: Record<T, string>, ctx: z.RefinementCtx) {
  const date = dayjs(val[key], ['MM/YYYY'], true);
  if (!date.isValid()) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: t('fields.date.invalidDateFormatMMYYYY'),
      path: [key],
    });
  }
}

function NoPastDate<T extends string>(key: T, val: Record<T, string>, ctx: z.RefinementCtx) {
  const date = dayjs(val[key], ['DD/MM/YYYY', 'D/M/YYYY', 'MM/YYYY'], true);
  if (date.toDate() < new Date()) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: t('fields.date.noPast'),
      path: [key],
    });
  }
}

function NoFutureDate<T extends string>(key: T, val: Record<T, string>, ctx: z.RefinementCtx) {
  const date = dayjs(val[key], ['DD/MM/YYYY', 'D/M/YYYY'], true);
  if (date.toDate() > new Date()) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: t('fields.date.noFuture'),
      path: [key],
    });
  }
}

function MinimumAge<T extends string>(key: T, val: Record<T, string>, ctx: z.RefinementCtx, age: number) {
  const date = dayjs(val[key], ['DD/MM/YYYY', 'D/M/YYYY'], true);
  if (date.toDate() > dayjs().subtract(age, 'year').toDate()) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: t('fields.date.mustBeYearsOld', { age }),
      path: [key],
    });
  }
}

export const DateFieldValidators = {
  ValidDDMMYYYFormat,
  ValidMMYYYYFormat,
  NoPastDate,
  NoFutureDate,
  MinimumAge,
};
