import { cloneElement } from 'react';
import type { ReactElement, ReactNode } from 'react';
import { XStack, YStack } from 'tamagui';
import { Text } from '../Text';

export interface FeatureProps {
  title: string;
  description: ReactNode;
  icon: ReactElement;
}

const Feature = ({ title, icon, description }: FeatureProps) => {
  return (
    <XStack flexDirection="row" gap="$space.lg" alignItems="flex-start">
      <YStack
        alignItems="center"
        justifyContent="center"
        backgroundColor="$feature/color/feature-icon-bg"
        width="$3xl"
        height="$3xl"
        borderRadius="$round"
      >
        {cloneElement(icon, {
          color: '$feature/color/feature-icon-fg',
          backgroundColor: '$background/transparent',
        })}
      </YStack>
      <YStack flexDirection="column" justifyContent="center" flex={1}>
        <Text variant="bodyMediumEm">{title}</Text>
        <Text variant="bodySmall" color="$foreground/surface-subdued">
          {description}
        </Text>
      </YStack>
    </XStack>
  );
};

export { Feature };
