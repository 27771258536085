import { Svg, Path } from 'react-native-svg';
import type { IconProps } from '@tamagui/helpers-icon';
import { themed } from '@tamagui/helpers-icon';
import { memo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- from tamagui/lucide-icons
const Icon = (props: any) => {
  const { color = 'black', size = 24, ...otherProps } = props;
  return (
    <Svg width={size * 3} height={size} viewBox="0 0 75 26" {...otherProps}>
      <Path
        d="M19.9436 13.5863C18.0366 13.0828 17.3152 12.6811 17.3152 11.9569C17.3152 11.2327 18.1372 10.814 19.0208 10.814C20.0834 10.814 20.8216 11.3345 21.0173 12.2907H22.7733C22.5272 10.3671 21.1236 9.28644 19.0208 9.28644C18.0366 9.28644 17.2145 9.54669 16.5714 10.0898C15.9506 10.6103 15.6263 11.2553 15.6263 12.0021C15.6263 13.5297 16.549 14.3953 18.5735 14.8989C20.6818 15.442 21.4647 15.8437 21.4647 16.7094C21.4647 17.575 20.5587 18.1351 19.4403 18.1351C18.1317 18.1351 17.3208 17.6089 16.9852 16.5849H15.2852C15.5368 18.5707 17.0803 19.7475 19.3955 19.7475C20.5196 19.7475 21.42 19.4477 22.1246 18.8253C22.8292 18.203 23.1871 17.4392 23.1871 16.4944C23.1871 14.9894 22.1637 14.186 19.938 13.6033"
        fill={color}
      />
      <Path
        d="M32.3206 9.48446H30.0333L26.3648 13.4618V5.47314H24.6367V19.5326H26.3648V15.7532L27.0079 15.0913L30.6709 19.5269H32.919L28.2494 13.8296L32.3206 9.48446Z"
        fill={color}
      />
      <Path
        d="M37.6171 9.28076C36.0904 9.28076 34.8489 9.7843 33.887 10.7687C32.9419 11.7532 32.4609 12.9979 32.4609 14.5085C32.4609 16.0191 32.9419 17.2808 33.887 18.2652C34.8489 19.2496 36.096 19.7305 37.6171 19.7305C39.1382 19.7305 40.3853 19.2496 41.3304 18.2652C42.2923 17.2808 42.7732 16.0134 42.7732 14.5085C42.7732 13.0035 42.2923 11.7532 41.3304 10.7687C40.3853 9.7843 39.1438 9.28076 37.6171 9.28076ZM40.0442 17.1789C39.4234 17.8013 38.6013 18.1238 37.6171 18.1238C36.6328 18.1238 35.8107 17.8013 35.1676 17.1789C34.5469 16.5566 34.2225 15.6513 34.2225 14.5085C34.2225 13.3656 34.5413 12.4604 35.1676 11.838C35.8107 11.2157 36.6328 10.8932 37.6171 10.8932C38.6013 10.8932 39.4234 11.2157 40.0442 11.838C40.6873 12.4604 41.006 13.3656 41.006 14.5085C41.006 15.6513 40.6873 16.5566 40.0442 17.1789Z"
        fill={color}
      />
      <Path
        d="M7.48483 5.26379C5.49955 5.26379 3.8554 5.94838 2.50765 7.29491C1.16549 8.64145 0.5 10.367 0.5 12.5C0.5 14.633 1.1599 16.3812 2.50765 17.7277C3.84981 19.0743 5.49955 19.7362 7.48483 19.7362C9.47011 19.7362 11.1143 19.0743 12.462 17.7277C13.8042 16.3812 14.4697 14.633 14.4697 12.5C14.4697 10.367 13.8098 8.64145 12.462 7.29491C11.1199 5.94838 9.47011 5.26379 7.48483 5.26379ZM11.1366 16.4547C10.2363 17.4618 9.01154 17.9597 7.48483 17.9597C5.95812 17.9597 4.7334 17.4562 3.83304 16.4547C2.93267 15.4307 2.4685 14.1238 2.4685 12.4943C2.4685 10.8649 2.93267 9.55799 3.83304 8.55658C4.73899 7.53254 5.95812 7.029 7.48483 7.029C9.01154 7.029 10.2363 7.53254 11.1366 8.55658C12.037 9.56365 12.5012 10.8706 12.5012 12.4943C12.5012 14.1181 12.0426 15.4307 11.1366 16.4547Z"
        fill={color}
      />
      <Path
        d="M68.9947 12.5C68.9947 8.50566 65.7902 5.26379 61.8421 5.26379C57.8939 5.26379 54.6895 8.5 54.6895 12.5C54.6895 16.5 57.8939 19.7362 61.8421 19.7362C65.7902 19.7362 68.9947 16.4943 68.9947 12.5ZM61.8421 17.326C59.2137 17.326 57.0774 15.1648 57.0774 12.5C57.0774 9.83522 59.2137 7.67964 61.8421 7.67964C64.4705 7.67964 66.6067 9.84088 66.6067 12.5C66.6067 15.1591 64.4705 17.326 61.8421 17.326Z"
        fill={color}
      />
      <Path
        d="M63.0332 0.5V2.92716C65.1303 3.18741 67.0653 4.14356 68.5864 5.6768C70.3871 7.49859 71.377 9.92008 71.377 12.5C71.377 15.0799 70.3871 17.5014 68.5864 19.3232C67.0709 20.8564 65.1303 21.8126 63.0332 22.0728V24.5C69.0562 23.8946 73.7593 18.7518 73.7593 12.5C73.7593 6.24823 69.0562 1.10537 63.0332 0.5Z"
        fill={color}
      />
      <Path
        d="M52.3081 12.5C52.3081 9.92574 53.298 7.49859 55.0987 5.6768C56.6142 4.14356 58.5548 3.18741 60.6519 2.92716V0.5C54.6289 1.10537 49.9258 6.24823 49.9258 12.5C49.9258 18.7518 54.6289 23.8946 60.6519 24.5V22.0728C58.5548 21.8126 56.6198 20.8564 55.0987 19.3232C53.298 17.5014 52.3081 15.0799 52.3081 12.5Z"
        fill={color}
      />
    </Svg>
  );
};

Icon.displayName = 'OskoLarge';

export const OskoLarge = memo<IconProps>(themed(Icon));
