'use client';

import { useFieldInfo, useTsController } from '@ts-react/form';
import { Spinner, useDebounceValue } from 'tamagui';
import { useEffect, useId, useMemo, useState } from 'react';
import { useFormContext, type DeepPartial } from 'react-hook-form';
import type { TypeaheadProps } from '../../../Typeahead';
import { Typeahead } from '../../../Typeahead';

export type UseTypeaheadFieldHookReturn<Item> = Pick<
  TypeaheadFieldProps<Item>,
  | 'items'
  | 'getItems'
  | 'getItemsOnSearchValueChange'
  | 'itemFilter'
  | 'itemToString'
  | 'validateSelectedItem'
  | 'unfocusOnItemSelect'
  | 'itemRenderer'
  | 'setSearchValueOnItemSelect'
  | 'resetSelectedItemOnSearchValueChangeOrItemSelect'
  | 'loading'
  | 'extraItems'
>;

export type ValidateItemFunction<Item> = (
  item: Item,
  helpers: {
    setError: (error: string) => void; // Set a temporary custom error message, which disappears when the search value changes or item is selected.
  }
) => Promise<
  | {
      shouldSelect: true;
      item: Item;
    }
  | {
      shouldSelect: false;
    }
>;

export type TypeaheadFieldProps<Item> = Pick<
  TypeaheadProps<Item>,
  | 'items'
  | 'itemToString'
  | 'itemFilter'
  | 'status'
  | 'hint'
  | 'disabled'
  | 'testID'
  | 'unfocusOnItemSelect'
  | 'itemRenderer'
  | 'extraItems'
  | 'size'
  | 'noItemsFoundMessage'
> & {
  /**
   * Search value. Use if you want search value to be controlled.
   */
  search?: string;
  /**
   * Used to set the search value. Use if you want search value to be controlled.
   */
  setSearch?: (search: string) => void;
  /**
   * Initial search value. Use if you want search value to be uncontrolled.
   */
  initialSearchValue?: string;
  /**
   * Optional callback to fetch items.
   *
   * The callback should update the `items` prop with the new list of items.
   *
   * @param search - the current search value of the typeahead field
   * @returns a list of items to display in the typeahead
   */
  getItems?: (
    search: string,
    helpers: {
      setError: (error: string) => void; // Set a temporary custom error message, which disappears when the search value changes or item is selected.
    }
  ) => Promise<Item[]>;
  /**
   * Whether or not to call getItems whenever the search value changes. Defaults to `true`
   */
  getItemsOnSearchValueChange?: boolean;
  /**
   * Whether the typeahead field is loading and should render a loading spinner.
   */
  loading?: boolean;
  /**
   * Validation callback that runs whenever an item is selected. Decides whether or not an item should be selected.
   *
   * Can be used to validate and/or transform the selected item
   *
   * **Only use this to if you need to perform async or dynamic validation. Otherwise, for static validation, use .refine or .superRefine on the schema instead**
   *
   * @param item - the selected item
   * @param helpers - helper functions to update the form. `setError` sets a temporary error unrelated to schema validation.
   * @returns isValidItem - `true` to select the item, `false` to not select the item
   * @returns item - the item to use as the selected item.
   */
  validateSelectedItem?: ValidateItemFunction<Item>;
  /**
   * Callback that runs before validateSelectedItem and before search value is set
   */
  onSelectedItem?: (item: Item) => void;
  /**
   * Whether selecting an item will set the search value to that item's display string (from `itemToString`)
   * Defaults to true
   */
  setSearchValueOnItemSelect?: boolean;
  /**
   * Whether to reset the selected item when the search value changes or another item is selected. Defaults to true.
   *
   * If the field's search value is not the representation of the selected item, consider setting this to false.
   */
  resetSelectedItemOnSearchValueChangeOrItemSelect?: boolean;
  /**
   * Optional label override if a dynamic label is needed
   */
  labelOverride?: string;
};

export function TypeaheadField<Item>({
  search: controlledSearch,
  setSearch: controlledSetSearch,
  initialSearchValue = '',
  items,
  getItems,
  getItemsOnSearchValueChange = true,
  validateSelectedItem,
  onSelectedItem,
  setSearchValueOnItemSelect = true,
  resetSelectedItemOnSearchValueChangeOrItemSelect = true,
  disabled,
  testID,
  loading,
  itemFilter,
  itemToString,
  labelOverride,
  noItemsFoundMessage,
  ...props
}: TypeaheadFieldProps<Item>): JSX.Element {
  const { field, error: zodError, formState } = useTsController<Item>();
  const form = useFormContext();
  const formError = form.formState.errors[field.name];

  const { label, placeholder } = useFieldInfo();
  const [error, setError] = useState<string>('');

  const [internalSearch, setInternalSearch] = useState<string>(initialSearchValue);

  const isControlledInternally = controlledSearch === undefined && controlledSetSearch === undefined;

  const search = useMemo<string>(() => {
    if (controlledSearch !== undefined) {
      return controlledSearch;
    }

    return internalSearch;
  }, [controlledSearch, internalSearch]);

  const setSearch = useMemo<(search: string) => void>(() => {
    if (controlledSetSearch !== undefined) {
      return controlledSetSearch;
    }

    return setInternalSearch;
  }, [controlledSetSearch]);

  useEffect(() => {
    // If controlled externally, then search value should be updated there instead
    if (!isControlledInternally) {
      return;
    }
    setSearch(initialSearchValue);
  }, [initialSearchValue, isControlledInternally, setSearch]);

  const debouncedSearch = useDebounceValue(search, 300);

  // Fetch items when debounced search changes
  useEffect(() => {
    if (!getItemsOnSearchValueChange || !getItems) {
      return;
    }
    void getItems(debouncedSearch, { setError }).catch(() => {
      // Default error handling. Ideally getItems has error handling
      setError('An error has occurred. Please try again.');
    });
  }, [debouncedSearch, getItemsOnSearchValueChange, getItems]);

  const { isSubmitting } = formState;
  const id = useId();
  const isDisabled = isSubmitting || disabled;

  return (
    <Typeahead<Item>
      inputRef={field.ref}
      id={id}
      label={labelOverride ?? label}
      testID={`${testID || field.name}`}
      width="100%"
      items={items}
      itemFilter={itemFilter}
      itemToString={itemToString}
      inputValue={search}
      noItemsFoundMessage={noItemsFoundMessage}
      minWidth="100%"
      maxWidth="100%"
      onInputValueChange={(changes) => {
        // Ignore downshift behaviour of setting search value on item click here. It will be set in onSelectedItem instead.
        if (changes.type.valueOf() === '__item_click__') {
          return;
        }
        setError('');
        setSearch(changes.inputValue ?? '');

        if (resetSelectedItemOnSearchValueChangeOrItemSelect) {
          form.setValue(field.name, undefined);
        }
      }}
      onSelectedItem={async (item) => {
        setError('');
        onSelectedItem?.(item);
        if (setSearchValueOnItemSelect && !validateSelectedItem) {
          // Set search value if it doesn't need to be validated
          setSearch(itemToString(item));
        }

        if (resetSelectedItemOnSearchValueChangeOrItemSelect) {
          form.setValue(field.name, undefined);
        }

        let validatedItem = item;

        if (validateSelectedItem) {
          try {
            const validation = await validateSelectedItem(item, { setError });

            if (!validation.shouldSelect) {
              return;
            }
            validatedItem = validation.item;
          } catch {
            setError('An error has occurred. Please try again.');
          }
        }

        if (setSearchValueOnItemSelect && validateSelectedItem) {
          // Set search only after it's passed validation
          setSearch(itemToString(item));
        }

        // eslint-disable-next-line @typescript-eslint/ban-types -- react-hook-form definition
        field.onChange(validatedItem as (Item extends Object ? true : false) extends true ? DeepPartial<Item> : Item);
      }}
      disabled={isDisabled}
      error={error || zodError?.errorMessage || formError?.message?.toString()}
      placeholder={placeholder}
      endIcon={loading ? <Spinner /> : undefined}
      prioritiseEndIcon
      autoCorrect={false} // Turn off spell check
      autoCapitalize="none"
      {...props}
    />
  );
}
