import { forwardRef } from 'react';
import type { TamaguiElement, YStackProps } from 'tamagui';
import { YStack, withStaticProperties } from 'tamagui';

const Wrapper = forwardRef<TamaguiElement, YStackProps>(function Wrapper(props, ref) {
  return (
    <YStack
      ref={ref}
      // Add spacing between Header (renderBefore), Body (children) and Footer (renderAfter)
      gap="$space.2xl"
      justifyContent="center"
      width="100%"
      {...props}
    />
  );
});

const Body = forwardRef<TamaguiElement, YStackProps>(function Body(props, ref) {
  return (
    <YStack
      ref={ref}
      // Add spacing between fields
      gap="$space.2xl"
      {...props}
    />
  );
});

const Footer = forwardRef<TamaguiElement, YStackProps>(function Footer(props, ref) {
  return (
    <YStack
      ref={ref}
      // Add spacing between footer children
      gap="$space.2xl"
      {...props}
    />
  );
});

export const FormWrapper = withStaticProperties(Wrapper, {
  Body,
  Footer,
});
