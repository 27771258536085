import { XStack } from 'tamagui';
import { Text, type TextProps } from '../Text';

interface BulletPointProps {
  color: TextProps['color'];
  variant: TextProps['variant'];
  children: string;
}
export const BulletPoint = ({ color, variant, children }: BulletPointProps) => {
  return (
    <XStack gap="$space.sm">
      <Text color={color} variant={variant}>
        {'\u2022 '}
      </Text>
      <Text color={color} variant={variant}>
        {children}
      </Text>
    </XStack>
  );
};
