import { Portal as TamaguiPortal, type PortalProps, type GetProps, isWeb } from 'tamagui';

export const PORTAL_ROOT_ELEMENT = 'portal-root';

/**
 * Custom portal component that wraps the tamagui portal component.
 * Attaches itself to the root element div which lives in the UIProvider.
 * @param children - The children to render in the portal.
 */
export const Portal = ({ children, ref: _ref, ...rest }: PortalProps) => {
  const hostElement = isWeb ? document.getElementById(PORTAL_ROOT_ELEMENT) : undefined;

  const ref = _ref as GetProps<typeof TamaguiPortal>['ref'];

  return (
    <TamaguiPortal host={hostElement} pointerEvents="auto" {...rest} ref={ref}>
      {children}
    </TamaguiPortal>
  );
};

export type { PortalProps };
