/* eslint-disable @typescript-eslint/no-non-null-assertion -- From Tamagui implementation */
/* eslint-disable @typescript-eslint/no-unsafe-return -- From Tamagui implementation */
import { useComposedRefs } from '@tamagui/compose-refs';
import { isWeb, useIsomorphicLayoutEffect } from '@tamagui/constants';
import type { ListItemProps } from '@tamagui/list-item';
import { ListItemFrame, useListItem } from '@tamagui/list-item';
import * as React from 'react';
import { createSelectContext, useSelectItemParentContext } from './context';
import type { ScopedProps } from './types';

/* -------------------------------------------------------------------------------------------------
 * SelectItem
 * ## Each of the items in the opened list ##
 * -----------------------------------------------------------------------------------------------*/

const ITEM_NAME = 'SelectItem';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions -- from Tamagui implementation
type SelectItemContextValue = {
  value: string;
  textId: string;
  isSelected: boolean;
};

export const [SelectItemContextProvider, useSelectItemContext] = createSelectContext<SelectItemContextValue>(ITEM_NAME);

export interface SelectItemExtraProps {
  value: string;
  index: number;
  disabled?: boolean;
  textValue?: string;
  inverse?: boolean;
}

export interface SelectItemProps extends Omit<ListItemProps, keyof SelectItemExtraProps>, SelectItemExtraProps {}

export const SelectItem = ListItemFrame.styleable<SelectItemExtraProps>(
  function SelectItem(props: ScopedProps<SelectItemProps>, forwardedRef) {
    const {
      __scopeSelect,
      value,
      disabled = false,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars -- From Tamagui implementation, probably to remove it from restProps
      textValue: textValueProp,
      index,
      inverse,
      ...restProps
    } = props;

    const { props: listItemProps } = useListItem({
      ...(!props.unstyled && {
        ellipse: true, // Removing this prop fixes this issue
      }),
      ...restProps,
    });

    const context = useSelectItemParentContext(ITEM_NAME, __scopeSelect);

    const {
      setSelectedIndex,
      listRef,
      setOpen,
      onChange,
      activeIndexSubscribe,
      valueSubscribe,
      allowMouseUpRef,
      allowSelectRef,
      setValueAtIndex,
      selectTimeoutRef,
      dataRef,
      interactions,
      shouldRenderWebNative,
      size,
      onActiveChange,
      initialValue,
    } = context;

    const [isSelected, setSelected] = React.useState(initialValue === value);

    React.useEffect(() => {
      return activeIndexSubscribe((i) => {
        const isActive = index === i;

        if (isActive) {
          onActiveChange(value, index);
          listRef?.current[index]?.focus();
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps -- From Tamagui implementation
    }, [index]);

    React.useEffect(() => {
      return valueSubscribe((val) => {
        setSelected(val === value);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps -- From Tamagui implementation
    }, [value]);

    const textId = React.useId();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- from Tamagui implementation
    const composedRefs = useComposedRefs(forwardedRef, (node) => {
      if (!isWeb) {
        return;
      }
      if (node instanceof HTMLElement) {
        if (listRef) {
          listRef.current[index] = node;
        }
      }
    });

    useIsomorphicLayoutEffect(() => {
      setValueAtIndex(index, value);
    }, [index, setValueAtIndex, value]);

    function handleSelect() {
      setSelectedIndex(index);
      onChange(value);
      setOpen(false);
    }

    const selectItemProps = React.useMemo(() => {
      return interactions
        ? interactions.getItemProps({
            onTouchMove() {
              allowSelectRef!.current = true;
              allowMouseUpRef!.current = false;
            },
            onTouchEnd() {
              allowSelectRef!.current = false;
              allowMouseUpRef!.current = true;
            },
            onKeyDown(event) {
              if (event.key === 'Enter' || (event.key === ' ' && !dataRef?.current.typing)) {
                event.preventDefault();
                handleSelect();
              } else {
                allowSelectRef!.current = true;
              }
            },

            onClick() {
              if (allowSelectRef!.current) {
                handleSelect();
              }
            },

            onMouseUp() {
              if (!allowMouseUpRef!.current) {
                return;
              }

              if (allowSelectRef!.current) {
                handleSelect();
              }

              // On touch devices, prevent the element from
              // immediately closing `onClick` by deferring it
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- From Tamagui implementation
              clearTimeout(selectTimeoutRef!.current);
              selectTimeoutRef!.current = setTimeout(() => {
                allowSelectRef!.current = true;
              });
            },
          })
        : {
            onPress: handleSelect,
          };
      // eslint-disable-next-line react-hooks/exhaustive-deps -- From Tamagui implementation
    }, [handleSelect]);

    return (
      <SelectItemContextProvider scope={__scopeSelect} value={value} textId={textId || ''} isSelected={isSelected}>
        {shouldRenderWebNative ? (
          <option value={value}>{props.children}</option>
        ) : (
          <ListItemFrame
            tag="div"
            componentName={ITEM_NAME}
            ref={composedRefs}
            aria-labelledby={textId}
            aria-selected={isSelected}
            data-state={isSelected ? 'active' : 'inactive'}
            aria-disabled={disabled || undefined}
            data-disabled={disabled ? '' : undefined}
            tabIndex={disabled ? undefined : -1}
            {...(!props.unstyled && {
              backgrounded: true,
              backgroundColor: '$background/surface', //using surface color, because the one specified in Figma is transparent, breaking the overlay effect
              pressTheme: true,
              hoverTheme: true,
              cursor: 'default',
              size,
              outlineOffset: -0.5,
            })}
            {...listItemProps}
            {...selectItemProps}
            focusStyle={{
              backgroundColor: inverse ? '$border/primary' : '$background/primary-subdued',
              color: '$button/color/button-primary-fg',
              outlineStyle: 'none',
            }}
          />
        )}
      </SelectItemContextProvider>
    );
  },
  {
    disableTheme: true,
  }
);
