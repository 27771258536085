import { useMedia } from '@tamagui/core';
import type { MediaQueryKey } from '@tamagui/core';
import type { SelectContextValue } from './types';

export const useSelectBreakpointActive = (sheetBreakpoint: SelectContextValue['sheetBreakpoint']) => {
  const media = useMedia();
  if (!sheetBreakpoint) {
    return false;
  }
  if (sheetBreakpoint === true) {
    return true;
  }

  return sheetBreakpoint ? media[sheetBreakpoint as MediaQueryKey] : false;
};

export const useShowSelectSheet = (context: SelectContextValue) => {
  const breakpointActive = useSelectBreakpointActive(context.sheetBreakpoint);
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare -- From Tamagui implementation
  return context.open === false ? false : breakpointActive;
};
