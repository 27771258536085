import { createFont, getVariableValue, isVariable, isWeb } from '@tamagui/core';
import type { FillInFont, GenericFont as TamaguiGenericFont } from '@tamagui/core';

const coreTokensFontSize = {
  1: 11,
  2: 12,
  3: 13,
  4: 14,
  5: 16,
  6: 18,
  7: 20,
  8: 24,
  9: 28,
  10: 32,
  11: 40,
  12: 56,
  13: 72,
  14: 88,
  15: 104,
  16: 120,
};

// This is what some Tamagui components will use in conjunction with the semantic size tokens
const semanticTokensFontSize = {
  true: coreTokensFontSize['5'],
  xs: coreTokensFontSize['2'],
  sm: coreTokensFontSize['4'],
  md: coreTokensFontSize['5'],
  lg: coreTokensFontSize['7'],
  xl: coreTokensFontSize['8'],
  '2xl': coreTokensFontSize['9'],
  '3xl': coreTokensFontSize['10'],
  '4xl': coreTokensFontSize['11'],
  '5xl': coreTokensFontSize['12'],
  '6xl': coreTokensFontSize['13'],
  '7xl': coreTokensFontSize['14'],
  '8xl': coreTokensFontSize['15'],
} as const;

type DefaultSizes = keyof typeof semanticTokensFontSize;

export interface GenericFont extends Omit<TamaguiGenericFont, 'size' | 'family'> {
  size?: TamaguiGenericFont['size'];
  family: NonNullable<TamaguiGenericFont['family']>;
}

export interface CalculateFontSizes {
  calculateLineHeight?: (fontSize: number) => number;
  calculateFontSize?: (fontSize: number) => number;
}

/**
 * Creates a font given a font family, font value overrides, and size functions for font size and line height
 * @param font - font value overrides, such as size, line height, weight, letter spacing, transform. The size and line height values will be calculated using the size functions.
 * @param sizeFunctions - font size and line height functions, if any additional calculations are needed on the passed size values.
 * If no line height overrides are passed, the font size passed to the line height function will be the output of the font size function
 * @returns a font to be used in tamagui's config
 */
export function createGenericFont<FontT extends TamaguiGenericFont>(
  font: GenericFont,
  { calculateLineHeight = (fontSize) => fontSize, calculateFontSize = (fontSize) => fontSize }: CalculateFontSizes = {}
): FillInFont<FontT, DefaultSizes> {
  // Merge individual overrides for size
  const size = {
    ...Object.fromEntries(
      Object.entries({
        ...semanticTokensFontSize,
        ...font.size,
      }).map(([k, v]) => [k, calculateFontSize(Number(v))])
    ),
  };

  const lineHeight = {
    ...Object.fromEntries(
      Object.entries({
        ...size,
        ...font.lineHeight,
      }).map(([k, v]) => [k, calculateLineHeight(getVariableValue(v) as number)])
    ),
  };

  const fontFamily = isVariable(font.family) ? font.family.val : font.family;

  return createFont({
    ...font,
    family: isWeb
      ? `${fontFamily}, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif`
      : fontFamily,
    weight: {
      md: '400',
      ...font.weight,
    },
    letterSpacing: {
      md: 0,
      ...font.letterSpacing,
    },
    // This will be based on calculations using sizeSize and sizeLineHeight.
    // We've already merged overrides of size and line height in the calculations above
    size,
    lineHeight,
  }) as FillInFont<FontT, DefaultSizes>;
}
