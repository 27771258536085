import { Svg, Path } from 'react-native-svg';
import type { IconProps } from '@tamagui/helpers-icon';
import { themed } from '@tamagui/helpers-icon';
import { memo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- from tamagui/lucide-icons
const Icon = (props: any) => {
  const { color = 'black', size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" stroke="none" {...otherProps}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.99967 18.3334C4.54134 18.3334 4.14898 18.1702 3.82259 17.8438C3.4962 17.5174 3.33301 17.1251 3.33301 16.6667V3.33341C3.33301 2.87508 3.4962 2.48272 3.82259 2.15633C4.14898 1.82994 4.54134 1.66675 4.99967 1.66675H11.6663L16.6663 6.66675V16.6667C16.6663 17.1251 16.5031 17.5174 16.1768 17.8438C15.8504 18.1702 15.458 18.3334 14.9997 18.3334H4.99967ZM10.833 7.50008V3.33341H4.99967V16.6667H14.9997V7.50008H10.833Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83301 15.0001H6.66634V13.3334H7.49967C7.73579 13.3334 7.9337 13.2536 8.09342 13.0938C8.25315 12.9341 8.33301 12.7362 8.33301 12.5001V11.6667C8.33301 11.4306 8.25315 11.2327 8.09342 11.073C7.9337 10.9133 7.73579 10.8334 7.49967 10.8334H5.83301V15.0001ZM6.66634 12.5001V11.6667H7.49967V12.5001H6.66634ZM9.16634 15.0001H10.833C11.0691 15.0001 11.267 14.9202 11.4268 14.7605C11.5865 14.6008 11.6663 14.4029 11.6663 14.1667V11.6667C11.6663 11.4306 11.5865 11.2327 11.4268 11.073C11.267 10.9133 11.0691 10.8334 10.833 10.8334H9.16634V15.0001ZM9.99967 14.1667V11.6667H10.833V14.1667H9.99967ZM12.4997 15.0001H13.333V13.3334H14.1663V12.5001H13.333V11.6667H14.1663V10.8334H12.4997V15.0001Z"
        fill={color}
      />
    </Svg>
  );
};

Icon.displayName = 'CustomPDF';

export const CustomPDF = memo<IconProps>(themed(Icon));
