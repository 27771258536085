import type { IconProps } from '@tamagui/helpers-icon';
import { themed } from '@tamagui/helpers-icon';
import { memo } from 'react';
import { Svg, Circle } from 'react-native-svg';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- from tamagui/lucide-icons
const Icon = (props: any) => {
  const { color = 'black', size = 24, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <Circle cx="12.1" cy="12.1" r="4" fill={color} stroke={color} />
    </Svg>
  );
};

Icon.displayName = 'DotLarge';

export const DotLarge = memo<IconProps>(themed(Icon));
