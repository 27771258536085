'use client';

import type { AccordionTriggerProps } from 'tamagui';
import { Accordion, Nav, XStack, YStack, getTokens, withStaticProperties } from 'tamagui';
import type { ReactElement } from 'react';
import React, {
  Children,
  cloneElement,
  createContext,
  isValidElement,
  useCallback,
  useContext,
  useId,
  useMemo,
  useState,
} from 'react';
import type { IconProps } from '@tamagui/helpers-icon';
import { Dot } from '@tamagui/lucide-icons';
import { Text } from '../Text';
import { Badge } from '../Badge';

/* -------------------------------------------------------------------------------------------------
 * Contexts
 * -----------------------------------------------------------------------------------------------*/

/**
 * Shared values between NAvigationGroup and NavigationItem
 */
interface NavigationContextValues {
  selected: string;
  onItemSelected: (id: string) => void;
  collapsed: boolean;
}

const NavigationContext = createContext<NavigationContextValues | undefined>(undefined);

const useNavigationContext = () => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error('useNavigationContext must be used within a NavigationBase');
  }
  return context;
};

/**
 * Shared values for all NavigationItems within a NavigationGroup only
 */
interface StepNavigationGroupContextValues {
  groupId: string;
  groupName: string;
  isParentSelected: boolean;
  isSiblingSelected: boolean;
  /**
   * List of all children selected in this group
   */
  selected: string[];
}

const StepNavigationGroupContext = createContext<StepNavigationGroupContextValues | undefined>(undefined);

const useGroupContext = () => {
  const context = useContext(StepNavigationGroupContext);
  return context;
};

/* -----------------------------------------------------------------------------------------------*/

/* -------------------------------------------------------------------------------------------------
 * Navigation components
 * -----------------------------------------------------------------------------------------------*/

export interface NavigationBaseChildProps {
  /**
   * Unique id for the item
   */
  id: string;
  /**
   * Label to display
   */
  label: string;
  /**
   * Icon to display before the label
   */
  icon?: ReactElement;
  notificationCount?: number;
  /**
   * If the item is disabled
   */
  disabled?: boolean;
  testID?: string;
}

export interface NavigationProps {
  /**
   * The currently selected `NavigationGroup` or `NavigationItem`.
   *
   * Only use if you want Navigation to be controlled.
   */
  selected?: string;
  /**
   * Callback when a `NavigationItem` is pressed.
   *
   * `selected` must also be passed if `onItemSelected` is passed.
   *
   * This will be triggered before any `onPress` callbacks from `NavigationItem` or `NavigationGroup`
   *
   * Only use if you want `Navigation` to be controlled.
   *
   */
  onItemSelected?: (id: string) => void;
  /**
   * The id of the default selected item.
   *
   * Only use if you want Navigation to be uncontrolled.
   */
  defaultSelected?: string;
  /**
   * `NavigationGroup` and `NavigationItem` components
   */
  children?: React.ReactNode;
  /**
   * If the navigation is collapsed. Defaults to `false`.
   */
  collapsed?: boolean;
  testID?: string;
}

export function NavigationBase({
  selected: controlledSelected,
  onItemSelected,
  defaultSelected = '',
  children,
  collapsed = false,
  testID,
}: NavigationProps) {
  // The currently selected NavigationGroup or NavigationItem id.
  const [selected, setSelected] = useState<string>(defaultSelected);

  const contextValue = useMemo<NavigationContextValues>(() => {
    const selectedValue = controlledSelected ?? selected;
    return {
      selected: selectedValue,
      onItemSelected: onItemSelected ?? setSelected,
      collapsed,
    };
  }, [collapsed, controlledSelected, onItemSelected, selected]);

  // Flatten steps into an ordered array of ids
  const orderedStepsById: (string | Record<string, string[]>)[] = useMemo(() => {
    const validChildren: (ReactElement<NavigationGroupProps> | ReactElement<NavigationItemProps>)[] = Children.toArray(
      children
    ).filter((child) => {
      return (
        isValidElement<NavigationBaseChildProps>(child) &&
        (child.type === NavigationGroup || child.type === NavigationItem)
      );
    }) as (ReactElement<NavigationGroupProps> | ReactElement<NavigationItemProps>)[];

    const steps: (string | Record<string, string[]>)[] = [];

    validChildren.forEach((child) => {
      if (child.type === NavigationGroup) {
        steps.push({
          [child.props.id]: Children.toArray((child.props as NavigationGroupProps).children).map(
            (item) => (item as ReactElement<NavigationItemProps>).props.id
          ),
        });
      } else {
        steps.push(child.props.id);
      }
    });

    return steps;
  }, [children]);

  // Default opened accordion item
  const getDefaultValue = useCallback(() => {
    const currentDefaultSelected = controlledSelected ?? defaultSelected;
    if (!currentDefaultSelected) {
      return undefined;
    }
    for (const step of orderedStepsById) {
      // Step is a NavigationItem
      // If defaultSelected is a top-level NavigationItem, then it doesn't need to be the Accordion's defaultValue
      if (typeof step === 'string') {
        if (step === currentDefaultSelected) {
          return undefined;
        }
      }
      // Step is a NavigationGroup
      else {
        const group = Object.entries(step)[0];
        if (!group) {
          return undefined;
        }
        const [groupId, groupSteps] = group;
        // If defaultSelected is a NavigationGroup, then it's the Accordion's defaultValue
        if (groupId === currentDefaultSelected) {
          return groupId;
        }
        // If default is a child item of a NavigationGroup, then this NavigationGroup needs to be the Accordion's defaultValue
        if (groupSteps.includes(currentDefaultSelected)) {
          return groupId;
        }
      }
    }
    return undefined;
  }, [controlledSelected, defaultSelected, orderedStepsById]);

  return (
    <NavigationContext.Provider value={contextValue}>
      <Nav testID={testID} alignItems={collapsed ? 'flex-start' : 'stretch'}>
        <Accordion
          type="single" // only one group can be open at a time
          defaultValue={getDefaultValue()}
          alignItems={collapsed ? 'flex-start' : 'stretch'}
        >
          {/* Validate that all children are either NavigationGroup or NavigationItem components */}
          {Children.map(children, (child) => {
            if (
              isValidElement<NavigationBaseChildProps>(child) &&
              (child.type === NavigationGroup || child.type === NavigationItem)
            ) {
              // If NavigationItem, need to wrap with Accordion.Item to be considered as a item within Accordion.
              if (child.type === NavigationItem) {
                return (
                  <Accordion.Item value={child.props.id} width="100%">
                    <Accordion.Header unstyled>{child}</Accordion.Header>
                  </Accordion.Item>
                );
              }

              // NavigationGroup
              return child;
            } else if (isValidElement(child) && child.type === NavigationDivider) {
              return child;
            }

            throw new Error(
              'Invalid children. Expected children to consist of NavigationGroup, NavigationItem or NavigationDivider components.'
            );
          })}
        </Accordion>
      </Nav>
    </NavigationContext.Provider>
  );
}

export interface NavigationGroupProps extends NavigationBaseChildProps {
  children: React.ReactNode;
  onPress?: () => void;
}

function NavigationGroup({ id, label, icon, notificationCount, onPress, children, testID }: NavigationGroupProps) {
  const navContext = useNavigationContext();

  const { selected, onItemSelected, collapsed } = navContext;

  const childItemIds: string[] = useMemo(
    () =>
      Children.toArray(children)
        .filter((child) => isValidElement<NavigationBaseChildProps>(child))
        .map((child) => (child as ReactElement<NavigationBaseChildProps>).props.id),
    [children]
  );

  const isSelected = selected === id;
  const isChildSelected = childItemIds.includes(selected);

  const getSelectedChildren = () => {
    if (!isChildSelected) {
      return [];
    }
    const selectedChildren: string[] = [];
    for (const childItemId of childItemIds) {
      selectedChildren.push(childItemId);
      if (childItemId === selected) {
        break;
      }
    }
    return selectedChildren;
  };

  // Empty icon for spacing
  const defaultIcon = <YStack width="$size.icon/size/sm" height="$size.icon/size/sm" />;

  let labelIcon = icon;
  if (isValidElement<IconProps>(icon)) {
    labelIcon = cloneElement(icon, {
      color: isSelected || isChildSelected ? '$navigation/color/nav-icon-selected' : '$navigation/color/nav-fg-subdued',
    });
  }

  if (isSelected && icon === undefined) {
    labelIcon = <Dot color="$$navigation/color/nav-fg-default" />;
  }

  return (
    <Accordion.Item
      value={id}
      backgroundColor={
        isSelected || isChildSelected ? '$navigation/color/nav-bg-selected' : '$navigation/color/nav-bg-default'
      }
      width="100%"
    >
      <Accordion.Header unstyled backgroundColor="transparent">
        <NavigationItemButton
          cursor="pointer"
          testID={testID}
          label={label}
          icon={labelIcon ?? defaultIcon}
          notificationCount={notificationCount}
          onPress={() => {
            onItemSelected(id);
            onPress?.();
          }}
          isSelected={isSelected}
          isRelativeSelected={isChildSelected}
          collapsed={collapsed}
          isSiblingSelected={isChildSelected}
        />
      </Accordion.Header>
      <Accordion.Content padding={0} backgroundColor="transparent">
        {/* Validate that all children are NavigationItem components */}
        {Children.map(children, (child) => {
          if (isValidElement<NavigationItemProps>(child) && child.type === NavigationItem) {
            // Insert group context values
            return (
              <StepNavigationGroupContext.Provider
                value={{
                  groupId: id,
                  groupName: label,
                  isParentSelected: isSelected,
                  isSiblingSelected: isChildSelected,
                  selected: getSelectedChildren(),
                }}
              >
                {child}
              </StepNavigationGroupContext.Provider>
            );
          }
          throw new Error('Invalid children. Expected all children to be NavigationItem components.');
        })}
      </Accordion.Content>
    </Accordion.Item>
  );
}

interface NavigationItemProps extends NavigationBaseChildProps {
  onPress?: () => void;
}

function NavigationItem({ id, label, icon, notificationCount, onPress, testID }: NavigationItemProps) {
  const tokens = getTokens();
  const navContext = useNavigationContext();
  const groupContext = useGroupContext();

  const { selected, onItemSelected, collapsed } = navContext;
  const isSelected = selected === id;
  const isParentSelected = !!groupContext?.isParentSelected;
  const isRelativeSelected = isParentSelected || !!groupContext?.isSiblingSelected;

  const defaultIcon = groupContext?.selected.includes(id) ? (
    // Empty divider
    <YStack width="$size.icon/size/sm" height="$size.icon/size/sm">
      <YStack
        position="absolute"
        top={-(tokens.space['navigation/space/nav-icon-divider-gap'].val * 2)}
        bottom={-(tokens.space['navigation/space/nav-icon-divider-gap'].val * 2)}
        left={tokens.size['icon/size/sm'].val / 2}
        outlineColor="$navigation/color/nav-fg-subdued"
        outlineWidth={tokens.size['navigation/size/nav-vertical-divider-width'].val / 2}
        outlineStyle="solid"
        zIndex={1}
      />
    </YStack>
  ) : (
    // Empty icon for spacing
    <YStack width="$size.icon/size/sm" height="$size.icon/size/sm" />
  );

  let labelIcon = icon;
  if (isValidElement<IconProps>(icon)) {
    labelIcon = cloneElement(icon, {
      color: isSelected ? '$navigation/color/nav-icon-selected' : '$navigation/color/nav-fg-subdued',
    });
  }
  if (isSelected && icon === undefined) {
    labelIcon = <Dot color="$$navigation/color/nav-fg-default" />;
  }

  return (
    <NavigationItemButton
      cursor="pointer"
      testID={testID}
      label={label}
      icon={labelIcon ?? defaultIcon}
      onPress={() => {
        onItemSelected(id);
        onPress?.();
      }}
      notificationCount={notificationCount}
      isSelected={isSelected}
      isRelativeSelected={isRelativeSelected}
      hasNavigationDivider={groupContext?.selected.includes(id)}
      collapsed={collapsed}
    />
  );
}

function NavigationDivider() {
  /* FIXME: Introduce a new Variable in Figma for the divider border color e.g. $navigation/color/divider-bg-default */
  return (
    <XStack
      borderTopWidth="$size.navigation/size/nav-horizontal-divider-width"
      borderColor="$navigation/color/nav-bg-selected"
    />
  );
}

export const Navigation = withStaticProperties(NavigationBase, {
  Group: NavigationGroup,
  Item: NavigationItem,
  Divider: NavigationDivider,
});

/* -----------------------------------------------------------------------------------------------*/

/* -------------------------------------------------------------------------------------------------
 * Navigation Trigger Buttons
 * -----------------------------------------------------------------------------------------------*/

function NavigationItemButton({
  label,
  icon,
  onPress,
  isSelected,
  isRelativeSelected,
  disabled,
  hasNavigationDivider,
  notificationCount,
  collapsed,
  isSiblingSelected,
  ...props
}: AccordionTriggerProps & {
  label: NavigationItemProps['label'];
  icon: NavigationItemProps['icon'];
  onPress: () => void;
  isSelected: boolean;
  isRelativeSelected: boolean;
  disabled?: boolean;
  hasNavigationDivider?: boolean;
  collapsed?: boolean;
  notificationCount?: number;
  isSiblingSelected?: boolean;
}) {
  const id = useId();
  return (
    <Accordion.Trigger
      unstyled
      aria-labelledby={!collapsed ? id : undefined}
      aria-selected={isSelected}
      disabled={disabled}
      paddingVertical="$space.navigation/space/nav-padding-vertical"
      paddingHorizontal="$space.navigation/space/nav-padding-horizontal"
      borderWidth={0}
      width="100%"
      hoverStyle={{
        backgroundColor: '$navigation/color/nav-bg-hover',
      }}
      backgroundColor={
        isSelected || isRelativeSelected ? '$navigation/color/nav-bg-selected' : '$navigation/color/nav-bg-default'
      }
      focusStyle={{
        outlineColor: '$navigation/color/nav-focus',
        outlineWidth: '$size.navigation/size/nav-outline-width-focus',
        outlineStyle: 'solid',
        // zIndex when being focused, so outline is always visible
        zIndex: 1,
      }}
      onPress={onPress}
      tabIndex={disabled ? -1 : 0}
      pointerEvents={disabled ? 'none' : 'auto'}
      {...props}
    >
      <XStack
        gap="$navigation/space/nav-icon-gap"
        alignItems="center"
        justifyContent={collapsed ? 'center' : undefined}
      >
        <YStack>
          {hasNavigationDivider ? <NavigationVerticalStepDivider /> : null}
          {icon}
        </YStack>
        {!collapsed && (
          <Text
            id={id}
            variant="bodyMedium"
            focusable={false}
            userSelect="none"
            pointerEvents="none"
            color={isSelected ? '$navigation/color/nav-fg-default' : '$navigation/color/nav-fg-subdued'}
          >
            {label}
          </Text>
        )}
        {notificationCount && !isSiblingSelected ? (
          <XStack flex={1} justifyContent="flex-end">
            <Badge size="small" appearance="info" round>
              {notificationCount}
            </Badge>
          </XStack>
        ) : null}
      </XStack>
    </Accordion.Trigger>
  );
}

function NavigationVerticalStepDivider() {
  const tokens = getTokens();
  return (
    <YStack
      position="absolute"
      top={-(tokens.size['icon/size/sm'].val + tokens.space['navigation/space/nav-icon-divider-gap'].val / 2)}
      bottom={tokens.size['icon/size/sm'].val + tokens.space['navigation/space/nav-icon-divider-gap'].val * 2}
      left={tokens.size['icon/size/sm'].val / 2}
      outlineColor="$navigation/color/nav-fg-subdued"
      outlineWidth={tokens.size['navigation/size/nav-vertical-divider-width'].val / 2}
      outlineStyle="solid"
      zIndex={1}
    />
  );
}

/* -----------------------------------------------------------------------------------------------*/
