import { useComposedRefs } from '@tamagui/compose-refs';
import type { GetProps, SizeTokens, TamaguiTextElement } from '@tamagui/core';
import { styled } from '@tamagui/core';
import * as React from 'react';
import { Text } from '../Text';
import { useSelectContext, useSelectItemParentContext } from './context';
import { useSelectItemContext } from './SelectItem';
import type { ScopedProps } from './types';

/* -------------------------------------------------------------------------------------------------
 * SelectItemText
 * -----------------------------------------------------------------------------------------------*/

export const ITEM_TEXT_NAME = 'SelectItemText';

export const SelectItemTextFrame = styled(Text, {
  name: ITEM_TEXT_NAME,
  color: 'inherit',
  focusStyle: {},

  variants: {
    unstyled: {
      false: {
        userSelect: 'none',
        ellipse: true,
      },
    },
  } as const,

  defaultVariants: {
    unstyled: process.env.TAMAGUI_HEADLESS === '1',
  },
});

//TODO: move to external file and add unit tests
const parentSizeToFont: (parentSize: SizeTokens | undefined) => '$sm' | '$md' = (parentSize) => {
  switch (parentSize) {
    case '$sm':
    case '$md':
      return '$sm';
    default:
      return '$md';
  }
};

export type SelectItemTextProps = GetProps<typeof SelectItemTextFrame>;

export const SelectItemText = SelectItemTextFrame.styleable(function SelectItemText(
  props: ScopedProps<SelectItemTextProps>,
  forwardedRef
) {
  const { __scopeSelect, className, ...itemTextProps } = props;
  const context = useSelectContext(ITEM_TEXT_NAME, __scopeSelect);
  const itemParentContext = useSelectItemParentContext(ITEM_TEXT_NAME, __scopeSelect);
  const ref = React.useRef<TamaguiTextElement | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- from Tamagui implementation
  const composedRefs = useComposedRefs(forwardedRef, ref);
  const itemContext = useSelectItemContext(ITEM_TEXT_NAME, __scopeSelect);
  const contents = React.useRef<React.ReactNode>();
  const fontModifiers = parentSizeToFont(itemParentContext.size);

  // we portal this to the selected area, which is fine to be a bit unsafe concurrently (mostly? its not changing often)...
  // until react native supports portals this is best i think
  contents.current = (
    <SelectItemTextFrame
      className={className}
      id={itemContext.textId}
      {...itemTextProps}
      ref={composedRefs}
      color="inherit"
      fontFamily="$body"
      fontSize={fontModifiers}
      fontWeight={fontModifiers}
      lineHeight={fontModifiers}
    />
  );

  React.useEffect(() => {
    if (itemParentContext.initialValue === itemContext.value && !context.selectedIndex) {
      context.setSelectedItem(contents.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- From Tamagui implementation
  }, []);

  React.useEffect(() => {
    return itemParentContext.valueSubscribe((val) => {
      if (val === itemContext.value) {
        context.setSelectedItem(contents.current);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- From Tamagui implementation
  }, [itemContext.value]);

  if (itemParentContext.shouldRenderWebNative) {
    return <>{props.children}</>;
  }

  return (
    <>
      {contents.current}

      {/* Portal an option in the bubble select */}
      {/* {context.bubbleSelect
              ? ReactDOM.createPortal(
                  // we use `.textContent` because `option` only support `string` or `number`
                  <option value={itemContext.value}>{ref.current?.textContent}</option>,
                  context.bubbleSelect
                )
              : null} */}
    </>
  );
});
