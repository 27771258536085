import { Svg, Path } from 'react-native-svg';
import type { IconProps } from '@tamagui/helpers-icon';
import { themed } from '@tamagui/helpers-icon';
import { memo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- from tamagui/lucide-icons
const Icon = (props: any) => {
  const { color = 'black', size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" stroke="none" {...otherProps}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1zm1 5a1 1 0 10-2 0v1h-1a3 3 0 100 6h1v2H8a1 1 0 100 2h3v1a1 1 0 102 0v-1h1a3 3 0 000-6h-1V9h3a1 1 0 100-2h-3V6zm-2 3h-1a1 1 0 000 2h1V9zm2 4v2h1a1 1 0 000-2h-1z"
        fill={color}
      />
    </Svg>
  );
};

Icon.displayName = 'CircleDollarSignFilled';

export const CircleDollarSignFilled = memo<IconProps>(themed(Icon));
