import { type GetProps, Stack, styled } from '@tamagui/core';

export const Circle = styled(Stack, {
  name: 'Circle',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '$round',
  overflow: 'hidden',
  variants: {
    size: {
      large: {
        width: 300,
        height: 300,
        backgroundColor: '$background/primary-default',
      },
      small: {
        width: 100,
        height: 100,
        backgroundColor: '$background/secondary-default',
      },
      tiny: {
        backgroundColor: '$background/tertiary-default',
        width: 10,
        height: 10,
      },
    },
  } as const,
  defaultVariants: {
    size: 'small',
  },
});

// Why can I pass extra props and styles to the component?
// eslint-disable-next-line @typescript-eslint/no-unused-vars -- ignore me,
const circle = (
  <Circle
    // Types safety works if you uncomment next line
    // backgroundColor="$random"
    borderColor="$background/primary-default"
    space="$lg"
    // You can't pass wrong token type into prop if you uncomment next line
    // color="$space/vertical/sm"
    // Wrong token type can't be passed into prop if you uncomment next line
    // width="$size/text/info"
    // I can pass random styling props which breaks the component API boundaries
    flexDirection="column"
  />
);

export type CircleProps = GetProps<typeof Circle>;
