'use client';

import type { YStackProps } from 'tamagui';
import { isWeb, styled, YStack } from 'tamagui';
import { Dimensions } from 'react-native';
import { useReducedMotionEnabled } from 'packages/ui/src/hooks';

const windowHeight = Dimensions.get('window').height;

export const AnimatedStack = styled(YStack, {
  y: 0,
  zIndex: 1,
  opacity: 1,
  animation: 'slow',
  variants: {
    animateDirection: {
      forward: {
        enterStyle: {
          y: isWeb ? '100%' : windowHeight,
          opacity: 0,
        },
        exitStyle: {
          y: isWeb ? '-100%' : -windowHeight,
          opacity: 0,
          zIndex: 0,
        },
      },
      back: {
        enterStyle: {
          y: isWeb ? '-100%' : -windowHeight,
          opacity: 0,
        },
        exitStyle: {
          y: isWeb ? '100%' : windowHeight,
          opacity: 0,
          zIndex: 0,
        },
      },
    },
  } as const,
});

export const AnimatedFormStack = ({
  animateDirection,
  ...restProps
}: YStackProps & { animateDirection: 'forward' | 'back' }) => {
  const reducedMotion = useReducedMotionEnabled();

  if (reducedMotion) {
    return <YStack {...restProps} />;
  }

  return <AnimatedStack animateDirection={animateDirection} {...restProps} />;
};
