import { type GetProps, Stack, styled, type VariantSpreadFunction } from '@tamagui/core';
import type { StackProps } from '@tamagui/web/types/types';

interface ElevationVariants {
  /**
   * The elevation level of the component.
   */
  level?: 1 | 2 | 3 | 4;
  hover?: true | false;
  shadow?: 'soft' | 'hard';
}

type LevelKeys = NonNullable<ElevationVariants['level']>;

const softShadows: Record<LevelKeys, string> = {
  1: '0px 4px 8px 0px rgba(0, 0, 0, 0.05)',
  2: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
  3: '0px 4px 8px 0px rgba(0, 0, 0, 0.20)',
  4: '0px 4px 8px 0px rgba(0, 0, 0, 0.30)',
};

const hardShadows: Record<LevelKeys, string> = {
  1: '0px 2px 4px 0px rgba(0, 0, 0, 0.20)',
  2: '0px 2px 4px 0px rgba(0, 0, 0, 0.30)',
  3: '0px 2px 4px 0px rgba(0, 0, 0, 0.40)',
  4: '0px 2px 4px 0px rgba(0, 0, 0, 0.50)',
};

const softHoverShadows: Record<LevelKeys, string> = {
  1: '0px 8px 16px 0px rgba(0, 0, 0, 0.10)',
  2: '0px 8px 16px 0px rgba(0, 0, 0, 0.20)',
  3: '0px 8px 16px 0px rgba(0, 0, 0, 0.30)',
  4: '0px 8px 16px 0px rgba(0, 0, 0, 0.40)',
};

const hardHoverShadows: Record<LevelKeys, string> = {
  1: '0px 4px 8px 0px rgba(0, 0, 0, 0.30)',
  2: '0px 4px 8px 0px rgba(0, 0, 0, 0.40)',
  3: '0px 4px 8px 0px rgba(0, 0, 0, 0.50)',
  4: '0px 4px 8px 0px rgba(0, 0, 0, 0.60)',
};

const variantStylesGetter: VariantSpreadFunction<StackProps & ElevationVariants, string> = (_value, extras) => {
  const { level, shadow, hover } = extras.props as Required<ElevationVariants>;
  let shadowValue: string;
  let shadowValueHover: string;

  switch (shadow) {
    case 'soft':
      shadowValue = softShadows[level];
      shadowValueHover = softHoverShadows[level];
      break;
    case 'hard':
      shadowValue = hardShadows[level];
      shadowValueHover = hardHoverShadows[level];
      break;
  }

  return {
    // TODO: PWA-67 Convert box-shadow to react-native shadow props and remove type casting
    // https://reactnative.dev/docs/shadow-props
    boxShadow: shadowValue,
    hoverStyle: hover
      ? {
          boxShadow: shadowValueHover,
        }
      : {},
  } as StackProps;
};

export const Elevation = styled(Stack, {
  name: 'Elevation',
  variants: {
    shadow: {
      soft: {},
      hard: {},
    },
    level: {
      1: variantStylesGetter,
      2: variantStylesGetter,
      3: variantStylesGetter,
      4: variantStylesGetter,
    },
    hover: {
      true: {},
      false: {},
    },
  },
  defaultVariants: {
    shadow: 'soft',
    level: 1,
    hover: false,
  },
} as const);

export type ElevationProps = GetProps<typeof Elevation>;
