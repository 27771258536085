import { Stack } from 'tamagui';
import { Trash2 } from '@tamagui/lucide-icons';
import { InformationTable } from '../Tables/InformationTable';
import type { UploadedFile } from './FileUpload';

interface DisplayUploadedDocProps {
  testId?: string;
  /**
   * list of name ids to display
   */
  files: UploadedFile[];
  /**
   * Category for callback when deleted doc
   */
  category: string;
  /**
   * On delete call back to remove document
   */
  onDeleteDoc?: (fileId: string) => Promise<void> | void;
  /**
   * Whether file upload is disabled
   */
  disabled?: boolean;
}

export const DisplayUploadedDoc = ({
  testId = 'DisplayUploadedDoc',
  files,
  onDeleteDoc,
  disabled,
}: DisplayUploadedDocProps) => {
  return (
    <InformationTable
      column1FlexBasis={80}
      background="transparent"
      testID={testId}
      rows={files.map((file: UploadedFile) => {
        return {
          data: [
            file.name,
            onDeleteDoc ? (
              <Stack
                key="deleteIcon"
                tag="button"
                role="button"
                onPress={() => {
                  void onDeleteDoc(file.id);
                }}
                cursor={disabled ? undefined : 'pointer'}
                testID={`delete-document-${file.name}`}
                disabled={disabled}
              >
                <Trash2 size="$size.icon/size/sm" color={disabled ? '$icon/disabled' : '$icon/app'} />
              </Stack>
            ) : (
              // eslint-disable-next-line react/jsx-no-useless-fragment -- empty
              <></>
            ),
          ],
        };
      })}
    />
  );
};
