import { isValidElement } from 'react';
import type { z } from 'zod';

// https://github.com/colinhacks/zod/discussions/2134#discussioncomment-8008293
interface ZodSchemaFields {
  [K: string]: ZodSchemaFields | object;
}
interface DirtyZodSchemaFields {
  [K: string]: DirtyZodSchemaFields;
}

const _proxyHandler = {
  get(fields: DirtyZodSchemaFields, key: string | symbol) {
    if (key === 'then' || typeof key !== 'string') {
      return;
    }
    if (!fields[key]) {
      fields[key] = new Proxy({}, _proxyHandler);
    }
    return fields[key];
  },
};

function _clean(fields: DirtyZodSchemaFields, defaultValue: object) {
  const cleaned: ZodSchemaFields = {};
  Object.entries(fields).forEach(([k, val]) => {
    cleaned[k] = Object.keys(val).length ? { ..._clean(val, defaultValue), ...defaultValue } : defaultValue;
  });
  return cleaned;
}

/**
 * Get an object with the same shape as a given schema, will the values of each key as some default value
 */
export function getEmptyFieldProps(schema: z.ZodSchema, defaultValue: object): ZodSchemaFields {
  const fields = {};
  schema.safeParse(new Proxy(fields, _proxyHandler));
  return _clean(fields, defaultValue);
}

// Merge two objects
export function mergeObjects(obj1: Record<string, unknown>, obj2: Record<string, unknown>) {
  const merged = { ...obj1 };
  for (const key in obj2) {
    // Recursive arrays not needed by SchemaForm use case, as we only want to inject primitive values
    if (Array.isArray(obj2[key])) {
      merged[key] = obj2[key];
      continue;
    }
    merged[key] =
      obj1[key] && typeof obj1[key] === 'object'
        ? mergeObjects(obj1[key] as Record<string, unknown>, obj2[key] as Record<string, unknown>)
        : obj2[key];
  }
  return merged;
}

// From react-ts-form library
// node_modules/@ts-react/form/src/createSchemaForm.tsx
// https://github.com/iway1/react-ts-form/blob/205892db84e4405328115b1de741a7a29d5f9e30/src/createSchemaForm.tsx#L675
export type RenderedElement = JSX.Element | JSX.Element[] | RenderedObjectElements | RenderedElement[];
export interface RenderedObjectElements {
  [key: string]: RenderedElement;
}

/***
 * Can be useful in CustomChildRenderProp to flatten the rendered field map at a given leve
 */
export function flattenRenderedElements(val: RenderedElement): JSX.Element[] {
  // eslint-disable-next-line no-nested-ternary -- code from library
  return Array.isArray(val)
    ? val.flatMap((obj) => flattenRenderedElements(obj))
    : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- code from library
      typeof val === 'object' && val !== null && !isValidElement(val)
      ? // eslint-disable-next-line @typescript-eslint/no-shadow -- code from library
        Object.values(val).reduce<JSX.Element[]>((accum: JSX.Element[], val) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any -- code from library
          return accum.concat(flattenRenderedElements(val as any));
        }, [])
      : [val];
}
