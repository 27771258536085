import { XStack, styled, type TamaguiComponent } from 'tamagui';
import type { NamedExoticComponent, ReactNode } from 'react';
import type { IconProps } from '@cxnpl/ui/icons';
import { Text } from '../Text';

export type BadgeAppearanceType = 'danger' | 'info' | 'success' | 'warning' | 'attention' | 'neutral';
export interface BadgeProps {
  appearance: BadgeAppearanceType;
  size?: 'default' | 'small';
  /**
   * Icon to show before the text. Only available for `size="default"`.
   */
  icon?: TamaguiComponent<IconProps> | NamedExoticComponent<IconProps>;
  children: string | ReactNode;
  testID?: string;
  round?: boolean;
  /**
   * Optional prop to control whether the badge has a border.
   */
  withBorder?: boolean;
}

const colorVariants = {
  danger: {
    color: '$alert/color/danger/alert-fg-danger',
    backgroundColor: '$alert/color/danger/alert-bg-danger',
  },
  info: {
    color: '$alert/color/info/alert-fg-info',
    backgroundColor: '$alert/color/info/alert-bg-info',
  },
  neutral: {
    color: '$alert/color/neutral/alert-fg-neutral',
    backgroundColor: '$alert/color/neutral/alert-bg-neutral',
  },
  success: {
    color: '$alert/color/success/alert-fg-success',
    backgroundColor: '$alert/color/success/alert-bg-success',
  },
  warning: {
    color: '$alert/color/warning/alert-fg-warning',
    backgroundColor: '$alert/color/warning/alert-bg-warning',
  },
  attention: {
    color: '$alert/color/attention/alert-fg-attention',
    backgroundColor: '$alert/color/attention/alert-bg-attention',
  },
} as const;

const BadgeInternal = styled(XStack, {
  display: 'inline-flex',
  borderRadius: '$round',
  gap: '$space.xs', // 4px
  alignItems: 'center',

  variants: {
    appearance: colorVariants,
    round: {
      true: {
        aspectRatio: 1,
      },
    },
    size: {
      default: {
        height: '$size.lg', // 32px
        paddingHorizontal: '$space.sm', // 8px
      },
      small: {
        height: '$size.xs', // 20px
        paddingHorizontal: '$space.sm', // 8px
      },
    },
    withBorder: {
      true: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '$text/surface',
      },
      false: {
        borderWidth: 0,
      },
    },
  } as const,
  defaultVariants: {
    appearance: 'info',
    size: 'default',
    withBorder: false,
  },
});

export const Badge = ({
  appearance = 'info',
  size = 'default',
  children,
  icon: IconComponent,
  testID,
  round = false,
  withBorder = false,
}: BadgeProps) => {
  const showIcon = IconComponent && size === 'default';
  const textColor = colorVariants[appearance].color;

  return (
    <BadgeInternal
      appearance={appearance}
      size={size}
      withBorder={withBorder}
      testID={testID}
      justifyContent="center"
      round={round}
    >
      {showIcon ? (
        <XStack flexShrink={0}>
          <IconComponent size="$size.xs" color={textColor} />
        </XStack>
      ) : null}
      <Text color={textColor} variant="bodySmall">
        {children}
      </Text>
    </BadgeInternal>
  );
};
