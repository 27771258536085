'use client';

import type { ReactNode } from 'react';
import { useFieldInfo, useTsController } from '@ts-react/form';
import { useId } from 'react';
import { Fieldset, XStack, Stack, Label } from 'tamagui';
import type { SwitchProps, CheckedState } from 'tamagui';
import { Switch } from '../../Switch';
import { Text } from '../../Text';
import { Alert } from '../../Alert';

export interface SwitchFieldProps extends Pick<SwitchProps, 'testID' | 'disabled'> {
  outlined?: boolean;
  children?: ReactNode;
  hint?: string;
}

export const SwitchField = ({ testID, disabled = false, outlined = true, hint }: SwitchFieldProps) => {
  const {
    field,
    error,
    formState: { isSubmitting },
  } = useTsController<CheckedState>();
  const { label } = useFieldInfo();
  const id = useId();
  const isDisabled = isSubmitting || disabled;

  const isChecked = field.value === 'indeterminate' ? false : field.value;

  return (
    <Fieldset>
      <XStack
        alignItems="center"
        justifyContent="space-between"
        paddingHorizontal={outlined ? '$space.lg' : null}
        paddingVertical={outlined ? '$space.sm' : null}
        borderWidth={outlined ? 1 : undefined}
        borderRadius="$radius.md"
        borderColor="$border/surface-subdued"
        overflow="hidden"
      >
        <Label testID={`${testID || field.name}-label`} htmlFor={id}>
          <Text variant="bodyMediumEm" color="$form/color/form-fg-default">
            {label}
          </Text>
        </Label>
        <Switch
          ref={field.ref}
          testID={`${testID || field.name}-switch`}
          disabled={isDisabled}
          checked={isChecked}
          onPress={() => {
            field.onChange(!isChecked);
            return Promise.resolve();
          }}
        />
      </XStack>
      {error?.errorMessage ? (
        <Stack marginTop="$space.md">
          <Alert severity="danger" variant="inline-transparent">
            {error.errorMessage}
          </Alert>
        </Stack>
      ) : null}
      {hint && !error ? (
        <Text marginTop="$xs" variant="bodySmall" color="$form/color/form-fg-subdued" selectable={false}>
          {hint}
        </Text>
      ) : null}
    </Fieldset>
  );
};
