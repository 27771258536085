import type { GetProps } from 'tamagui';
import { styled, TextArea as TextAreaTamagui, YStack, isWeb, Stack, getTokenValue } from 'tamagui';
import Text from '../Text/Text';
import { shadowOpacity, shadowRadius } from '../../tokens/shadow';

const getTextAreaBaseVariantTokens = (size: '$sm' | '$md' | '$lg' | '$xl') => {
  switch (size) {
    case '$sm':
      return {
        paddingHorizontal: '$space.input/space/horizontal-padding-sm',
        paddingVertical: '$space.input/space/vertical-padding-sm',
        height: '$size.input/size/sm',
        fontSize: '$sm',
        fontWeight: '$sm',
        lineHeight: '$sm',
        letterSpacing: '$sm',
        minHeight: 80,
      } as const;
    case '$md':
      return {
        paddingHorizontal: '$space.input/space/horizontal-padding-md',
        paddingVertical: '$space.input/space/vertical-padding-md',
        height: '$size.input/size/md',
        fontSize: '$sm',
        fontWeight: '$sm',
        lineHeight: '$sm',
        letterSpacing: '$sm',
        minHeight: 100,
      } as const;
    case '$lg':
      return {
        paddingHorizontal: '$space.input/space/horizontal-padding-lg',
        paddingVertical: '$space.input/space/vertical-padding-lg',
        height: '$size.input/size/lg',
        fontSize: '$md',
        fontWeight: '$md',
        lineHeight: '$md',
        letterSpacing: '$md',
        minHeight: 120,
      } as const;
    case '$xl':
      return {
        paddingHorizontal: '$space.input/space/horizontal-padding-xl',
        paddingVertical: '$space.input/space/vertical-padding-xl',
        height: '$size.input/size/xl',
        fontSize: '$md',
        fontWeight: '$md',
        lineHeight: '$md',
        letterSpacing: '$md',
        minHeight: 140,
      } as const;
  }
};

const TextAreaBase = styled(TextAreaTamagui, {
  name: 'TextArea',
  fontFamily: '$bodyEmphasised',
  borderWidth: 0,
  color: '$form/color/form-fg-default',
  backgroundColor: '$background/transparent',
  minWidth: 0,
  placeholderTextColor: '$form/color/form-fg-subdued',
  hoverStyle: {},
  focusStyle: {
    borderColor: '$form/color/form-border-selected',
    shadowColor: '$form/color/form-border-selected',
    shadowOpacity: shadowOpacity.on,
    shadowRadius: shadowRadius.on,
    outlineColor: '$form/color/form-border-selected',
    outlineWidth: '$size.input/size/border-focus-width',
    outlineStyle: 'solid',
    outlineOffset: getTokenValue('$size.input/size/border-width'), // borderWidth is still 1 for input text spacing. Offsetting so the outline overlaps the border.
  },
  ...(isWeb
    ? {
        tabIndex: 0,
      }
    : {
        focusable: true,
      }),
  variants: {
    status: {
      default: {
        borderColor: '$form/color/form-border-default',
      },
      success: {
        borderColor: '$form/color/form-border-default',
      },
      error: {
        borderColor: '$form/color/form-border-danger',
      },
    },
    size: {
      $sm: getTextAreaBaseVariantTokens('$sm'),
      $md: getTextAreaBaseVariantTokens('$md'),
      $lg: getTextAreaBaseVariantTokens('$lg'),
      $xl: getTextAreaBaseVariantTokens('$xl'),
    },
    textAreaDisabled: {
      true: {
        backgroundColor: '$background/disabled',
        color: '$foreground/disabled',
      },
    },
  } as const,

  defaultVariants: {
    status: 'default',
  },
});

interface TextAreaFrameExtraProps {
  error?: string;
}

export type TextAreaStatus = 'default' | 'success' | 'error';

export const TextArea = TextAreaBase.styleable<TextAreaFrameExtraProps>((props, ref) => {
  const { status, error, disabled, size, value, maxLength = 280, ...rest } = props;

  let computedStatus: TextAreaStatus | undefined = status;

  // If disabled, override status to be 'default'
  if (disabled) {
    computedStatus = 'default';
  } else if (error) {
    // If error message is given, override status to be 'error'
    computedStatus = 'error';
  }

  const remainingChars = value ? maxLength - value.length : maxLength;
  return (
    <YStack gap="$space.input/space/message-gap">
      <Stack
        borderColor="$form/color/form-border-default"
        borderRadius="$radius.form/radius/formcontrol"
        borderWidth="$size.input/size/border-width"
      >
        <TextAreaBase
          ref={ref}
          status={computedStatus}
          size={size}
          textAreaDisabled={disabled}
          disabled={disabled}
          value={value}
          maxLength={maxLength}
          {...rest}
        />
      </Stack>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="flex-end">
        {error ? (
          <Text variant="bodySmall" color="$form/color/form-fg-danger" selectable={false}>
            {error}
          </Text>
        ) : null}
        <Stack flex={1} />

        <Stack
          key={value}
          animation={[
            '100ms',
            {
              opacity: {
                overshootClamping: true,
              },
            },
          ]}
          enterStyle={{ y: 0, opacity: 25 }}
          exitStyle={{ y: 0, opacity: 25 }}
        >
          <Text alignSelf="flex-end" variant="bodySmall" color="$form/color/form-fg-subdued">
            {remainingChars}
          </Text>
        </Stack>
      </Stack>
    </YStack>
  );
});

export type TextAreaProps = GetProps<typeof TextArea>;
