import { Svg, Path } from 'react-native-svg';
import type { IconProps } from '@tamagui/helpers-icon';
import { themed } from '@tamagui/helpers-icon';
import { memo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- from tamagui/lucide-icons
const Icon = (props: any) => {
  const { color = 'black', size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" stroke="none" {...otherProps}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.614 1.21a1 1 0 00-1.228 0l-9 7A1 1 0 002 9v11a3 3 0 003 3h14a3 3 0 003-3V9a1 1 0 00-.386-.79l-9-7zM9 11a1 1 0 00-1 1v9h2v-8h4v8h2v-9a1 1 0 00-1-1H9z"
        fill={color}
      />
    </Svg>
  );
};

Icon.displayName = 'HomeFilled';

export const HomeFilled = memo<IconProps>(themed(Icon));
