import { type CalculateFontSizes, createGenericFont, type GenericFont } from '../createGenericFont';

export const headingFont = createGenericFont(
  {
    family: 'Poppins-SemiBold',
    weight: {
      md: '700',
    },
    // On Android you need to set the face option in createFont or else fonts wont pick up different weights, due to a React Native restriction.
    face: {
      700: { normal: 'Poppins-SemiBold', italic: 'Poppins-SemiBoldItalic' },
    },
  },
  {
    calculateFontSize: (fontSize) => fontSize,
    calculateLineHeight: (fontSize) => fontSize + 4,
  }
);

const bodySizeFunctions = {
  calculateFontSize: (fontSize) => fontSize,
  calculateLineHeight: (fontSize) => (fontSize >= 20 ? fontSize + 8 : fontSize + 6),
} satisfies CalculateFontSizes;

const bodyFontConfig = {
  family: 'Poppins-Regular',
  weight: {
    md: '400',
  },
  // On Android you need to set the face option in createFont or else fonts wont pick up different weights, due to a React Native restriction.
  face: {
    400: { normal: 'Poppins-Regular', italic: 'Poppins-Italic' },
  },
} as const satisfies GenericFont;

export const bodyFont = createGenericFont(bodyFontConfig, bodySizeFunctions);

const bodyEmphasisedFontConfig = {
  family: 'Poppins-Medium',
  weight: {
    md: '500',
  },
  // On Android you need to set the face option in createFont or else fonts wont pick up different weights, due to a React Native restriction.
  face: {
    500: { normal: 'Poppins-Medium', italic: 'Poppins-MediumItalic' },
  },
} as const satisfies GenericFont;

export const bodyEmphasisedFont = createGenericFont(bodyEmphasisedFontConfig, bodySizeFunctions);

const linkFontDefinitionConfig = {
  family: 'Poppins-Medium',
  weight: {
    md: '500',
  },
  // On Android you need to set the face option in createFont or else fonts wont pick up different weights, due to a React Native restriction.
  face: {
    500: { normal: 'Poppins-Medium', italic: 'Poppins-MediumItalic' },
  },
} as const satisfies GenericFont;

export const linkFont = createGenericFont(linkFontDefinitionConfig, bodySizeFunctions);

const linkEmphasisedFontConfig = {
  family: 'Poppins-SemiBold',
  weight: {
    md: '600',
  },
  // On Android you need to set the face option in createFont or else fonts wont pick up different weights, due to a React Native restriction.
  face: {
    600: { normal: 'Poppins-SemiBold', italic: 'Poppins-SemiBoldItalic' },
  },
} as const satisfies GenericFont;

export const linkEmphasisedFont = createGenericFont(linkEmphasisedFontConfig, bodySizeFunctions);
