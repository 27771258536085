// THIS IS AN AUTOGENERATED FILE. REGENERATE USING THE TOKENS2CODE FIGMA PLUGIN

import type { VariablesCollection } from '../variables';
import { coreTokensSize, coreTokensSpace } from './coreTokens';
import {
  semanticTokensLightColor,
  semanticTokensDarkColor,
  semanticTokensSize,
  semanticTokensRadius,
  semanticTokensSpace,
} from './semanticTokens';

export const componentTokensLightColor = {
  'alert/color/attention/alert-bg-attention': semanticTokensLightColor['background/surface-inverse'],
  'alert/color/attention/alert-border-attention': semanticTokensLightColor['background/transparent'],
  'alert/color/attention/alert-fg-attention': semanticTokensLightColor['foreground/surface-inverse'],
  'alert/color/attention/alert-fg-attention-subdued': semanticTokensLightColor['foreground/surface-inverse-subdued'],
  'alert/color/attention/alert-icon-attention': semanticTokensLightColor['icon/surface'],
  'alert/color/attention/alert-text-attention': semanticTokensLightColor['text/surface'],
  'alert/color/danger/alert-bg-danger': semanticTokensLightColor['background/danger'],
  'alert/color/danger/alert-border-danger': semanticTokensLightColor['background/transparent'],
  'alert/color/danger/alert-fg-danger': semanticTokensLightColor['foreground/danger-default'],
  'alert/color/danger/alert-fg-danger-subdued': semanticTokensLightColor['foreground/danger-subdued'],
  'alert/color/danger/alert-icon-danger': semanticTokensLightColor['icon/danger'],
  'alert/color/danger/alert-text-danger': semanticTokensLightColor['text/danger'],
  'alert/color/info/alert-bg-info': semanticTokensLightColor['background/info'],
  'alert/color/info/alert-border-info': semanticTokensLightColor['background/transparent'],
  'alert/color/info/alert-fg-info': semanticTokensLightColor['foreground/info-default'],
  'alert/color/info/alert-fg-info-subdued': semanticTokensLightColor['foreground/info-subdued'],
  'alert/color/info/alert-icon-info': semanticTokensLightColor['icon/info'],
  'alert/color/info/alert-text-info': semanticTokensLightColor['text/info'],
  'alert/color/neutral/alert-bg-neutral': semanticTokensLightColor['background/neutral'],
  'alert/color/neutral/alert-border-neutral': semanticTokensLightColor['background/transparent'],
  'alert/color/neutral/alert-fg-neutral': semanticTokensLightColor['foreground/neutral'],
  'alert/color/neutral/alert-fg-neutral-subdued': semanticTokensLightColor['foreground/neutral'],
  'alert/color/neutral/alert-icon-neutral': semanticTokensLightColor['foreground/neutral'],
  'alert/color/neutral/alert-text-neutral': semanticTokensLightColor['foreground/neutral'],
  'alert/color/success/alert-bg-success': semanticTokensLightColor['background/success'],
  'alert/color/success/alert-border-success': semanticTokensLightColor['background/transparent'],
  'alert/color/success/alert-fg-subdued': semanticTokensLightColor['foreground/success-subdued'],
  'alert/color/success/alert-fg-success': semanticTokensLightColor['foreground/success-default'],
  'alert/color/success/alert-icon-success': semanticTokensLightColor['icon/success'],
  'alert/color/success/alert-text-success': semanticTokensLightColor['text/success'],
  'alert/color/warning/alert-bg-warning': semanticTokensLightColor['background/warning'],
  'alert/color/warning/alert-border-warning': semanticTokensLightColor['background/transparent'],
  'alert/color/warning/alert-fg-warning': semanticTokensLightColor['foreground/warning-default'],
  'alert/color/warning/alert-fg-warning-subdued': semanticTokensLightColor['foreground/warning-subdued'],
  'alert/color/warning/alert-icon-warning': semanticTokensLightColor['icon/warning'],
  'alert/color/warning/alert-text-warning': semanticTokensLightColor['text/warning'],
  'button/color/button-danger-bg-default': semanticTokensLightColor['background/danger'],
  'button/color/button-danger-bg-hover': semanticTokensLightColor['background/danger-hover'],
  'button/color/button-danger-bg-press': semanticTokensLightColor['background/danger-press'],
  'button/color/button-danger-border': semanticTokensLightColor['background/transparent'],
  'button/color/button-danger-border-hover': semanticTokensLightColor['background/transparent'],
  'button/color/button-danger-border-press': semanticTokensLightColor['background/transparent'],
  'button/color/button-danger-fg': semanticTokensLightColor['foreground/danger-default'],
  'button/color/button-danger-text': semanticTokensLightColor['text/danger'],
  'button/color/button-danger-text-hover': semanticTokensLightColor['text/danger-hover'],
  'button/color/button-danger-text-press': semanticTokensLightColor['text/danger-press'],
  'button/color/button-disabled-bg': semanticTokensLightColor['background/disabled'],
  'button/color/button-disabled-border': semanticTokensLightColor['background/transparent'],
  'button/color/button-disabled-fg': semanticTokensLightColor['foreground/disabled'],
  'button/color/button-focus-border': semanticTokensLightColor['other/focus'],
  'button/color/button-outlined-danger-bg': semanticTokensLightColor['background/transparent'],
  'button/color/button-outlined-danger-bg-hover': semanticTokensLightColor['background/transparent'],
  'button/color/button-outlined-danger-bg-press': semanticTokensLightColor['background/transparent'],
  'button/color/button-outlined-danger-border': semanticTokensLightColor['border/danger'],
  'button/color/button-outlined-danger-border-hover': semanticTokensLightColor['border/danger-hover'],
  'button/color/button-outlined-danger-border-press': semanticTokensLightColor['border/danger-press'],
  'button/color/button-outlined-disabled-border': semanticTokensLightColor['background/transparent'],
  'button/color/button-outlined-primary-bg': semanticTokensLightColor['background/transparent'],
  'button/color/button-outlined-primary-bg-hover': semanticTokensLightColor['background/transparent'],
  'button/color/button-outlined-primary-bg-press': semanticTokensLightColor['background/transparent'],
  'button/color/button-outlined-primary-border': semanticTokensLightColor['border/primary'],
  'button/color/button-outlined-primary-border-hover': semanticTokensLightColor['border/primary-hover'],
  'button/color/button-outlined-primary-border-press': semanticTokensLightColor['border/primary-press'],
  'button/color/button-outlined-secondary-bg': semanticTokensLightColor['background/transparent'],
  'button/color/button-outlined-secondary-bg-hover': semanticTokensLightColor['background/transparent'],
  'button/color/button-outlined-secondary-bg-press': semanticTokensLightColor['background/transparent'],
  'button/color/button-outlined-secondary-border': semanticTokensLightColor['border/secondary'],
  'button/color/button-outlined-secondary-border-hover': semanticTokensLightColor['border/secondary-hover'],
  'button/color/button-outlined-secondary-border-press': semanticTokensLightColor['border/secondary-press'],
  'button/color/button-outlined-tertiary-bg': semanticTokensLightColor['background/transparent'],
  'button/color/button-outlined-tertiary-bg-hover': semanticTokensLightColor['background/transparent'],
  'button/color/button-outlined-tertiary-bg-press': semanticTokensLightColor['background/transparent'],
  'button/color/button-outlined-tertiary-border': semanticTokensLightColor['background/tertiary-default'],
  'button/color/button-outlined-tertiary-border-hover': semanticTokensLightColor['background/tertiary-hover'],
  'button/color/button-outlined-tertiary-border-press': semanticTokensLightColor['background/tertiary-press'],
  'button/color/button-primary-bg-default': semanticTokensLightColor['background/primary-default'],
  'button/color/button-primary-bg-hover': semanticTokensLightColor['background/primary-hover'],
  'button/color/button-primary-bg-press': semanticTokensLightColor['background/primary-press'],
  'button/color/button-primary-border': semanticTokensLightColor['background/transparent'],
  'button/color/button-primary-border-hover': semanticTokensLightColor['background/transparent'],
  'button/color/button-primary-border-press': semanticTokensLightColor['background/transparent'],
  'button/color/button-primary-fg': semanticTokensLightColor['foreground/primary-default'],
  'button/color/button-primary-text': semanticTokensLightColor['text/primary'],
  'button/color/button-primary-text-hover': semanticTokensLightColor['text/primary-hover'],
  'button/color/button-primary-text-press': semanticTokensLightColor['text/primary-press'],
  'button/color/button-secondary-bg-default': semanticTokensLightColor['background/secondary-default'],
  'button/color/button-secondary-bg-hover': semanticTokensLightColor['background/secondary-hover'],
  'button/color/button-secondary-bg-press': semanticTokensLightColor['background/secondary-press'],
  'button/color/button-secondary-border': semanticTokensLightColor['background/transparent'],
  'button/color/button-secondary-border-hover': semanticTokensLightColor['background/transparent'],
  'button/color/button-secondary-border-press': semanticTokensLightColor['background/transparent'],
  'button/color/button-secondary-fg': semanticTokensLightColor['foreground/secondary-default'],
  'button/color/button-secondary-text': semanticTokensLightColor['text/secondary'],
  'button/color/button-secondary-text-hover': semanticTokensLightColor['text/secondary-hover'],
  'button/color/button-secondary-text-press': semanticTokensLightColor['text/secondary-press'],
  'button/color/button-tertiary-bg-default': semanticTokensLightColor['background/tertiary-default'],
  'button/color/button-tertiary-bg-hover': semanticTokensLightColor['background/tertiary-hover'],
  'button/color/button-tertiary-bg-press': semanticTokensLightColor['background/tertiary-press'],
  'button/color/button-tertiary-border': semanticTokensLightColor['background/transparent'],
  'button/color/button-tertiary-border-hover': semanticTokensLightColor['background/transparent'],
  'button/color/button-tertiary-border-press': semanticTokensLightColor['background/transparent'],
  'button/color/button-tertiary-fg': semanticTokensLightColor['foreground/tertiary-default'],
  'button/color/button-tertiary-text': semanticTokensLightColor['text/tertiary'],
  'button/color/button-tertiary-text-hover': semanticTokensLightColor['text/tertiary-hover'],
  'button/color/button-tertiary-text-press': semanticTokensLightColor['text/tertiary-press'],
  'checkbox/color/checkbox-bg-default': semanticTokensLightColor['background/transparent'],
  'checkbox/color/checkbox-bg-selected': semanticTokensLightColor['background/surface-inverse'],
  'checkbox/color/checkbox-border-danger': semanticTokensLightColor['border/danger'],
  'checkbox/color/checkbox-border-default': semanticTokensLightColor['border/surface'],
  'checkbox/color/checkbox-border-selected': semanticTokensLightColor['background/surface-inverse'],
  'checkbox/color/checkbox-card-bg-default': semanticTokensLightColor['background/transparent'],
  'checkbox/color/checkbox-card-bg-selected': semanticTokensLightColor['background/primary-subdued'],
  'checkbox/color/checkbox-card-border-danger': semanticTokensLightColor['border/danger'],
  'checkbox/color/checkbox-card-border-default': semanticTokensLightColor['border/surface-subdued'],
  'checkbox/color/checkbox-card-border-selected': semanticTokensLightColor['border/primary'],
  'checkbox/color/checkbox-fg-danger': semanticTokensLightColor['text/danger'],
  'checkbox/color/checkbox-fg-default': semanticTokensLightColor['foreground/surface-default'],
  'checkbox/color/checkbox-fg-selected': semanticTokensLightColor['foreground/primary-subdued'],
  'checkbox/color/checkbox-fg-selected-subdued': semanticTokensLightColor['foreground/app-subdued'],
  'checkbox/color/checkbox-fg-subdued': semanticTokensLightColor['foreground/app-subdued'],
  'checkbox/color/checkbox-icon-default': semanticTokensLightColor['icon/surface'],
  'checkbox/color/checkbox-icon-selected': semanticTokensLightColor['icon/surface-inverse'],
  'feature/color/feature-icon-bg': semanticTokensLightColor['background/primary-subdued'],
  'feature/color/feature-icon-fg': semanticTokensLightColor['foreground/primary-default'],
  'form/color/form-bg-default': semanticTokensLightColor['background/transparent'],
  'form/color/form-bg-selected': semanticTokensLightColor['background/transparent'],
  'form/color/form-border-danger': semanticTokensLightColor['border/danger'],
  'form/color/form-border-default': semanticTokensLightColor['border/surface'],
  'form/color/form-border-selected': semanticTokensLightColor['border/primary'],
  'form/color/form-fg-danger': semanticTokensLightColor['text/danger'],
  'form/color/form-fg-default': semanticTokensLightColor['foreground/surface-default'],
  'form/color/form-fg-selected': semanticTokensLightColor['foreground/surface-default'],
  'form/color/form-fg-selected-subdued': semanticTokensLightColor['foreground/app-subdued'],
  'form/color/form-fg-subdued': semanticTokensLightColor['foreground/app-subdued'],
  'form/color/form-icon-default': semanticTokensLightColor['icon/surface'],
  'form/color/form-icon-selected': semanticTokensLightColor['icon/surface'],
  'navigation/color/nav-bg-default': semanticTokensLightColor['background/secondary-default'],
  'navigation/color/nav-bg-hover': semanticTokensLightColor['background/secondary-hover'],
  'navigation/color/nav-bg-selected': semanticTokensLightColor['background/secondary-press'],
  'navigation/color/nav-fg-default': semanticTokensLightColor['foreground/secondary-default'],
  'navigation/color/nav-fg-subdued': semanticTokensLightColor['foreground/secondary-subdued'],
  'navigation/color/nav-focus': semanticTokensLightColor['other/focus'],
  'navigation/color/nav-icon-selected': semanticTokensLightColor['background/surface-highlight'],
  'navigation/color/nav-icon-text-selected': semanticTokensLightColor['text/surface'],
  'radio/color/radio-bg-default': semanticTokensLightColor['background/transparent'],
  'radio/color/radio-bg-selected': semanticTokensLightColor['background/surface-inverse'],
  'radio/color/radio-border-danger': semanticTokensLightColor['border/danger'],
  'radio/color/radio-border-default': semanticTokensLightColor['border/surface'],
  'radio/color/radio-border-selected': semanticTokensLightColor['background/surface-inverse'],
  'radio/color/radio-card-bg-default': semanticTokensLightColor['background/transparent'],
  'radio/color/radio-card-bg-selected': semanticTokensLightColor['background/primary-subdued'],
  'radio/color/radio-card-border-danger': semanticTokensLightColor['border/danger'],
  'radio/color/radio-card-border-default': semanticTokensLightColor['border/surface-subdued'],
  'radio/color/radio-card-border-selected': semanticTokensLightColor['border/primary'],
  'radio/color/radio-fg-default': semanticTokensLightColor['foreground/surface-default'],
  'radio/color/radio-fg-selected': semanticTokensLightColor['foreground/primary-subdued'],
  'radio/color/radio-icon-default': semanticTokensLightColor['icon/surface'],
  'radio/color/radio-icon-selected': semanticTokensLightColor['icon/surface-inverse'],
  'step-navigation/color/step-bg-default': semanticTokensLightColor['background/secondary-default'],
  'step-navigation/color/step-bg-hover': semanticTokensLightColor['background/secondary-hover'],
  'step-navigation/color/step-bg-selected': semanticTokensLightColor['background/secondary-press'],
  'step-navigation/color/step-fg-default': semanticTokensLightColor['foreground/secondary-default'],
  'step-navigation/color/step-fg-subdued': semanticTokensLightColor['foreground/secondary-subdued'],
  'step-navigation/color/step-focus': semanticTokensLightColor['other/focus'],
  'step-navigation/color/step-icon-selected': semanticTokensLightColor['background/surface-highlight'],
  'step-navigation/color/step-icon-text-selected': semanticTokensLightColor['text/surface'],
  'switch/color/switch-bg-default': semanticTokensLightColor['background/transparent'],
  'switch/color/switch-bg-selected': semanticTokensLightColor['background/surface-inverse'],
  'switch/color/switch-border-default': semanticTokensLightColor['border/surface'],
  'switch/color/switch-border-selected': semanticTokensLightColor['background/surface-inverse'],
  'switch/color/switch-card-bg-default': semanticTokensLightColor['background/transparent'],
  'switch/color/switch-card-bg-selected': semanticTokensLightColor['background/primary-subdued'],
  'switch/color/switch-card-border-default': semanticTokensLightColor['border/surface-subdued'],
  'switch/color/switch-card-border-selected': semanticTokensLightColor['border/primary'],
  'switch/color/switch-fg-default': semanticTokensLightColor['foreground/surface-default'],
  'switch/color/switch-fg-selected': semanticTokensLightColor['foreground/primary-subdued'],
  'switch/color/switch-icon-default': semanticTokensLightColor['icon/surface'],
  'switch/color/switch-icon-selected': semanticTokensLightColor['icon/surface-inverse'],
  'toast/color/toast-bg-attention': semanticTokensLightColor['background/surface-inverse'],
  'toast/color/toast-bg-danger': semanticTokensLightColor['background/danger'],
  'toast/color/toast-bg-info': semanticTokensLightColor['background/info'],
  'toast/color/toast-bg-success': semanticTokensLightColor['background/success'],
  'toast/color/toast-bg-warning': semanticTokensLightColor['background/warning'],
  'toast/color/toast-fg-attention': semanticTokensLightColor['foreground/surface-inverse'],
  'toast/color/toast-fg-danger': semanticTokensLightColor['foreground/danger-default'],
  'toast/color/toast-fg-info': semanticTokensLightColor['foreground/info-default'],
  'toast/color/toast-fg-success': semanticTokensLightColor['foreground/success-default'],
  'toast/color/toast-fg-warning': semanticTokensLightColor['foreground/warning-default'],
  'toggle/color/toggle-bg-default': semanticTokensLightColor['background/transparent'],
  'toggle/color/toggle-bg-disabled': semanticTokensLightColor['background/disabled'],
  'toggle/color/toggle-bg-hover': semanticTokensLightColor['background/primary-subdued'],
  'toggle/color/toggle-bg-selected': semanticTokensLightColor['background/surface-inverse'],
  'toggle/color/toggle-border-default': semanticTokensLightColor['border/primary'],
  'toggle/color/toggle-border-disabled': semanticTokensLightColor['border/disabled'],
  'toggle/color/toggle-border-selected': semanticTokensLightColor['background/surface-inverse'],
  'toggle/color/toggle-fg-default': semanticTokensLightColor['text/primary'],
  'toggle/color/toggle-fg-disabled': semanticTokensLightColor['foreground/disabled'],
  'toggle/color/toggle-fg-selected': semanticTokensLightColor['foreground/surface-inverse'],
} as const;
type ComponentTokensBaseTheme = VariablesCollection<typeof componentTokensLightColor>;

export const componentTokensDarkColor: ComponentTokensBaseTheme = {
  'alert/color/attention/alert-bg-attention': semanticTokensDarkColor['background/surface-inverse'],
  'alert/color/attention/alert-border-attention': semanticTokensDarkColor['background/transparent'],
  'alert/color/attention/alert-fg-attention': semanticTokensDarkColor['foreground/surface-inverse'],
  'alert/color/attention/alert-fg-attention-subdued': semanticTokensDarkColor['foreground/surface-inverse-subdued'],
  'alert/color/attention/alert-icon-attention': semanticTokensDarkColor['icon/surface'],
  'alert/color/attention/alert-text-attention': semanticTokensDarkColor['text/surface'],
  'alert/color/danger/alert-bg-danger': semanticTokensDarkColor['background/danger'],
  'alert/color/danger/alert-border-danger': semanticTokensDarkColor['background/transparent'],
  'alert/color/danger/alert-fg-danger': semanticTokensDarkColor['foreground/danger-default'],
  'alert/color/danger/alert-fg-danger-subdued': semanticTokensDarkColor['foreground/danger-subdued'],
  'alert/color/danger/alert-icon-danger': semanticTokensDarkColor['icon/danger'],
  'alert/color/danger/alert-text-danger': semanticTokensDarkColor['text/danger'],
  'alert/color/info/alert-bg-info': semanticTokensDarkColor['background/info'],
  'alert/color/info/alert-border-info': semanticTokensDarkColor['background/transparent'],
  'alert/color/info/alert-fg-info': semanticTokensDarkColor['foreground/info-default'],
  'alert/color/info/alert-fg-info-subdued': semanticTokensDarkColor['foreground/info-subdued'],
  'alert/color/info/alert-icon-info': semanticTokensDarkColor['icon/info'],
  'alert/color/info/alert-text-info': semanticTokensDarkColor['text/info'],
  'alert/color/neutral/alert-bg-neutral': semanticTokensDarkColor['background/neutral'],
  'alert/color/neutral/alert-border-neutral': semanticTokensDarkColor['background/transparent'],
  'alert/color/neutral/alert-fg-neutral': semanticTokensDarkColor['foreground/neutral'],
  'alert/color/neutral/alert-fg-neutral-subdued': semanticTokensDarkColor['foreground/neutral'],
  'alert/color/neutral/alert-icon-neutral': semanticTokensDarkColor['foreground/neutral'],
  'alert/color/neutral/alert-text-neutral': semanticTokensDarkColor['foreground/neutral'],
  'alert/color/success/alert-bg-success': semanticTokensDarkColor['background/success'],
  'alert/color/success/alert-border-success': semanticTokensDarkColor['background/transparent'],
  'alert/color/success/alert-fg-subdued': semanticTokensDarkColor['foreground/success-subdued'],
  'alert/color/success/alert-fg-success': semanticTokensDarkColor['foreground/success-default'],
  'alert/color/success/alert-icon-success': semanticTokensDarkColor['icon/success'],
  'alert/color/success/alert-text-success': semanticTokensDarkColor['text/success'],
  'alert/color/warning/alert-bg-warning': semanticTokensDarkColor['background/warning'],
  'alert/color/warning/alert-border-warning': semanticTokensDarkColor['background/transparent'],
  'alert/color/warning/alert-fg-warning': semanticTokensDarkColor['foreground/warning-default'],
  'alert/color/warning/alert-fg-warning-subdued': semanticTokensDarkColor['foreground/warning-subdued'],
  'alert/color/warning/alert-icon-warning': semanticTokensDarkColor['icon/warning'],
  'alert/color/warning/alert-text-warning': semanticTokensDarkColor['text/warning'],
  'button/color/button-danger-bg-default': semanticTokensDarkColor['background/danger'],
  'button/color/button-danger-bg-hover': semanticTokensDarkColor['background/danger-hover'],
  'button/color/button-danger-bg-press': semanticTokensDarkColor['background/danger-press'],
  'button/color/button-danger-border': semanticTokensDarkColor['background/transparent'],
  'button/color/button-danger-border-hover': semanticTokensDarkColor['background/transparent'],
  'button/color/button-danger-border-press': semanticTokensDarkColor['background/transparent'],
  'button/color/button-danger-fg': semanticTokensDarkColor['foreground/danger-default'],
  'button/color/button-danger-text': semanticTokensDarkColor['text/danger'],
  'button/color/button-danger-text-hover': semanticTokensDarkColor['text/danger-hover'],
  'button/color/button-danger-text-press': semanticTokensDarkColor['text/danger-press'],
  'button/color/button-disabled-bg': semanticTokensDarkColor['background/disabled'],
  'button/color/button-disabled-border': semanticTokensDarkColor['background/transparent'],
  'button/color/button-disabled-fg': semanticTokensDarkColor['foreground/disabled'],
  'button/color/button-focus-border': semanticTokensDarkColor['other/focus'],
  'button/color/button-outlined-danger-bg': semanticTokensDarkColor['background/transparent'],
  'button/color/button-outlined-danger-bg-hover': semanticTokensDarkColor['background/transparent'],
  'button/color/button-outlined-danger-bg-press': semanticTokensDarkColor['background/transparent'],
  'button/color/button-outlined-danger-border': semanticTokensDarkColor['border/danger'],
  'button/color/button-outlined-danger-border-hover': semanticTokensDarkColor['border/danger-hover'],
  'button/color/button-outlined-danger-border-press': semanticTokensDarkColor['border/danger-press'],
  'button/color/button-outlined-disabled-border': semanticTokensDarkColor['background/transparent'],
  'button/color/button-outlined-primary-bg': semanticTokensDarkColor['background/transparent'],
  'button/color/button-outlined-primary-bg-hover': semanticTokensDarkColor['background/transparent'],
  'button/color/button-outlined-primary-bg-press': semanticTokensDarkColor['background/transparent'],
  'button/color/button-outlined-primary-border': semanticTokensDarkColor['border/primary'],
  'button/color/button-outlined-primary-border-hover': semanticTokensDarkColor['border/primary-hover'],
  'button/color/button-outlined-primary-border-press': semanticTokensDarkColor['border/primary-press'],
  'button/color/button-outlined-secondary-bg': semanticTokensDarkColor['background/transparent'],
  'button/color/button-outlined-secondary-bg-hover': semanticTokensDarkColor['background/transparent'],
  'button/color/button-outlined-secondary-bg-press': semanticTokensDarkColor['background/transparent'],
  'button/color/button-outlined-secondary-border': semanticTokensDarkColor['border/secondary'],
  'button/color/button-outlined-secondary-border-hover': semanticTokensDarkColor['border/secondary-hover'],
  'button/color/button-outlined-secondary-border-press': semanticTokensDarkColor['border/secondary-press'],
  'button/color/button-outlined-tertiary-bg': semanticTokensDarkColor['background/transparent'],
  'button/color/button-outlined-tertiary-bg-hover': semanticTokensDarkColor['background/transparent'],
  'button/color/button-outlined-tertiary-bg-press': semanticTokensDarkColor['background/transparent'],
  'button/color/button-outlined-tertiary-border': semanticTokensDarkColor['background/tertiary-default'],
  'button/color/button-outlined-tertiary-border-hover': semanticTokensDarkColor['background/tertiary-hover'],
  'button/color/button-outlined-tertiary-border-press': semanticTokensDarkColor['background/tertiary-press'],
  'button/color/button-primary-bg-default': semanticTokensDarkColor['background/primary-default'],
  'button/color/button-primary-bg-hover': semanticTokensDarkColor['background/primary-hover'],
  'button/color/button-primary-bg-press': semanticTokensDarkColor['background/primary-press'],
  'button/color/button-primary-border': semanticTokensDarkColor['background/transparent'],
  'button/color/button-primary-border-hover': semanticTokensDarkColor['background/transparent'],
  'button/color/button-primary-border-press': semanticTokensDarkColor['background/transparent'],
  'button/color/button-primary-fg': semanticTokensDarkColor['foreground/primary-default'],
  'button/color/button-primary-text': semanticTokensDarkColor['text/primary'],
  'button/color/button-primary-text-hover': semanticTokensDarkColor['text/primary-hover'],
  'button/color/button-primary-text-press': semanticTokensDarkColor['text/primary-press'],
  'button/color/button-secondary-bg-default': semanticTokensDarkColor['background/secondary-default'],
  'button/color/button-secondary-bg-hover': semanticTokensDarkColor['background/secondary-hover'],
  'button/color/button-secondary-bg-press': semanticTokensDarkColor['background/secondary-press'],
  'button/color/button-secondary-border': semanticTokensDarkColor['background/transparent'],
  'button/color/button-secondary-border-hover': semanticTokensDarkColor['background/transparent'],
  'button/color/button-secondary-border-press': semanticTokensDarkColor['background/transparent'],
  'button/color/button-secondary-fg': semanticTokensDarkColor['foreground/secondary-default'],
  'button/color/button-secondary-text': semanticTokensDarkColor['text/secondary'],
  'button/color/button-secondary-text-hover': semanticTokensDarkColor['text/secondary-hover'],
  'button/color/button-secondary-text-press': semanticTokensDarkColor['text/secondary-press'],
  'button/color/button-tertiary-bg-default': semanticTokensDarkColor['background/tertiary-default'],
  'button/color/button-tertiary-bg-hover': semanticTokensDarkColor['background/tertiary-hover'],
  'button/color/button-tertiary-bg-press': semanticTokensDarkColor['background/tertiary-press'],
  'button/color/button-tertiary-border': semanticTokensDarkColor['background/transparent'],
  'button/color/button-tertiary-border-hover': semanticTokensDarkColor['background/transparent'],
  'button/color/button-tertiary-border-press': semanticTokensDarkColor['background/transparent'],
  'button/color/button-tertiary-fg': semanticTokensDarkColor['foreground/tertiary-default'],
  'button/color/button-tertiary-text': semanticTokensDarkColor['text/tertiary'],
  'button/color/button-tertiary-text-hover': semanticTokensDarkColor['text/tertiary-hover'],
  'button/color/button-tertiary-text-press': semanticTokensDarkColor['text/tertiary-press'],
  'checkbox/color/checkbox-bg-default': semanticTokensDarkColor['background/transparent'],
  'checkbox/color/checkbox-bg-selected': semanticTokensDarkColor['background/surface-inverse'],
  'checkbox/color/checkbox-border-danger': semanticTokensDarkColor['border/danger'],
  'checkbox/color/checkbox-border-default': semanticTokensDarkColor['border/surface'],
  'checkbox/color/checkbox-border-selected': semanticTokensDarkColor['background/surface-inverse'],
  'checkbox/color/checkbox-card-bg-default': semanticTokensDarkColor['background/transparent'],
  'checkbox/color/checkbox-card-bg-selected': semanticTokensDarkColor['background/primary-subdued'],
  'checkbox/color/checkbox-card-border-danger': semanticTokensDarkColor['border/danger'],
  'checkbox/color/checkbox-card-border-default': semanticTokensDarkColor['border/surface-subdued'],
  'checkbox/color/checkbox-card-border-selected': semanticTokensDarkColor['border/primary'],
  'checkbox/color/checkbox-fg-danger': semanticTokensDarkColor['text/danger'],
  'checkbox/color/checkbox-fg-default': semanticTokensDarkColor['foreground/surface-default'],
  'checkbox/color/checkbox-fg-selected': semanticTokensDarkColor['foreground/primary-subdued'],
  'checkbox/color/checkbox-fg-selected-subdued': semanticTokensDarkColor['foreground/app-subdued'],
  'checkbox/color/checkbox-fg-subdued': semanticTokensDarkColor['foreground/app-subdued'],
  'checkbox/color/checkbox-icon-default': semanticTokensDarkColor['icon/surface'],
  'checkbox/color/checkbox-icon-selected': semanticTokensDarkColor['icon/surface-inverse'],
  'feature/color/feature-icon-bg': semanticTokensDarkColor['background/primary-subdued'],
  'feature/color/feature-icon-fg': semanticTokensDarkColor['foreground/primary-default'],
  'form/color/form-bg-default': semanticTokensDarkColor['background/transparent'],
  'form/color/form-bg-selected': semanticTokensDarkColor['background/transparent'],
  'form/color/form-border-danger': semanticTokensDarkColor['border/danger'],
  'form/color/form-border-default': semanticTokensDarkColor['border/surface'],
  'form/color/form-border-selected': semanticTokensDarkColor['border/primary'],
  'form/color/form-fg-danger': semanticTokensDarkColor['text/danger'],
  'form/color/form-fg-default': semanticTokensDarkColor['foreground/surface-default'],
  'form/color/form-fg-selected': semanticTokensDarkColor['foreground/surface-default'],
  'form/color/form-fg-selected-subdued': semanticTokensDarkColor['foreground/app-subdued'],
  'form/color/form-fg-subdued': semanticTokensDarkColor['foreground/app-subdued'],
  'form/color/form-icon-default': semanticTokensDarkColor['icon/surface'],
  'form/color/form-icon-selected': semanticTokensDarkColor['icon/surface'],
  'navigation/color/nav-bg-default': semanticTokensDarkColor['background/secondary-default'],
  'navigation/color/nav-bg-hover': semanticTokensDarkColor['background/secondary-hover'],
  'navigation/color/nav-bg-selected': semanticTokensDarkColor['background/secondary-press'],
  'navigation/color/nav-fg-default': semanticTokensDarkColor['foreground/secondary-default'],
  'navigation/color/nav-fg-subdued': semanticTokensDarkColor['foreground/secondary-subdued'],
  'navigation/color/nav-focus': semanticTokensDarkColor['other/focus'],
  'navigation/color/nav-icon-selected': semanticTokensDarkColor['background/surface-highlight'],
  'navigation/color/nav-icon-text-selected': semanticTokensDarkColor['text/surface'],
  'radio/color/radio-bg-default': semanticTokensDarkColor['background/transparent'],
  'radio/color/radio-bg-selected': semanticTokensDarkColor['background/surface-inverse'],
  'radio/color/radio-border-danger': semanticTokensDarkColor['border/danger'],
  'radio/color/radio-border-default': semanticTokensDarkColor['border/surface'],
  'radio/color/radio-border-selected': semanticTokensDarkColor['background/surface-inverse'],
  'radio/color/radio-card-bg-default': semanticTokensDarkColor['background/transparent'],
  'radio/color/radio-card-bg-selected': semanticTokensDarkColor['background/primary-subdued'],
  'radio/color/radio-card-border-danger': semanticTokensDarkColor['border/danger'],
  'radio/color/radio-card-border-default': semanticTokensDarkColor['border/surface-subdued'],
  'radio/color/radio-card-border-selected': semanticTokensDarkColor['border/primary'],
  'radio/color/radio-fg-default': semanticTokensDarkColor['foreground/surface-default'],
  'radio/color/radio-fg-selected': semanticTokensDarkColor['foreground/primary-subdued'],
  'radio/color/radio-icon-default': semanticTokensDarkColor['icon/surface'],
  'radio/color/radio-icon-selected': semanticTokensDarkColor['icon/surface-inverse'],
  'step-navigation/color/step-bg-default': semanticTokensDarkColor['background/secondary-default'],
  'step-navigation/color/step-bg-hover': semanticTokensDarkColor['background/secondary-hover'],
  'step-navigation/color/step-bg-selected': semanticTokensDarkColor['background/secondary-press'],
  'step-navigation/color/step-fg-default': semanticTokensDarkColor['foreground/secondary-default'],
  'step-navigation/color/step-fg-subdued': semanticTokensDarkColor['foreground/secondary-subdued'],
  'step-navigation/color/step-focus': semanticTokensDarkColor['other/focus'],
  'step-navigation/color/step-icon-selected': semanticTokensDarkColor['background/surface-highlight'],
  'step-navigation/color/step-icon-text-selected': semanticTokensDarkColor['text/surface'],
  'switch/color/switch-bg-default': semanticTokensDarkColor['background/transparent'],
  'switch/color/switch-bg-selected': semanticTokensDarkColor['background/surface-inverse'],
  'switch/color/switch-border-default': semanticTokensDarkColor['border/surface'],
  'switch/color/switch-border-selected': semanticTokensDarkColor['background/surface-inverse'],
  'switch/color/switch-card-bg-default': semanticTokensDarkColor['background/transparent'],
  'switch/color/switch-card-bg-selected': semanticTokensDarkColor['background/primary-subdued'],
  'switch/color/switch-card-border-default': semanticTokensDarkColor['border/surface-subdued'],
  'switch/color/switch-card-border-selected': semanticTokensDarkColor['border/primary'],
  'switch/color/switch-fg-default': semanticTokensDarkColor['foreground/surface-default'],
  'switch/color/switch-fg-selected': semanticTokensDarkColor['foreground/primary-subdued'],
  'switch/color/switch-icon-default': semanticTokensDarkColor['icon/surface'],
  'switch/color/switch-icon-selected': semanticTokensDarkColor['icon/surface-inverse'],
  'toast/color/toast-bg-attention': semanticTokensDarkColor['background/surface-inverse'],
  'toast/color/toast-bg-danger': semanticTokensDarkColor['background/danger'],
  'toast/color/toast-bg-info': semanticTokensDarkColor['background/info'],
  'toast/color/toast-bg-success': semanticTokensDarkColor['background/success'],
  'toast/color/toast-bg-warning': semanticTokensDarkColor['background/warning'],
  'toast/color/toast-fg-attention': semanticTokensDarkColor['foreground/surface-inverse'],
  'toast/color/toast-fg-danger': semanticTokensDarkColor['foreground/danger-default'],
  'toast/color/toast-fg-info': semanticTokensDarkColor['foreground/info-default'],
  'toast/color/toast-fg-success': semanticTokensDarkColor['foreground/success-default'],
  'toast/color/toast-fg-warning': semanticTokensDarkColor['foreground/warning-default'],
  'toggle/color/toggle-bg-default': semanticTokensDarkColor['background/transparent'],
  'toggle/color/toggle-bg-disabled': semanticTokensDarkColor['background/disabled'],
  'toggle/color/toggle-bg-hover': semanticTokensDarkColor['background/primary-subdued'],
  'toggle/color/toggle-bg-selected': semanticTokensDarkColor['background/surface-inverse'],
  'toggle/color/toggle-border-default': semanticTokensDarkColor['border/primary'],
  'toggle/color/toggle-border-disabled': semanticTokensDarkColor['border/disabled'],
  'toggle/color/toggle-border-selected': semanticTokensDarkColor['background/surface-inverse'],
  'toggle/color/toggle-fg-default': semanticTokensDarkColor['text/primary'],
  'toggle/color/toggle-fg-disabled': semanticTokensDarkColor['foreground/disabled'],
  'toggle/color/toggle-fg-selected': semanticTokensDarkColor['foreground/surface-inverse'],
} as const;

export const componentTokensColor = {
  light: componentTokensLightColor,
  dark: componentTokensDarkColor,
} as const;

export const componentTokensSpace = {
  'icon-button/space/lg': semanticTokensSpace.md,
  'icon-button/space/md': semanticTokensSpace.sm,
  'icon-button/space/xl': semanticTokensSpace.lg,
  'input/space/horizontal-padding-lg': semanticTokensSpace.lg,
  'input/space/horizontal-padding-md': semanticTokensSpace.md,
  'input/space/horizontal-padding-sm': semanticTokensSpace.sm,
  'input/space/horizontal-padding-xl': semanticTokensSpace.lg,
  'input/space/icon-gap': semanticTokensSpace.sm,
  'input/space/message-gap': semanticTokensSpace.xs,
  'input/space/vertical-padding-lg': semanticTokensSpace.md,
  'input/space/vertical-padding-md': semanticTokensSpace.sm,
  'input/space/vertical-padding-sm': semanticTokensSpace.xs,
  'input/space/vertical-padding-with-label-lg': semanticTokensSpace.sm,
  'input/space/vertical-padding-with-label-md': semanticTokensSpace.xs,
  'input/space/vertical-padding-with-label-sm': semanticTokensSpace.xxs,
  'input/space/vertical-padding-with-label-xl': semanticTokensSpace.sm,
  'input/space/vertical-padding-xl': semanticTokensSpace.lg,
  'multi-step-form/space/lg': semanticTokensSpace['8xl'],
  'multi-step-form/space/md': semanticTokensSpace['8xl'],
  'multi-step-form/space/sm': semanticTokensSpace['5xl'],
  'multi-step-form/space/xs': semanticTokensSpace.xl,
  'navigation/space/nav-icon-divider-gap': coreTokensSpace['1-5'],
  'navigation/space/nav-icon-gap': coreTokensSpace['3'],
  'navigation/space/nav-padding-horizontal': semanticTokensSpace.xl,
  'navigation/space/nav-padding-vertical': semanticTokensSpace.lg,
  'step-navigation/space/step-icon-divider-gap': coreTokensSpace['1-5'],
  'step-navigation/space/step-icon-gap': coreTokensSpace['3'],
  'step-navigation/space/step-padding-horizontal': semanticTokensSpace.xl,
  'step-navigation/space/step-padding-vertical': semanticTokensSpace.lg,
};

export const componentTokensSize = {
  'button/size/lg': semanticTokensSize['4xl'],
  'button/size/md': semanticTokensSize['2xl'],
  'button/size/sm': semanticTokensSize.lg,
  'button/size/xl': semanticTokensSize['5xl'],
  'doc-upload/size/display-height': coreTokensSpace['14'],
  'doc-upload/size/web-upload-height': coreTokensSpace['28'],
  'form/size/lg': semanticTokensSize['4xl'],
  'form/size/md': semanticTokensSize['2xl'],
  'form/size/sm': semanticTokensSize.lg,
  'form/size/xl': semanticTokensSize['5xl'],
  'icon-button/size/icon': coreTokensSize['1-5'],
  'icon-button/size/lg': semanticTokensSize['4xl'],
  'icon-button/size/md': semanticTokensSize['2xl'],
  'icon-button/size/xl': semanticTokensSize['5xl'],
  'icon/size/lg': semanticTokensSize.lg,
  'icon/size/md': semanticTokensSize.md,
  'icon/size/sm': semanticTokensSize.sm,
  'icon/size/xs': semanticTokensSize.xs,
  'input/size/border-focus-width': coreTokensSize['0-25'],
  'input/size/border-width': coreTokensSize['0-12-5'],
  'input/size/lg': semanticTokensSize['4xl'],
  'input/size/md': semanticTokensSize['2xl'],
  'input/size/sm': semanticTokensSize.lg,
  'input/size/xl': semanticTokensSize['5xl'],
  'multi-step-form/size/lg': coreTokensSize['50_percent'],
  'multi-step-form/size/md': coreTokensSize['62-5_percent'],
  'multi-step-form/size/sm': coreTokensSize['75_percent'],
  'multi-step-form/size/xs': coreTokensSize['100_percent'],
  'navigation/size/nav-horizontal-divider-width': coreTokensSize['0-12-5'],
  'navigation/size/nav-outline-width-focus': coreTokensSize['0-25'],
  'navigation/size/nav-vertical-divider-width': coreTokensSize['0-12-5'],
  'navigation/size/sidebar': coreTokensSize['20'],
  'navigation/size/sidebar-collapsed': coreTokensSize['7'],
  'navigation/size/sidebar-header': coreTokensSize['9'],
  'step-navigation/size/sidebar': coreTokensSize['20'],
  'step-navigation/size/step-divider-width': coreTokensSize['0-12-5'],
  'step-navigation/size/step-number-icon-border-width': coreTokensSize['0-25'],
  'step-navigation/size/step-outline-width-focus': coreTokensSize['0-25'],
  'switch/size/lg': semanticTokensSize.xl,
  'switch/size/md': semanticTokensSize.lg,
  'switch/size/sm': semanticTokensSize.sm,
};

export const componentTokensRadius = {
  'alert/radius/alert': semanticTokensRadius['2xl'],
  'button/radius/button-radius': semanticTokensRadius.round,
  'checkbox/radius/checkbox-radius': semanticTokensRadius.sm,
  'fab/radius/fab-radius': semanticTokensRadius.round,
  'form/radius/formcontrol': semanticTokensRadius.md,
  'surface/radius/surface-radius': semanticTokensRadius['2xl'],
  'tab/radius/tab-radius': semanticTokensRadius.round,
  'toast/radius/toast': semanticTokensRadius.round,
  'toggle/radius/toggle-radius-multi': semanticTokensRadius['2xl'],
  'toggle/radius/toggle-radius-single': semanticTokensRadius.round,
};
