import type { NamedExoticComponent, ReactNode } from 'react';
import { Dialog, XStack, YStack } from 'tamagui';
import { AlertCircle, type IconProps } from '@cxnpl/ui/icons';
import { Text } from '../Text';
import { H3 } from '../Heading';
import { Elevation } from '../Elevation';

export interface AppErrorProps {
  title: string;
  message: string;
  /** Custom Icon. Default icon is AlertCircle */
  icon?: NamedExoticComponent<IconProps>;
  /**
   * Children rendered after title and body
   */
  children?: ReactNode;
  /**
   * Display mode
   */
  display?: 'inline' | 'inline-card' | 'overlay';
  /**
   * Overlay component to render, if display mode is overlay
   */
  overlayBackground?: ReactNode;
}

export const AppError = ({
  title,
  message,
  icon: Icon,
  children,
  display = 'inline',
  overlayBackground,
}: AppErrorProps) => {
  const content = (
    <YStack gap="$2xl" alignItems="center">
      <YStack gap="$2xl" flexGrow={1} $desktop={{ flexGrow: 0 }}>
        <XStack justifyContent="center">
          {Icon ? <Icon color="$text/danger" size="$2xl" /> : <AlertCircle color="$text/danger" size="$2xl" />}
        </XStack>
        <YStack gap="$lg">
          <XStack justifyContent="center">
            <H3 textAlign="center">{title}</H3>
          </XStack>
          <XStack justifyContent="center">
            <Text variant="bodyMedium" textAlign="center" whiteSpace="pre-wrap">
              {message}
            </Text>
          </XStack>
        </YStack>
      </YStack>
      {children}
    </YStack>
  );

  const card = (
    <Elevation
      level={4}
      shadow="soft"
      key="content"
      animateOnly={['transform', 'opacity']}
      animation="100ms"
      enterStyle={{ x: 0, y: -20, opacity: 0, scale: 0.9 }}
      exitStyle={{ x: 0, y: 10, opacity: 0, scale: 0.95 }}
      borderRadius="$surface/radius/surface-radius"
      backgroundColor="$background/surface"
      width="100%"
      padding="$2xl"
      $tablet={{
        maxWidth: 468,
        padding: '$5xl',
      }}
    >
      {content}
    </Elevation>
  );

  if (display === 'inline') {
    return content;
  }

  if (display === 'inline-card') {
    return card;
  }

  return (
    <Dialog open>
      <Dialog.Portal>
        <Dialog.Overlay
          backgroundColor="$other/black-alpha-50"
          key="overlay"
          animation="quick"
          enterStyle={{ opacity: 0 }}
          exitStyle={{ opacity: 0 }}
        >
          {overlayBackground}
        </Dialog.Overlay>
        <Dialog.Content alignItems="center" backgroundColor="$background/transparent" elevate>
          {card}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog>
  );
};
