'use client';

import { useState } from 'react';
import { useWindowDimensions } from 'react-native';
import type { SizeTokens } from 'tamagui';
import { YStack, Adapt, Sheet } from 'tamagui';
import { ChevronDown, Check, ChevronUp } from '@cxnpl/ui/icons';
import { Text } from '../Text';
import type { SelectProps, SelectStatus } from './types';
import { SelectBase } from './SelectBase';

//Size semantic tokens don't match 0-75, so this function gives the two sizes
export const getIconSize: (selectSize: SizeTokens | undefined) => 16 | 24 = (selectSize) => {
  switch (selectSize) {
    case '$sm':
    case '$md':
      return 16;
    default:
      return 24;
  }
};

const getColor = (variant?: 'outlined' | 'no-outline', inverse?: boolean) => {
  if (variant === 'no-outline') {
    return '$button/color/button-primary-text';
  }
  if (inverse) {
    return '$foreground/surface-inverse';
  }
  return undefined;
};

export function Select<T>(props: SelectProps<T>) {
  const { height } = useWindowDimensions();
  const [contentWidth, setContentWidth] = useState<number>(0);
  const [preventViewportScroll, setPreventViewportScroll] = useState<boolean>(false);
  const {
    status,
    id,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- Children here, ignore
    children,
    value,
    defaultValue,
    onValueChange,
    open,
    defaultOpen,
    onOpenChange,
    dir,
    name,
    autoComplete,
    size,
    native,
    disablePreventBodyScroll,
    onActiveChange,
    items,
    itemToLabel,
    itemToValue,
    label,
    placeholder,
    disabled,
    error,
    hint,
    testID,
    variant,
    inverse,
    ...stackProps
  } = props;

  let computedStatus: SelectStatus | undefined = status;

  // If disabled, override status to be 'default'
  if (disabled) {
    computedStatus = 'default';
  } else if (error) {
    // If error message is given, override status to be 'error'
    computedStatus = 'error';
  }

  return (
    <YStack {...stackProps} testID={testID}>
      <SelectBase
        id={id}
        value={value}
        defaultValue={defaultValue}
        onValueChange={onValueChange}
        open={open}
        defaultOpen={defaultOpen}
        onOpenChange={onOpenChange}
        dir={dir}
        name={name}
        autoComplete={autoComplete}
        size={size}
        native={native}
        disablePreventBodyScroll={disablePreventBodyScroll}
        onActiveChange={onActiveChange}
        preventScroll={preventViewportScroll}
      >
        <SelectBase.Trigger
          testID={testID ? `${testID}-trigger` : undefined}
          iconAfter={<ChevronDown size={getIconSize(size)} color={getColor(variant, inverse)} />}
          status={computedStatus}
          disabled={disabled}
          unstyled={variant === 'no-outline'}
          onLayout={(e) => {
            /**
             * This locks the open Select contents width, same way it's done for the Typeahead.
             * Thus, when the layout changes, the contents automatically resize.
             */
            const { width } = e.nativeEvent.layout;
            setContentWidth(width);
            stackProps.onLayout?.(e);
          }}
        >
          <SelectBase.Value
            color={getColor(variant, inverse)} // TODO: Looks like this has no effect??
            placeholder={placeholder ? placeholder : '\u00A0'}
          />
        </SelectBase.Trigger>
        {/**
         * This Adapt and Sheet make the select into a full screen sheet selector in mobile
         * It fixes iPhone issues selecting items, plus it looks much better
         */}
        <Adapt when="mobile" platform="touch">
          <Sheet
            native={!!props.native}
            modal
            dismissOnSnapToBottom
            animationConfig={{
              type: 'timing',
              duration: 250,
            }}
          >
            <Sheet.Frame backgroundColor="$background/surface">
              <Sheet.ScrollView>
                <Adapt.Contents />
              </Sheet.ScrollView>
            </Sheet.Frame>
            <Sheet.Overlay height="100%" animation="lazy" enterStyle={{ opacity: 0 }} exitStyle={{ opacity: 0 }} />
          </Sheet>
        </Adapt>
        <SelectBase.Content>
          <SelectBase.ScrollUpButton
            maxWidth={contentWidth}
            alignItems="center"
            justifyContent="center"
            position="relative"
            width="100%"
            height="$md"
          >
            <YStack>
              <ChevronUp size={20} color={getColor(variant, inverse)} />
            </YStack>
          </SelectBase.ScrollUpButton>
          <SelectBase.Viewport
            maxWidth={contentWidth}
            onLayout={(e) => {
              /*
               * This is a fix to have more control over the behaviour Tamagui and the underlying floating-ui package provide.
               * When the viewport is fully on screen (y + viewportHeight < screen height) disable scrolling, preventing the
               * viewport from 'walking' over the screen. Now, when the floating viewport is partially off-screen, mostly due to it opening
               * centered on the selected item while the trigger is at the bottom of the screen, the 'walking' will be allowed, as it will look good.
               */
              const { height: viewportHeight, y } = e.nativeEvent.layout;
              setPreventViewportScroll(y + viewportHeight < height);
            }}
            maxHeight={height}
          >
            <SelectBase.Group testID={testID ? `${testID}-group` : undefined}>
              {label ? (
                <SelectBase.Label testID={testID ? `${testID}-label` : undefined}>{label}</SelectBase.Label>
              ) : null}
              {items.map((item, index) => {
                const itemValue = itemToValue(item);
                const itemLabel = itemToLabel(item);
                return (
                  <SelectBase.Item
                    key={index}
                    index={index}
                    value={itemValue}
                    testID={testID ? `${testID}-item-${itemValue}` : undefined}
                    cursor="pointer"
                    role="menuitem"
                    backgroundColor={inverse ? '$background/surface-inverse' : undefined}
                    inverse={inverse}
                  >
                    <SelectBase.ItemText width="90%" color={getColor(variant, inverse)}>
                      {itemLabel}
                    </SelectBase.ItemText>
                    <SelectBase.ItemIndicator marginLeft="auto">
                      <Check size={getIconSize(size)} color={inverse ? '$foreground/surface-inverse' : undefined} />
                    </SelectBase.ItemIndicator>
                  </SelectBase.Item>
                );
              })}
            </SelectBase.Group>
          </SelectBase.Viewport>
          <SelectBase.ScrollDownButton
            maxWidth={contentWidth}
            alignItems="center"
            justifyContent="center"
            position="relative"
            width="100%"
            height="$md"
          >
            <YStack>
              <ChevronDown
                size={20}
                // color={variant === 'no-outline' ? '$button/color/button-primary-text' : undefined}
                color="$alert/color/danger/alert-fg-danger"
              />
            </YStack>
          </SelectBase.ScrollDownButton>
        </SelectBase.Content>
      </SelectBase>
      {error ? (
        <Text variant="bodySmall" color="$form/color/form-fg-danger" selectable={false}>
          {error}
        </Text>
      ) : null}
      {hint && !error ? (
        <Text variant="bodySmall" color="$form/color/form-fg-subdued" selectable={false}>
          {hint}
        </Text>
      ) : null}
    </YStack>
  );
}
