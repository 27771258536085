import type { ModalProps } from '../Modal';
import { Modal, ModalDescription } from '../Modal';

// Props for Confirmation Dialog
export interface ConfirmationDialogProps {
  children: string;
  open: boolean;
  title: string;
  confirmText: string;
  cancelText: string;
  setModalOpen: (a: boolean) => void;
  confirmClick: () => Promise<void> | void;
  confirmProps?: Pick<ModalProps['buttons'][0], 'disabled' | 'loading'>;
  cancelClick: () => Promise<void> | void;
  cancelProps?: Pick<ModalProps['buttons'][0], 'disabled' | 'loading'>;
  testID?: string;
}

//Confirmation Dialog is a specific version of the standard modal
export const ConfirmationDialog = ({
  children,
  open,
  title,
  confirmText,
  cancelText,
  setModalOpen,
  confirmClick,
  confirmProps,
  cancelClick,
  cancelProps,
  testID,
}: ConfirmationDialogProps) => {
  return (
    <Modal
      testID={testID || 'confirmation-dialog'}
      open={open}
      title={title}
      dismissible={false}
      setModalOpen={setModalOpen}
      buttons={[
        {
          onPress: async () => {
            await confirmClick();
          },
          variant: 'filled',
          mode: 'primary',
          label: confirmText,
          ...confirmProps,
        },
        {
          onPress: async () => {
            await cancelClick();
          },
          variant: 'outlined',
          mode: 'primary',
          label: cancelText,
          ...cancelProps,
        },
      ]}
    >
      <ModalDescription>{children}</ModalDescription>
    </Modal>
  );
};
