import type { ReactNode } from 'react';
import { Check } from '@tamagui/lucide-icons';
import { useStringFieldInfo, useTsController } from '@ts-react/form';
import { Fieldset, XStack, Stack, Label } from 'tamagui';
import type { CheckboxProps } from 'tamagui';
import type { Animated } from 'react-native';
import type { GetThemeValueForKey } from '@tamagui/web';
import { match } from 'ts-pattern';
import { Checkbox } from '../../Checkbox';
import { Alert } from '../../Alert';
import { H4 } from '../../Heading';

interface Option {
  label: string;
  value: string;
  disabled?: boolean;
}
export interface MultiCheckboxFieldProps extends Pick<CheckboxProps, 'testID' | 'disabled'> {
  outlined?: boolean;
  children?: ({ option }: { option: Option }) => ReactNode;
  options: Option[];
  styles: {
    direction: 'row' | 'column';
    width: number | Animated.AnimatedNode | GetThemeValueForKey<'width'> | null | undefined;
  };
  containerStyles?: React.ComponentProps<typeof XStack>;
}

export const MultiCheckboxField = ({
  testID,
  disabled = false,
  outlined = true,
  children,
  options,
  styles,
  containerStyles,
}: MultiCheckboxFieldProps) => {
  const { field, error, formState } = useTsController<string[]>();
  const isDisabled = formState.isSubmitting || disabled;
  const zodFieldInfo = useStringFieldInfo();
  const { label } = zodFieldInfo;

  return (
    <Fieldset>
      {Boolean(label) && (
        <H4 color="$foreground/surface-default" paddingBottom="$space.xl">
          {label}
        </H4>
      )}
      <Stack width="inherit" flexWrap="wrap" flexDirection={styles.direction}>
        {options.map((item, index, array) => {
          const checked = !!field.value?.includes(item.value);
          const isError = !!error;

          return (
            <XStack
              key={item.value}
              gap="$md"
              alignItems="center"
              padding={outlined ? '$lg' : null}
              marginBottom={outlined && index < array.length - 1 ? '$sm' : null}
              borderWidth={outlined ? '$size.input/size/border-width' : undefined}
              borderRadius="$radius.sm"
              borderColor={
                match({ checked, isError, outlined })
                  .with({ checked: true, outlined: true }, () => '$checkbox/color/checkbox-card-border-selected')
                  .with({ isError: true }, () => '$form/color/form-border-danger')
                  .otherwise(() => '$border/surface-subdued') as GetThemeValueForKey<'backgroundColor'>
              }
              backgroundColor={
                match({ checked, isError, outlined })
                  .with({ checked: true, outlined: true }, () => '$checkbox/color/checkbox-card-bg-selected')
                  .otherwise(() => undefined) as GetThemeValueForKey<'backgroundColor'>
              }
              overflow="hidden"
              width="100%"
              $tablet={{ width: styles.width }}
              paddingBottom="$lg"
              paddingRight="$xl"
              {...containerStyles}
            >
              <Checkbox
                ref={field.ref}
                id={item.value}
                testID={`${testID || item.label}-checkbox`}
                disabled={isDisabled || item.disabled}
                checked={checked}
                onCheckedChange={(checkedState) => {
                  let currentValues = [...new Set(field.value)];
                  if (checkedState) {
                    currentValues.push(item.value);
                  } else {
                    currentValues = currentValues.filter((value) => value !== item.value);
                  }
                  field.onChange(currentValues);
                }}
              >
                <Checkbox.Indicator>
                  <Check
                    color={
                      isDisabled || item.disabled ? '$foreground/disabled' : '$checkbox/color/checkbox-icon-selected'
                    }
                  />
                </Checkbox.Indicator>
              </Checkbox>
              {children ? (
                children({ option: item })
              ) : (
                <Label
                  testID={`${testID || item.label}-label`}
                  htmlFor={item.value}
                  cursor="pointer"
                  color="$form/color/form-fg-default"
                  lineHeight={20}
                  fontSize="$sm"
                  whiteSpace="pre-wrap"
                >
                  {item.label}
                </Label>
              )}
            </XStack>
          );
        })}
      </Stack>
      {error?.errorMessage ? (
        <Stack marginTop="$space.input/space/message-gap">
          <Alert severity="danger" textVariant="bodySmallEm" variant="inline-transparent">
            {error.errorMessage}
          </Alert>
        </Stack>
      ) : null}
    </Fieldset>
  );
};
