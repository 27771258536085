import { styled, Text } from 'tamagui';

export const Heading = styled(Text, {
  tag: 'span',
  name: 'Heading',
  accessibilityRole: 'header',
  fontFamily: '$heading',
  margin: 0,
});

export default Heading;

export const H1 = styled(Heading, {
  name: 'H1',
  tag: 'h1',
  fontSize: '$3xl',
  lineHeight: '$3xl',
  fontWeight: '$3xl',
});

export const H2 = styled(Heading, {
  name: 'H2',
  tag: 'h2',
  fontSize: '$2xl',
  lineHeight: '$2xl',
  fontWeight: '$2xl',
});

export const H3 = styled(Heading, {
  name: 'H3',
  tag: 'h3',
  fontSize: '$xl',
  lineHeight: '$xl',
  fontWeight: '$xl',
});

export const H4 = styled(Heading, {
  name: 'H4',
  tag: 'h4',
  fontSize: '$lg',
  lineHeight: '$lg',
  fontWeight: '$lg',
});

export const H5 = styled(Heading, {
  name: 'H5',
  tag: 'h5',
  fontSize: '$md',
  lineHeight: '$md',
  fontWeight: '$md',
});
