import type { IconProps } from '@tamagui/helpers-icon';
import { themed } from '@tamagui/helpers-icon';
import { memo } from 'react';
import { Path, Svg } from 'react-native-svg';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- from tamagui/lucide-icons
const Icon = (props: any) => {
  const { color = 'black', size = 24, ...otherProps } = props;

  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" stroke="none" {...otherProps}>
      <Path
        d="M13.479 9.883c-1.626-.604-2.512-1.067-2.512-1.803 0-.622.51-.977 1.423-.977 1.667 0 3.379.642 4.558 1.22l.666-4.11c-.935-.447-2.847-1.178-5.49-1.178-1.87 0-3.425.49-4.536 1.401-1.155.954-1.757 2.334-1.757 4 0 3.023 1.847 4.312 4.847 5.403 1.936.688 2.579 1.178 2.579 1.934 0 .732-.63 1.155-1.762 1.155-1.403 0-3.716-.689-5.231-1.578l-.674 4.157c1.304.732 3.705 1.488 6.197 1.488 1.976 0 3.624-.467 4.735-1.356 1.245-.977 1.89-2.422 1.89-4.289 0-3.09-1.89-4.38-4.935-5.468h.002v.001z"
        fill={color}
      />
    </Svg>
  );
};

Icon.displayName = 'StripeLogo';

export const StripeLogo = memo<IconProps>(themed(Icon));
