/* eslint-disable @typescript-eslint/no-explicit-any -- any used for generics */
'use client';

import type { PortalProps } from 'tamagui';
import { YStack, useMedia, Aside, XStack, getTokens, ScrollView, isWeb, Spinner } from 'tamagui';
import { ChevronUp, X } from '@tamagui/lucide-icons';
import type { ReactNode } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { AxiosError as BaseAxiosError } from 'axios';
import { isAxiosError } from 'axios';
import branding from '@cxnpl/ui/brand';
import { Alert, type AlertProps } from '../Alert';
import { IconButton } from '../IconButton';
import { Image } from '../Image';
import { StepNavigation } from '../StepNavigation';
import { Portal } from '../Portal';
import { AnimatePresence } from '../AnimatePresence';
import { AnimatedFormStack } from './components/AnimatedFormStack';

const PortalWrapper = ({ asPortal, children, ...props }: { asPortal: boolean; children: ReactNode } & PortalProps) => {
  if (asPortal) {
    return (
      <Portal pointerEvents="auto" {...props}>
        {children}
      </Portal>
    );
  }

  return <>{children}</>;
};

type DistributiveOmit<T, K extends keyof any> = T extends any ? Omit<T, K> : never;

type AxiosError<D = any> = BaseAxiosError<{ message: string }, D>;

const FALL_BACK_ERROR_MESSAGE =
  'An unexpected error has occurred. Please try again or contact support if the issue persists.';

export type NavigateToStep = (stepId: string) => void;

export interface BaseStepComponentProps {
  onSubmit: (...args: any[]) => Promise<void> | void;
  /**
   * Whether or not the form should be disabled. Any navigation buttons should not be disabled.
   */
  disabled?: boolean;
  /**
   * Whether or not the form is submitting.
   */
  isSubmitting?: boolean;
  /**
   * Whether or not this form is the last one in the flow.
   */
  isLastStep?: boolean;
  /**
   * Callback which navigates to a given step id, if it exists
   */
  navigateToStep: NavigateToStep;
}

export interface SubStep<T extends React.FC<any>> {
  id: string;
  name: string;
  /**
   * Component must have these base props as multi step form component will use these.
   */
  component: T extends React.FC<infer P>
    ? P extends Omit<BaseStepComponentProps, 'navigateToStep'>
      ? T
      : never
    : never;
  props: React.ComponentProps<T> extends Omit<BaseStepComponentProps, 'navigateToStep'>
    ? DistributiveOmit<React.ComponentProps<T>, 'disabled' | 'isSubmitting' | 'navigateToStep'> // 'disabled' will be passed into the component by MultiFormStep based on the 'locked' value.
    : never;
  /**
   *  If true, this step will not be rendered (including navigation link).
   */
  hidden?: boolean;
  /*
   * If true, this step will not be able to be navigated to (disabled navigation link).
   */
  disabled?: boolean;
  /**
   * If true, this step will be marked as completed in the navigation link.
   */
  completed?: boolean;
  /**
   * If true, this step will be marked as locked (non editable), but still be able to be navigated to.
   * This will be passed as the 'disabled' prop to the step component, which should implement how to handle this - ideally disabling all field inputs.
   * Assuming the step component is using SchemaForm, each field component would need to be passed 'disabled' and handle it.
   */
  locked?: boolean;
  /**
   * The message to display at the top of the screen if the step is locked.
   */
  lockedMessage?: AlertProps['children'];
  /**
   * If true, a loading spinner will be rendered instead of the component
   */
  loading?: boolean;
  /**
   * If true, display the component over the full width of the screen
   */
  fullwidth?: boolean;
}

export type SubSteps<T extends any[]> = {
  [K in keyof T]: SubStep<T[K]>;
};

export interface Step<T extends React.FC<any>[]> {
  id: string;
  name: string;
  steps: SubSteps<T>;
  /**
   * If true, entire step group will be hidden, regardless of each step's own `hidden` prop
   */
  hidden?: boolean;
}

export type Steps<T extends React.FC<any>[][]> = {
  [K in keyof T]: Step<T[K]>;
};

export interface MultiStepFormProps<T extends React.FC<any>[][]> {
  /**
   * The config for steps and their respective groupings.
   * */
  steps: Steps<T>;
  /**
   * Callback when the last step of the form is submitted, given all previous active steps are completed.
   * If there is an error thrown here, it will be caught by the form, prevent it from moving forward into the next step and show an error Alert
   * In the case the developer wants more control on the errors using a try/catch, the error must be re-thrown in the catch, otherwise the form will proceed.
   */
  onSubmit: () => Promise<void> | void;
  /**
   * Optional callback that will render a close button which triggers it when pressed
   */
  onClose?: () => void;
  /**
   * Controls if steps must be completed in order. Defaults to true.
   */
  enforceStepOrder?: boolean;
  /**
   * If a step group only has one sub-step, only render the sub-step navigation link
   */
  compactGroupIfSingleChild?: boolean;
  /**
   * Test id
   */
  testID?: string;
  /**
   * Callback when the logo in the side navigation is pressed
   */
  onPressLogo?: () => void;
}

/**
 * This component renders a multi-step form based on given steps
 * 
 * Assumptions
  - Steps must be within a grouping (that also has its own name).
  - Steps can be disabled. If disabled, a navigation link will be rendered, but it won't be pressable.
  - Steps can be hidden. If hidden, a navigation link won't be rendered for it.
  - Steps can be completed. If completed, a check icon will appear next to it's navigation link.
  - Steps can either: be completed in any order OR enforced to be completed in order. The order will be the order of the array of step groups and their steps.
  - Steps can locked. If locked, they can still be navigated to, but component itself has been disabled, e.g. all fields are disabled. The step component should handle this through a 'disabled' prop.
  - Assumes each step's onSubmit is related to their respective completed condition, i.e. each onSubmit will fulfil the completed condition of the step when successful.
  - The form's onSubmit will be called after a successful onSubmit of the last rendered step, if all previous steps are completed.
*/
export function MultiStepForm<T extends React.FC<any>[][]>({
  steps,
  onSubmit,
  onClose,
  enforceStepOrder = true,
  compactGroupIfSingleChild = false,
  testID,
  onPressLogo,
}: MultiStepFormProps<T>) {
  const media = useMedia();
  const tokens = getTokens();
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isStepSubmitting, setIsStepSubmitting] = useState(false);

  const compactMode = !media.tablet;
  const [collapsed, setCollapsed] = useState(true);

  // Only render steps which are not hidden
  const renderedSteps = useMemo(() => {
    // Filter out step groups and steps that are hidden
    const stepsToRender = steps
      .filter((stepGroup) => !stepGroup.hidden)
      .map((stepGroup) => ({
        ...stepGroup,
        steps: stepGroup.steps.filter((step) => !step.hidden),
      }));
    // Also filter out entire groups if all their steps inside are hidden
    return stepsToRender.filter((stepGroup) => stepGroup.steps.length > 0);
  }, [steps]);

  // Remap steps from an array to an object for more efficient read access
  const [stepsById, firstUncompletedStepIndex] = useMemo(() => {
    let stepCount = 0;
    let firstUncompletedStepIdx = renderedSteps.flatMap((stepGroup) => stepGroup.steps).length - 1; // set to last page if all are completed
    let firstUncompletedStepSetFlag = false;
    const stepsObj = renderedSteps.reduce<
      Record<
        string,
        SubStep<React.FC<BaseStepComponentProps>> & { groupId: string; groupName: string; stepIndex: number }
      >
    >((accm, stepGroup) => {
      stepGroup.steps.forEach((step) => {
        // If step is uncompleted, then flag it as the step to start on
        if (!firstUncompletedStepSetFlag && !step.completed && !step.locked) {
          firstUncompletedStepIdx = stepCount;
          firstUncompletedStepSetFlag = true;
        }
        accm[step.id] = { ...step, groupId: stepGroup.id, groupName: stepGroup.name, stepIndex: stepCount };
        stepCount += 1;
      });
      return accm;
    }, {});

    return [stepsObj, firstUncompletedStepIdx];
  }, [renderedSteps]);

  // We've effectively flattened out step groupings into an array of step ids
  const stepIds = Object.keys(stepsById);

  const [currentStepIndex, setCurrentStepIndex] = useState<number>(firstUncompletedStepIndex);
  const [animateDirection, setAnimateDirection] = useState<'forward' | 'back'>('forward');
  const changeStep = useCallback(
    (newStepIndex: number) => {
      if (newStepIndex === currentStepIndex) {
        return;
      }
      const directionToAnimate = newStepIndex > currentStepIndex ? 'forward' : 'back';
      setAnimateDirection(directionToAnimate);
      // Adds to a setTimeout as react needs to update the direction to animate before changing the step.
      // utilizes the fact that a new scope does react updates in a separate render cycle
      requestAnimationFrame(() => {
        setCurrentStepIndex(newStepIndex);
      });
    },
    [currentStepIndex]
  );
  const [submitError, setSubmitError] = useState<Record<string, string>>({});
  const [errorMessageHeight, setErrorMessageHeight] = useState<number>(0);
  const [lockedMessageHeight, setLockedMessageHeight] = useState<number>(0);
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  const currentStepId = stepIds[currentStepIndex] ?? '';
  const currentStep = stepsById[currentStepId];
  const CurrentStepComponent = currentStep?.component;

  // Reset error and locked message heights when no longer rendered
  useEffect(() => {
    if (!currentStep?.locked && lockedMessageHeight > 0) {
      setLockedMessageHeight(0);
    }
  }, [currentStep?.locked, lockedMessageHeight]);

  useEffect(() => {
    if (!submitError[currentStepId] && errorMessageHeight > 0) {
      setErrorMessageHeight(0);
    }
  }, [currentStepId, errorMessageHeight, submitError]);

  // API error should be axios message in response as a string
  // Known cases of an array with messages inside arrays
  const processAPIError = (e: unknown) => {
    let errorMessage: string | undefined;
    if (isAxiosError(e)) {
      const axiosError = e as AxiosError;
      if (axiosError.status && axiosError.status >= 500) {
        errorMessage = FALL_BACK_ERROR_MESSAGE;
      } else if (axiosError.response?.data.message) {
        // Try to process JSON message
        // On failure use the string (Default Method)
        try {
          const body: Record<string, string> | Record<string, string>[] = JSON.parse(axiosError.response.data.message);
          if (Array.isArray(body) && body.length > 0) {
            errorMessage = body[0]?.message || FALL_BACK_ERROR_MESSAGE;
          } else if (!Array.isArray(body)) {
            errorMessage = body.message || FALL_BACK_ERROR_MESSAGE;
          }
        } catch {
          errorMessage = axiosError.response.data.message; // Default method for standard behavior
        }
      }
    } else {
      // User thrown error
      errorMessage = (e as Error).message;
    }
    return errorMessage || FALL_BACK_ERROR_MESSAGE;
  };

  const Navigation = useMemo(() => {
    let stepIndex = 0;
    return (
      <StepNavigation
        defaultOpen={[]}
        testID={testID ? `${testID}-step-navigation` : undefined}
        selected={currentStepId}
        mode={compactMode ? 'compact' : 'full'}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
      >
        {renderedSteps.map((stepGroup, stepGroupIndex) => {
          let allChildrenDisabledOrUnSelectable = true;

          const StepNavigationItems = (() =>
            stepGroup.steps.map((step) => {
              // stepIndex, currentIndex and currentStepIndex will be referring to an index in orderedStepsById
              const currentIndex = stepIndex;
              stepIndex += 1;

              const selected = currentIndex === currentStepIndex;
              const disabled = !!step.disabled;
              const completed = !!step.completed;

              // Check all previous, not hidden steps if they're completed
              // Just checking one previous step is not enough, as the form data may have been directly changed by a service agent.
              const allPreviousStepsCompleted = (() => {
                let previousStepIndex = currentIndex - 1;
                let allPreviousStepsComplete = true;
                while (previousStepIndex >= 0) {
                  const previousStepId = stepIds[previousStepIndex] ?? '';
                  const previousStep = stepsById[previousStepId];
                  if (previousStep && !previousStep.completed) {
                    allPreviousStepsComplete = false;
                    break;
                  }
                  previousStepIndex -= 1;
                }
                return allPreviousStepsComplete;
              })();

              // Navigation item is selectable when:
              // If enforceStepOrder is true - if it's the first step, or all previous steps have been completed
              // If enforceStepOrder is false - then it's always selectable
              let selectable = false;
              if (enforceStepOrder) {
                if (currentIndex === 0) {
                  selectable = true;
                } else {
                  selectable = allPreviousStepsCompleted;
                }
              } else {
                selectable = true;
              }

              // Finding the first selectable index and unflagging if any child is not disabled or selectable
              if (!disabled && selectable) {
                allChildrenDisabledOrUnSelectable = false;
              }

              return (
                <StepNavigation.Item
                  testID={testID ? `${testID}-${step.id}-step-navigation-item-button` : undefined}
                  key={`${stepIndex}_${step.id}`}
                  id={step.id}
                  label={step.name}
                  completed={completed}
                  disabled={disabled || !selectable}
                  onPress={() => {
                    // If the step is disabled, not selectable, or already selected, don't navigate to it
                    if (disabled || !selectable || selected) {
                      return;
                    }
                    changeStep(currentIndex);
                  }}
                />
              );
            }))();

          return stepGroup.steps.length === 1 && compactGroupIfSingleChild ? (
            StepNavigationItems
          ) : (
            <StepNavigation.Group
              testID={testID ? `${testID}-${stepGroup.id}-step-navigation-group-button` : undefined}
              key={`${stepGroupIndex}_${stepGroup.id}`}
              id={stepGroup.id}
              label={stepGroup.name}
              // Disabled if all children are also disabled or un-selectable
              disabled={allChildrenDisabledOrUnSelectable}
            >
              {StepNavigationItems}
            </StepNavigation.Group>
          );
        })}
      </StepNavigation>
    );
  }, [
    collapsed,
    compactGroupIfSingleChild,
    compactMode,
    currentStepId,
    currentStepIndex,
    enforceStepOrder,
    renderedSteps,
    stepIds,
    stepsById,
    testID,
    changeStep,
  ]);

  return (
    <YStack
      testID={testID ? `${testID}-root-container` : undefined}
      backgroundColor="$background/surface"
      flexGrow={1}
      maxHeight="100%"
      $tablet={{
        flexDirection: 'row',
      }}
    >
      {/* SIDEBAR */}
      <YStack
        width="100%"
        $tablet={{
          width: '$size.step-navigation/size/sidebar',
          height: '100%',
        }}
      >
        <Aside
          backgroundColor={
            compactMode
              ? /* FIXME: the $background/surface color should be added as alias into "components" tier e.g. navigation/color/compact-nav-bg-default etc  */
                '$background/surface'
              : '$navigation/color/nav-bg-default'
          }
          // Web specific css
          style={{
            width: 'inherit',
            height: 'inherit',
          }}
          $tablet={{
            // @ts-expect-error -- web specific css
            position: 'fixed',
            top: 0,
            left: 0,
          }}
        >
          {!compactMode ? (
            <XStack
              paddingVertical="$space.step-navigation/space/step-padding-vertical"
              paddingHorizontal="$space.step-navigation/space/step-padding-horizontal"
              height="$size.navigation/size/sidebar-header"
              alignItems="center"
            >
              <YStack
                onPress={onPressLogo}
                role={onPressLogo ? 'button' : 'img'}
                cursor={onPressLogo ? 'pointer' : undefined}
              >
                <Image
                  src={branding.assets.logo.src}
                  width={branding.assets.logo.width}
                  height={branding.assets.logo.height}
                  alt=""
                  style={{ flex: 1 }}
                />
              </YStack>
            </XStack>
          ) : null}
          {compactMode ? (
            <>
              {collapsed ? (
                <XStack
                  paddingVertical="$space.step-navigation/space/step-padding-vertical"
                  paddingHorizontal="$space.step-navigation/space/step-padding-horizontal"
                  justifyContent="space-between"
                  alignItems="center"
                  onLayout={(e) => {
                    setHeaderHeight(e.nativeEvent.layout.height);
                  }}
                >
                  {/* Previous step button */}
                  {currentStepIndex > 0 ? (
                    <IconButton
                      key="previous-step-button"
                      aria-label="Previous step button"
                      icon={<ChevronUp />}
                      onPress={() => {
                        if (currentStepIndex <= 0) {
                          return;
                        }
                        // Not the first step, go to the previous step
                        changeStep(currentStepIndex - 1);
                      }}
                      mode="secondary"
                      variant="icon-only"
                      size="lg"
                      marginLeft={-tokens.space['icon-button/space/lg'].val}
                    />
                  ) : (
                    <IconButton
                      key="empty-button"
                      aria-label=""
                      disabled
                      // eslint-disable-next-line react/jsx-no-useless-fragment -- Empty space to align logo to the center
                      icon={<></>}
                      onPress={() => {
                        // Do nothing
                      }}
                      mode="secondary"
                      variant="icon-only"
                      size="lg"
                    />
                  )}
                  {/* Logo */}
                  <YStack
                    onPress={onPressLogo}
                    role={onPressLogo ? 'button' : 'img'}
                    cursor={onPressLogo ? 'pointer' : undefined}
                  >
                    <Image
                      src={branding.assets.logo.src}
                      width={branding.assets.logo.width}
                      height={branding.assets.logo.height}
                      alt=""
                      style={{ flex: 1 }}
                    />
                  </YStack>
                  {/* Close button */}
                  <YStack>
                    {onClose ? (
                      <IconButton
                        key="close-button"
                        aria-label="Close button"
                        icon={<X />}
                        onPress={onClose}
                        mode="secondary"
                        variant="icon-only"
                        size="lg"
                        marginRight={-tokens.space['icon-button/space/lg'].val}
                      />
                    ) : (
                      <IconButton
                        key="empty-button"
                        aria-label=""
                        disabled
                        // eslint-disable-next-line react/jsx-no-useless-fragment -- Empty space to align logo to the center
                        icon={<></>}
                        onPress={() => {
                          // Do nothing
                        }}
                        mode="secondary"
                        variant="icon-only"
                        size="lg"
                      />
                    )}
                  </YStack>
                </XStack>
              ) : (
                <XStack
                  backgroundColor="$step-navigation/color/step-bg-default"
                  paddingVertical="$space.step-navigation/space/step-padding-vertical"
                  paddingHorizontal="$space.step-navigation/space/step-padding-horizontal"
                  flexDirection="row-reverse"
                  alignItems="center"
                  onLayout={(e) => {
                    setHeaderHeight(e.nativeEvent.layout.height);
                  }}
                >
                  {/* Close navigation menu button only */}
                  <IconButton
                    icon={<X />}
                    iconProps={{
                      color: '$step-navigation/color/step-fg-default',
                    }}
                    aria-label="Close navigation menu button"
                    onPress={() => {
                      setCollapsed(true);
                    }}
                    mode="secondary"
                    variant="icon-only"
                    size="lg"
                    marginRight={-tokens.space['icon-button/space/lg'].val}
                  />
                </XStack>
              )}
            </>
          ) : null}
          <PortalWrapper asPortal={!!(compactMode && !collapsed)} marginTop={headerHeight}>
            {Navigation}
          </PortalWrapper>
        </Aside>
      </YStack>
      {/* MAIN CONTENT */}
      <YStack
        flexGrow={1}
        backgroundColor="$background/surface"
        $mobile={{
          height: undefined,
        }}
        overflow="hidden"
      >
        <AnimatePresence
          key={`${currentStepId}-animate-presence`}
          custom={{ animateDirection }}
          initial={false}
          presenceAffectsLayout={false}
        >
          <AnimatedFormStack
            key={currentStepId}
            animateDirection={animateDirection}
            backgroundColor="$background/surface"
            justifyContent="space-evenly"
            alignItems="center"
            fullscreen
            y={0}
            overflow="hidden"
            flex={1}
          >
            <ScrollView
              testID={testID ? `${testID}-${currentStepId}-container` : undefined}
              width="100%"
              paddingHorizontal="$2xl"
              flex={1}
              id="scroll-view"
              contentContainerStyle={{
                paddingVertical: tokens.space.$2xl.val,

                alignItems: compactMode ? 'flex-start' : 'center',
                justifyContent: 'flex-start',
                width: '100%',
                minHeight: compactMode ? undefined : '100%',
              }}
              $tablet={{}}
              $laptop={{
                paddingHorizontal: '$space.8xl',
              }}
              $desktop={{
                paddingHorizontal: '$space.8xl',
              }}
            >
              {currentStep?.locked && currentStep.lockedMessage ? (
                <YStack
                  key={currentStep.id}
                  animation="250ms"
                  id="lockedmessage"
                  zIndex={2}
                  width="100%"
                  alignSelf="flex-start"
                  paddingHorizontal={compactMode ? undefined : '$space.6xl'}
                  paddingBottom="$xl"
                  $tablet={{
                    alignSelf: 'unset',
                    marginBottom: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 2,
                    paddingTop: 0,
                  }}
                  onLayout={(e) => {
                    setLockedMessageHeight(e.nativeEvent.layout.height);
                  }}
                >
                  <Alert variant="inline" severity="warning">
                    {currentStep.lockedMessage}
                  </Alert>
                </YStack>
              ) : null}
              {submitError[currentStepId] ? (
                <YStack
                  zIndex={2}
                  width="100%"
                  alignSelf="flex-start"
                  paddingHorizontal={compactMode ? undefined : '$space.6xl'}
                  paddingBottom="$xl"
                  $tablet={{
                    // width: 'unset',
                    alignSelf: 'unset',
                    marginBottom: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 2,
                    paddingTop: 0,
                  }}
                  onLayout={(e) => {
                    setErrorMessageHeight(e.nativeEvent.layout.height);
                  }}
                >
                  <Alert
                    variant="inline"
                    severity="danger"
                    onDismiss={() => {
                      setErrorMessageHeight(0);
                      setSubmitError((prev) => ({
                        ...prev,
                        [currentStepId]: '',
                      }));
                    }}
                  >
                    {submitError[currentStepId]}
                  </Alert>
                </YStack>
              ) : null}
              {currentStep?.loading ? (
                <YStack
                  zIndex={20}
                  position="absolute"
                  top={0}
                  bottom={0}
                  left={0}
                  right={0}
                  justifyContent="center"
                  alignItems="center"
                  pointerEvents="box-only"
                  style={
                    isWeb
                      ? {
                          backdropFilter: 'blur(1px)',
                        }
                      : {
                          backgroundColor: '$background/surface',
                        }
                  }
                >
                  <Spinner size="large" color="$background/primary-default" />
                </YStack>
              ) : null}
              {/* currentStep and CurrentStepComponent should technically always be defined, but adding for type safety */}
              <YStack
                flexGrow={1}
                alignItems="center"
                justifyContent={compactMode ? 'flex-start' : 'center'}
                width="inherit"
              >
                {currentStep && CurrentStepComponent ? (
                  <YStack
                    id="current-step"
                    display="block"
                    alignItems={compactMode ? 'flex-start' : 'center'}
                    width={compactMode ? 'inherit' : '$size.multi-step-form/size/xs'}
                    maxWidth="$size.multi-step-form/size/xs"
                    $tablet={{
                      // 75%
                      width: currentStep.fullwidth ? undefined : '$size.multi-step-form/size/sm',
                      maxWidth: currentStep.fullwidth ? undefined : '$size.multi-step-form/size/sm',
                    }}
                    $laptop={{
                      // 62.5%
                      width: currentStep.fullwidth ? undefined : '$size.multi-step-form/size/md',
                      maxWidth: currentStep.fullwidth ? undefined : '$size.multi-step-form/size/md',
                    }}
                    $desktop={{
                      // 50%
                      width: currentStep.fullwidth ? undefined : '$size.multi-step-form/size/lg',
                      maxWidth: currentStep.fullwidth ? undefined : '$size.multi-step-form/size/lg',
                    }}
                  >
                    <CurrentStepComponent
                      key={currentStep.id}
                      {...currentStep.props}
                      navigateToStep={(stepId) => {
                        const stepIndex = stepsById[stepId]?.stepIndex;
                        if (stepIndex === undefined) {
                          return;
                        }
                        // If not enforceStepOrder, or if enforceStepOrder, then stepIndex must be the current uncompleted step, or the previous completed steps
                        if (!enforceStepOrder || stepIndex <= firstUncompletedStepIndex) {
                          changeStep(stepIndex);
                        }
                      }}
                      disabled={currentStep.locked} // A 'locked' step is passed the component's 'disabled' prop, which should handle it
                      isSubmitting={isFormSubmitting || isStepSubmitting}
                      isLastStep={currentStepIndex === stepIds.length - 1}
                      onSubmit={async (...props) => {
                        // If component is not locked, then call the component's onSubmit first
                        // There's no need to call a locked step's onSubmit again as the field values haven't changed
                        if (!currentStep.locked) {
                          try {
                            setIsStepSubmitting(true);
                            setSubmitError((prev) => ({
                              ...prev,
                              [currentStepId]: '',
                            }));
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- onSubmit will have any[] props
                            await currentStep.props.onSubmit(...props);
                            setIsStepSubmitting(false);
                          } catch (e) {
                            setIsStepSubmitting(false);
                            const errorMessage = processAPIError(e);
                            setSubmitError((prev) => ({
                              ...prev,
                              [currentStepId]: errorMessage,
                            }));
                            return;
                          }
                        }

                        // If current step is the last step
                        if (currentStepIndex === stepIds.length - 1) {
                          // If all previous, active (not hidden), aren't completed yet.
                          // This assume that a successful onSubmit has completed the last step.
                          if (
                            Object.values(stepsById)
                              // stepsById has already step.hidden filtered out, since it's based on renderedSteps
                              .filter((step) => step.id !== currentStep.id)
                              .some((step) => !step.completed)
                          ) {
                            // Show message to inform user to complete all screens
                            setSubmitError((prev) => ({
                              ...prev,
                              [currentStepId]: 'Please complete all previous steps to continue',
                            }));
                          } else {
                            // All steps complete, call form's onSubmit
                            setIsFormSubmitting(true);
                            try {
                              setSubmitError((prev) => ({
                                ...prev,
                                [currentStepId]: '',
                              }));
                              await onSubmit();
                            } catch (e) {
                              const errorMessage = processAPIError(e);
                              setSubmitError((prev) => ({
                                ...prev,
                                [currentStepId]: errorMessage,
                              }));
                            }
                            setIsFormSubmitting(false);
                          }
                        } else {
                          // Not the last step yet, go to the next step
                          changeStep(currentStepIndex + 1);
                        }
                      }}
                    />
                  </YStack>
                ) : null}
              </YStack>
            </ScrollView>
          </AnimatedFormStack>
        </AnimatePresence>
        {/* FLOATING BUTTONS */}
        {!compactMode ? (
          <>
            {/* Back button */}
            {currentStepIndex > 0 ? (
              <IconButton
                icon={<ChevronUp />}
                aria-label="Previous step button"
                onPress={() => {
                  if (currentStepIndex <= 0) {
                    return;
                  }
                  // Not the first step, go to the previous step
                  changeStep(currentStepIndex - 1);
                }}
                mode="secondary"
                variant="outlined"
                size="lg"
                testID="back-button"
                position="absolute"
                top="$space.xl"
                left="$space.2xl"
                zIndex={10}
              />
            ) : null}
            {/* Close button */}
            {onClose ? (
              <IconButton
                zIndex={10}
                icon={<X />}
                aria-label="Close button"
                onPress={onClose}
                mode="secondary"
                variant="outlined"
                size="lg"
                position="absolute"
                top="$space.xl"
                right="$space.2xl"
              />
            ) : null}
          </>
        ) : null}
      </YStack>
    </YStack>
  );
}
