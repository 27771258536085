import { AlertDialog as TamaguiAlertDialog } from 'tamagui';
import type { ElevationProps } from '../Elevation';
import { Elevation } from '../Elevation';

interface AlertDialogProps {
  children: React.ReactNode;
  showDialog: boolean;
  testID?: string;
  containerProps?: ElevationProps;
}

export const AlertDialog = ({ children, showDialog, testID, containerProps }: AlertDialogProps) => {
  return (
    <TamaguiAlertDialog>
      <TamaguiAlertDialog.Portal forceMount={showDialog ? true : undefined}>
        <TamaguiAlertDialog.Overlay
          backgroundColor="$other/black-alpha-50"
          key="overlay"
          animation="quick"
          enterStyle={{ opacity: 0 }}
          exitStyle={{ opacity: 0 }}
        />
        <TamaguiAlertDialog.Content
          testID={testID}
          padding="$space.xl"
          width="100%"
          alignItems="center"
          backgroundColor="$background/transparent"
          elevate
        >
          <Elevation
            level={4}
            shadow="soft"
            key="content"
            animateOnly={['transform', 'opacity']}
            animation="100ms"
            enterStyle={{ x: 0, y: -20, opacity: 0, scale: 0.9 }}
            exitStyle={{ x: 0, y: 10, opacity: 0, scale: 0.95 }}
            borderRadius="$surface/radius/surface-radius"
            backgroundColor="$background/surface"
            width="100%"
            padding="$space.xl"
            maxWidth={360}
            {...containerProps}
          >
            {children}
          </Elevation>
        </TamaguiAlertDialog.Content>
      </TamaguiAlertDialog.Portal>
    </TamaguiAlertDialog>
  );
};
