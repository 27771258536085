import { Svg, Path } from 'react-native-svg';
import type { IconProps } from '@tamagui/helpers-icon';
import { themed } from '@tamagui/helpers-icon';
import { memo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- from tamagui/lucide-icons
const Icon = (props: any) => {
  const { color = 'black', size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" stroke="none" {...otherProps}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 4a2 2 0 012-2h5a2 2 0 012 2v5a2 2 0 01-2 2H4a2 2 0 01-2-2V4zM2 15a2 2 0 012-2h5a2 2 0 012 2v5a2 2 0 01-2 2H4a2 2 0 01-2-2v-5zM13 4a1 1 0 011-1h7a1 1 0 110 2h-7a1 1 0 01-1-1zM13 9a1 1 0 011-1h7a1 1 0 110 2h-7a1 1 0 01-1-1zM13 15a1 1 0 011-1h7a1 1 0 110 2h-7a1 1 0 01-1-1zM13 20a1 1 0 011-1h7a1 1 0 110 2h-7a1 1 0 01-1-1z"
        fill={color}
      />
    </Svg>
  );
};

Icon.displayName = 'LayoutListFilled';

export const LayoutListFilled = memo<IconProps>(themed(Icon));
