'use client';

import { useStringFieldInfo, useTsController } from '@ts-react/form';
import { useId } from 'react';
import { Fieldset, YStack } from 'tamagui';
import type { SelectProps } from '../../Select';
import { Select } from '../../Select';
import { Label } from '../../Label';

export type SelectFieldProps<T> = Pick<
  SelectProps<T>,
  | 'size'
  | 'status'
  | 'disabled'
  | 'hint'
  | 'testID'
  | 'items'
  | 'itemToValue'
  | 'itemToLabel'
  | 'onActiveChange'
  | 'onValueChange'
> & {
  fieldLabel?: string;
};

export function SelectField<T>({
  size = '$lg',
  status,
  disabled,
  hint,
  testID,
  items,
  itemToValue,
  itemToLabel,
  onActiveChange,
  onValueChange,
  fieldLabel,
}: SelectFieldProps<T>): JSX.Element {
  const {
    field,
    error, // zod error message
    formState,
  } = useTsController<string>();
  const { isSubmitting } = formState;
  const zodFieldInfo = useStringFieldInfo();
  const { label, placeholder } = zodFieldInfo;
  const id = useId();
  const isDisabled = disabled || isSubmitting;

  const typedItems: T[] = items as T[];

  return (
    <Fieldset>
      <YStack gap="$lg">
        {fieldLabel ? <Label variant="bodyMediumEm">{fieldLabel}</Label> : null}
        <Select
          ref={field.ref}
          id={id}
          size={size}
          testID={`${testID || field.name}`}
          disabled={isDisabled}
          label={label}
          placeholder={placeholder}
          value={field.value ?? ''} // default empty string to prevent "uncontrolled to controlled" react warning
          error={error?.errorMessage}
          hint={hint}
          status={status}
          items={typedItems}
          itemToValue={itemToValue}
          itemToLabel={itemToLabel}
          onActiveChange={(value, index) => {
            onActiveChange?.(value, index);
          }}
          onValueChange={(value) => {
            field.onChange(value);
            void onValueChange?.(value);
          }}
        />
      </YStack>
    </Fieldset>
  );
}
