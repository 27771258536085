import { Svg, Path } from 'react-native-svg';
import type { IconProps } from '@tamagui/helpers-icon';
import { themed } from '@tamagui/helpers-icon';
import { memo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- from tamagui/lucide-icons
const Icon = (props: any) => {
  const { color = 'black', size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
      <Path fillRule="evenodd" clipRule="evenodd" d="M9.5 14a1 1 0 011-1h1a1 1 0 110 2h-1a1 1 0 01-1-1z" fill={color} />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 13a1 1 0 100 2 1 1 0 000-2zm-3 1a3 3 0 116 0 3 3 0 01-6 0zM7.5 13a1 1 0 100 2 1 1 0 000-2zm-3 1a3 3 0 116 0 3 3 0 01-6 0zM16.5 8a1 1 0 011-1h5a1 1 0 110 2h-5a1 1 0 01-1-1z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5 8a1 1 0 011 1v1a1 1 0 11-2 0V9a1 1 0 011-1zM1.5 9a2 2 0 012-2h14a2 2 0 012 2v3a3 3 0 01-3 3h-.353v-2h.353a1 1 0 001-1V9h-14v3a1 1 0 001 1h.824v2H4.5a3 3 0 01-3-3V9z"
        fill={color}
      />
    </Svg>
  );
};

Icon.displayName = 'CustomTrailer';

export const CustomTrailer = memo<IconProps>(themed(Icon));
