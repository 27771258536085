import { Svg, Path } from 'react-native-svg';
import type { IconProps } from '@tamagui/helpers-icon';
import { themed } from '@tamagui/helpers-icon';
import { memo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- from tamagui/lucide-icons
const Icon = (props: any) => {
  const { color = 'black', size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" stroke="none" {...otherProps}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.99967 18.3334C4.54134 18.3334 4.14898 18.1702 3.82259 17.8438C3.4962 17.5174 3.33301 17.1251 3.33301 16.6667V3.33341C3.33301 2.87508 3.4962 2.48272 3.82259 2.15633C4.14898 1.82994 4.54134 1.66675 4.99967 1.66675H11.6663L16.6663 6.66675V16.6667C16.6663 17.1251 16.5031 17.5174 16.1768 17.8438C15.8504 18.1702 15.458 18.3334 14.9997 18.3334H4.99967ZM10.833 7.50008V3.33341H4.99967V16.6667H14.9997V7.50008H10.833Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.39798 15.0001H8.09289V13.9584H6.68047V11.8751H8.09289V10.8334H6.39798C6.2379 10.8334 6.10372 10.9 5.99544 11.0331C5.88715 11.1662 5.83301 11.3311 5.83301 11.5279V14.3056C5.83301 14.5024 5.88715 14.6673 5.99544 14.8004C6.10372 14.9335 6.2379 15.0001 6.39798 15.0001ZM8.60137 15.0001H10.2963C10.4564 15.0001 10.5905 14.9335 10.6988 14.8004C10.8071 14.6673 10.8613 14.5024 10.8613 14.3056V13.264C10.8613 13.0672 10.8071 12.8849 10.6988 12.7171C10.5905 12.5493 10.4564 12.4654 10.2963 12.4654H9.44883V11.8751H10.8613V10.8334H9.16634C9.00627 10.8334 8.87209 10.9 8.7638 11.0331C8.65551 11.1662 8.60137 11.3311 8.60137 11.5279V12.5695C8.60137 12.7663 8.65551 12.9428 8.7638 13.099C8.87209 13.2553 9.00627 13.3334 9.16634 13.3334H10.0138V13.9584H8.60137V15.0001ZM12.3302 15.0001H13.1776L14.1663 10.8334H13.3189L12.7539 13.2292L12.1889 10.8334H11.3415L12.3302 15.0001Z"
        fill={color}
      />
    </Svg>
  );
};

Icon.displayName = 'CustomCSV';

export const CustomCSV = memo<IconProps>(themed(Icon));
