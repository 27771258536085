'use client';

import { useStringFieldInfo, useTsController } from '@ts-react/form';
import { useEffect, useId, useRef, useState } from 'react';
import type { TextInput } from 'react-native';
import { Animated, Easing } from 'react-native';
import type { InputProps, YStackProps } from 'tamagui';
import { Fieldset, Input, isWeb, XStack, YStack } from 'tamagui';
import { shadowOpacity, shadowRadius } from '../../../tokens/shadow';
import { Label } from '../../Label';
import { Text } from '../../Text';

const INPUT_HEIGHT = 56;
const CHARACTER_TEXT_WIDTH = 20;

interface CharacterBoxProps extends YStackProps {
  secureTextEntry?: boolean;
  focused?: boolean;
  error?: boolean;
  disabled?: boolean;
  value: string;
}

export function CharacterBox({ secureTextEntry, focused, error, disabled, value, ...props }: CharacterBoxProps) {
  const borderColor = (() => {
    if (focused) {
      return '$form/color/form-border-selected';
    }
    if (error) {
      return '$form/color/form-border-danger';
    }
    return '$form/color/form-border-default';
  })();

  return (
    <YStack
      alignItems="center"
      justifyContent="center"
      disabled={disabled}
      flex={1}
      borderWidth={1}
      borderRadius="$radius.md"
      paddingHorizontal="$space.lg"
      paddingVertical="$space.lg"
      opacity={disabled ? 0.5 : 1}
      pointerEvents={disabled ? 'none' : 'auto'}
      backgroundColor={disabled ? '$form/color/form-fg-subdued' : '$form/color/form-bg-default'}
      borderColor={borderColor}
      shadowColor={borderColor}
      shadowOpacity={focused ? shadowOpacity.on : shadowOpacity.off}
      shadowRadius={focused ? shadowRadius.on : shadowRadius.off}
      borderTopWidth={2}
      borderLeftWidth={2}
      borderRightWidth={2}
      borderBottomWidth={2}
      {...props}
    >
      {focused && !value ? (
        <YStack width={CHARACTER_TEXT_WIDTH}>
          <FakeCaret />
        </YStack>
      ) : (
        <Text textAlign="center" width={CHARACTER_TEXT_WIDTH}>
          {secureTextEntry && value ? '●' : value}
        </Text>
      )}
    </YStack>
  );
}

export function FakeCaret() {
  const opacity = useRef(new Animated.Value(1)).current;

  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(opacity, {
          toValue: 0,
          easing: Easing.out(Easing.ease),
          duration: 240,
          delay: 360,
          useNativeDriver: true,
        }),
        Animated.timing(opacity, {
          toValue: 1,
          easing: Easing.out(Easing.ease),
          duration: 240,
          delay: 360,
          useNativeDriver: true,
        }),
      ])
    ).start();
  }, [opacity]);

  return (
    <Animated.View
      style={{
        opacity,
      }}
    >
      <Text variant="bodyMediumEm" textAlign="center">
        |
      </Text>
    </Animated.View>
  );
}

export interface PasswordCodeFieldProps
  extends Pick<InputProps, 'secureTextEntry' | 'testID' | 'disabled' | 'onChangeText'> {
  /**
   * Callback to trigger when a complete code has been entered
   */
  autoSubmit?: () => void | Promise<void>;
  isNumericOnly?: boolean;
  focusAfterSubmit?: boolean;
  // Should only be used for native or when this is the only input on screen
  autoFocus?: boolean;
  // Should only be used for native or when this is the only input on screen
  alwaysFocused?: boolean;
}

export function PasswordCodeField({
  secureTextEntry,
  testID,
  autoSubmit,
  isNumericOnly = true,
  focusAfterSubmit = true,
  autoFocus = false,
  alwaysFocused = false,
  disabled,
  onChangeText,
}: PasswordCodeFieldProps): JSX.Element {
  const {
    field,
    error, // zod error message
    formState,
  } = useTsController<string>();
  const { isSubmitting, isSubmitted } = formState;
  const isDisabled = disabled || isSubmitting;
  const zodFieldInfo = useStringFieldInfo();
  const { label, placeholder, isOptional, maxLength: zodMaxLength } = zodFieldInfo;
  const id = useId();
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<TextInput | null>(null);
  const [selection, setSelection] = useState<{
    start: number;
    end: number;
  }>({ start: 0, end: 0 });

  const maxLength = zodMaxLength ?? 6; // Default 6 max length

  // Always refocus the input if required
  useEffect(() => {
    if (alwaysFocused && !isFocused) {
      inputRef.current?.focus();
    }
  }, [alwaysFocused, isFocused]);

  // Refocus the input after submission if required
  useEffect(() => {
    if (focusAfterSubmit && !isSubmitting && isSubmitted && !isDisabled) {
      inputRef.current?.focus();
    }
  }, [focusAfterSubmit, isSubmitting, isSubmitted, isDisabled]);

  // Reset selection if field is reset
  useEffect(() => {
    if ((field.value ?? '').length === 0) {
      setSelection({ start: 0, end: 0 });
    }
  }, [field.value]);

  // Fix pasting from the middle of the input is limited by maxLength, for web only.
  // e.g. If maxLength is 6, and the input value is already '12345', pasting '9999' at the '3' position, will result only that position being replaced up to maxLength -> '129945'
  // In web, this will replace as many characters as needed, not just one, i.e. '129999'
  useEffect(() => {
    const handlePaste = (e: ClipboardEvent) => {
      const clipboardData = e.clipboardData?.getData('text');
      if (clipboardData) {
        e.stopPropagation();
        e.preventDefault();
        // If valid, remove the clipboard number of characters from the start of the selection
        const value = field.value ?? '';
        const maxNumberOfCharactersAllowed = maxLength - selection.start;
        const clipboardValue = clipboardData.slice(0, maxNumberOfCharactersAllowed);

        if (isNumericOnly && !/^\d+$/.exec(clipboardValue)) {
          return;
        } // Do nothing if pasted value is not all numbers

        const updatedValue = `${value.slice(0, selection.start)}${clipboardValue}${value.slice(
          selection.start + clipboardValue.length
        )}`;
        onChangeText?.(updatedValue);
        field.onChange(updatedValue);

        if (selection.start + clipboardValue.length < maxLength && updatedValue.length < maxLength) {
          setSelection({
            start: selection.start + clipboardValue.length,
            end: selection.start + clipboardValue.length,
          });
        } else {
          setSelection({
            start: selection.start + clipboardValue.length,
            end: selection.start + clipboardValue.length + 1,
          });
        }

        // Trigger onSubmit automatically when a full code has been entered
        if (autoSubmit && updatedValue.length === maxLength) {
          inputRef.current?.blur();
          void autoSubmit();
        }
      }
    };
    if (isWeb && inputRef.current) {
      (inputRef.current as unknown as HTMLInputElement).addEventListener('paste', handlePaste);
    }
    return () => {
      if (isWeb && inputRef.current) {
        (inputRef.current as unknown as HTMLInputElement).removeEventListener('paste', handlePaste);
      }
    };
  }, [autoSubmit, field, isNumericOnly, maxLength, onChangeText, selection.start]);

  return (
    <Fieldset gap="$space.sm">
      {Boolean(label) && (
        <Label
          htmlFor={id}
          testID={`${testID || field.name}-label`}
          variant="bodyMediumEm"
          color="$form/color/form-fg-default"
        >
          {label} {isOptional ? `(Optional)` : null}
        </Label>
      )}
      <YStack gap="$space.xs">
        <YStack height={INPUT_HEIGHT}>
          <Input
            ref={(ref) => {
              // Share react-hook-form ref
              field.ref(ref);
              inputRef.current = ref;
            }}
            id={id}
            testID={`${testID || field.name}-input-inner`}
            // eslint-disable-next-line jsx-a11y/no-autofocus -- this is a hidden input that may need to be focused
            autoFocus={autoFocus}
            autoComplete="off"
            autoCapitalize="none"
            disabled={isDisabled}
            inputMode={isNumericOnly ? 'numeric' : 'text'}
            maxLength={maxLength}
            selection={selection}
            onSelectionChange={(e) => {
              if (!('selection' in e.nativeEvent)) {
                return;
              }

              const eventSelection = e.nativeEvent.selection;

              setSelection((prevSelection) => {
                const currentSelection = eventSelection;
                const value = field.value ?? '';

                // Determine direction of selection based on arrow key presses.
                if (
                  currentSelection.start === currentSelection.end &&
                  prevSelection.start === prevSelection.end &&
                  currentSelection.start < prevSelection.start
                ) {
                  // Backwards, e.g. 3|3 -> 2|2. Need to select 2|3 next. This happens when selection is at the end of the input and then user presses left arrow.
                  return { start: currentSelection.start, end: currentSelection.start + 1 };
                } else if (
                  currentSelection.start === currentSelection.end &&
                  currentSelection.end < prevSelection.end &&
                  prevSelection.start === currentSelection.start
                ) {
                  // Backwards from range, e.g. 2|3 -> 2|2. Need to select 1|2 next.
                  // If 0|0, need to select 0|1 instead.
                  if (currentSelection.start === 0) {
                    e.preventDefault();
                    return { start: 0, end: 1 };
                  }

                  return { start: currentSelection.start - 1, end: currentSelection.start };
                } else if (
                  (currentSelection.start === currentSelection.end &&
                    prevSelection.start === prevSelection.end &&
                    currentSelection.end > prevSelection.end) ||
                  (currentSelection.start === currentSelection.end &&
                    currentSelection.start > prevSelection.start &&
                    prevSelection.end === currentSelection.end)
                ) {
                  // Forwards, e.g. 2|2 -> 3|3 or forwards from range, e.g. 2|3 -> 3|3. Need to select 3|4 next.
                  if (currentSelection.end === value.length && value.length === maxLength) {
                    e.preventDefault();
                    return { start: maxLength - 1, end: maxLength };
                  } else if (currentSelection.end < value.length) {
                    return { start: currentSelection.end, end: currentSelection.end + 1 };
                  }
                }

                // Occurs when selection directly navigated to. This should not normally happen.
                // e.g Home/End buttons or clicking on a specific part of the input.
                if (currentSelection.start === 0 && currentSelection.end === 0 && value.length > 0) {
                  e.preventDefault();
                  return { start: 0, end: 1 };
                } else if (
                  currentSelection.start === value.length &&
                  value.length === maxLength &&
                  currentSelection.start === currentSelection.end
                ) {
                  e.preventDefault();
                  return { start: maxLength - 1, end: maxLength };
                } else if (currentSelection.start === currentSelection.end && currentSelection.start < value.length) {
                  return { start: currentSelection.start, end: currentSelection.start + 1 };
                }

                // Normal input being typed
                return { ...eventSelection };
              });
            }}
            onKeyPress={(e) => {
              // When delete or backspace is pressed, keep the selection range if there are more values
              if (e.nativeEvent.key === 'Backspace' || e.nativeEvent.key === 'Delete') {
                const value = field.value ?? '';
                if (selection.start < value.length) {
                  const numberOfCharactersToDelete = Math.abs(selection.start - selection.end);
                  e.preventDefault();
                  const updatedValue = `${value.slice(0, selection.start)}${value.slice(
                    selection.start + numberOfCharactersToDelete
                  )}`;
                  onChangeText?.(updatedValue);
                  field.onChange(updatedValue);
                  if (value.length === maxLength && selection.start === maxLength - 1) {
                    // Keep selection at the end
                    setSelection({ start: maxLength - 1, end: maxLength - 1 });
                  } else {
                    // Keep selection at the same start
                    setSelection({ start: selection.start, end: selection.start + 1 });
                  }
                }
              }
            }}
            onChangeText={(updatedValue) => {
              if (isNumericOnly && updatedValue !== '' && !/^\d+$/.exec(updatedValue)) {
                // Don't move selection
                setSelection({ ...selection });
                return;
              }
              onChangeText?.(updatedValue);
              field.onChange(updatedValue);

              // Trigger onSubmit automatically when a full code has been entered
              if (autoSubmit && updatedValue.length === maxLength) {
                inputRef.current?.blur();
                void autoSubmit();
              }
            }}
            value={field.value ?? ''} // default empty string to prevent "uncontrolled to controlled" react warning
            placeholder={placeholder}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              setIsFocused(false);
            }}
            height="100%"
            color="$form/color/form-fg-default"
            // Hide input
            opacity={0}
            pointerEvents="none"
          />
          <XStack
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height="100%"
            gap="$space.sm"
            justifyContent="center"
            testID={`${testID || field.name}-input`}
            onPress={() => {
              inputRef.current?.focus();
            }}
            cursor="pointer"
            pointerEvents={isDisabled ? 'none' : 'auto'}
            disabled={isDisabled}
          >
            {Array(maxLength)
              .fill(0)
              .map((_, index) => {
                const focused =
                  // If input is focused and the selection range includes this index.
                  // Example 1, where selection is |abc. Selection is 0|0, so only the 'a' (index 0) character is focused.
                  // Example 2, where selection is a|bc|d. Selection is 1|3, so both 'b' (index 1), and 'c' (index 2) characters are focused.
                  isFocused && (selection.start === index || (index > selection.start && index < selection.end));
                return (
                  <CharacterBox
                    key={index}
                    value={field.value?.[index] ?? ''}
                    secureTextEntry={secureTextEntry}
                    focused={focused}
                    error={!!error?.errorMessage}
                    disabled={isDisabled}
                    cursor="pointer"
                    onPress={() => {
                      if (index >= (field.value ?? '').length) {
                        setSelection({
                          start: (field.value ?? '').length,
                          end: (field.value ?? '').length,
                        });
                      } else {
                        setSelection({
                          start: index,
                          end: index + 1,
                        });
                      }
                      inputRef.current?.focus();
                    }}
                  />
                );
              })}
          </XStack>
        </YStack>
        {error?.errorMessage ? (
          <Text variant="bodySmall" color="$form/color/form-fg-danger" selectable={false}>
            {error.errorMessage}
          </Text>
        ) : null}
      </YStack>
    </Fieldset>
  );
}
