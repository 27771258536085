import { Svg, Path } from 'react-native-svg';
import type { IconProps } from '@tamagui/helpers-icon';
import { themed } from '@tamagui/helpers-icon';
import { memo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- from tamagui/lucide-icons
const Icon = (props: any) => {
  const { color = 'black', size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 7a1 1 0 10-2 0v1h-1a3 3 0 100 6h1v2H8a1 1 0 100 2h3v1a1 1 0 102 0v-1h1a3 3 0 000-6h-1v-2h3a1 1 0 100-2h-3V7zm-2 3h-1a1 1 0 000 2h1v-2zm2 4v2h1a1 1 0 000-2h-1z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.614 1.21a1 1 0 00-1.228 0l-9 7A1 1 0 002 9v11a3 3 0 003 3h14a3 3 0 003-3V9a1 1 0 00-.386-.79l-9-7zM4 20V9.49l8-6.223 8 6.222V20a1 1 0 01-1 1H5a1 1 0 01-1-1z"
        fill={color}
      />
    </Svg>
  );
};

Icon.displayName = 'CustomHomeMortgage';

export const CustomHomeMortgage = memo<IconProps>(themed(Icon));
