import type { GetProps } from 'tamagui';
import { YStack, styled } from 'tamagui';
import { Text } from '../../Text';

const colorVariants = {
  active: {
    borderStyle: 'solid',
    backgroundColor: '$step-navigation/color/step-icon-selected',
    borderColor: '$step-navigation/color/step-icon-selected',
    color: '$step-navigation/color/step-icon-text-selected',
  },
  complete: {
    borderStyle: 'solid',
    backgroundColor: 'transparent',
    borderColor: '$step-navigation/color/step-fg-subdued',
    color: '$step-navigation/color/step-fg-subdued',
  },
  incomplete: {
    borderStyle: 'dotted',
    backgroundColor: 'transparent',
    borderColor: '$step-navigation/color/step-fg-subdued',
    color: '$step-navigation/color/step-fg-subdued',
  },
} as const;

const NumberIconBase = styled(YStack, {
  borderRadius: '$radius.round',
  borderWidth: '$size.step-navigation/size/step-number-icon-border-width',
  width: '$size.icon/size/sm',
  height: '$size.icon/size/sm',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',

  variants: {
    variant: colorVariants,
  },
} as const);

type NumberIconBaseProps = GetProps<typeof NumberIconBase>;

export interface NumberIconProps extends NumberIconBaseProps {
  children: string;
}

export function NumberIcon({ children, variant, ...props }: NumberIconProps) {
  const textColor = colorVariants[variant || 'active'].color;
  return (
    <NumberIconBase variant={variant} {...props}>
      <Text variant="bodySmallEm" color={textColor}>
        {children}
      </Text>
    </NumberIconBase>
  );
}
