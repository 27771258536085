'use client';
import { Fieldset, Stack, XStack, YStack } from 'tamagui';
import { useTranslation } from 'react-i18next';
import { useTsController } from '@ts-react/form';
import { z } from 'zod';
import { Input } from '../../Input';
import { Select } from '../../Select';
import { Text } from '../../Text';

export interface AmountFrequencyFieldProps {
  size?: '$lg' | '$md' | '$sm' | '$xl' | undefined;
  disabled?: boolean;
  testID?: string;
  hideError?: boolean;
  fieldLabel?: string;
}

export const createAmountFrequencySchema = (params?: {
  base?: z.RawCreateParams;
  amount?: z.ZodNumber | z.ZodOptional<z.ZodNumber>;
  frequency?: z.ZodString | z.ZodOptional<z.ZodString>;
}) => {
  return z.object(
    {
      amount:
        params?.amount ??
        z.number({
          required_error: 'This field is required',
          invalid_type_error: 'Please enter a valid number',
        }),
      frequency: params?.frequency ?? z.string({ required_error: 'This field is required' }),
    },
    params?.base ?? {
      required_error: 'This field is required',
    }
  );
};

export type AmountFrequencySchema = ReturnType<typeof createAmountFrequencySchema>;

export function AmountFrequencyField({
  size = '$lg',
  disabled,
  testID,
  hideError,
  fieldLabel,
}: AmountFrequencyFieldProps): JSX.Element {
  const { t } = useTranslation();
  const { field, error } = useTsController<z.infer<AmountFrequencySchema>>();
  const amount = field.value?.amount;
  const frequency = field.value?.frequency;

  const dispatchValue = (value: string) => {
    // Strip conversion
    const newValue = value.replace(/,/g, '');

    // Do not update value if a value is given that is not a number
    if (value !== '' && isNaN(Number(newValue))) {
      return;
    }

    // Do not update value if its over max safe value
    if (Number(newValue) > Number.MAX_SAFE_INTEGER) {
      return;
    }

    field.onChange({
      ...field.value,
      amount: newValue ? Number(newValue) : NaN,
    });
  };

  const convertValue = (value?: number) => {
    if (value !== 0 && !Number(value)) {
      return '';
    }

    return Intl.NumberFormat('en-EN').format(Number(value));
  };

  const displayValue = amount !== undefined ? convertValue(amount) : '';

  return (
    <Fieldset>
      <YStack gap="$lg">
        {fieldLabel ? <Text variant="bodyMediumEm">{fieldLabel}</Text> : null}
        <XStack gap="$xl" width="100%">
          <Stack testID={`${testID || field.name}-amount-container`} flex={1} flexBasis={0} width="100%">
            <Input
              testID={`${testID || field.name}-amount-input`}
              width="100%"
              size={size}
              disabled={disabled}
              inputMode="numeric"
              onBlur={field.onBlur}
              onChangeText={(text) => {
                dispatchValue(text);
              }}
              value={`${displayValue}`}
              error={!hideError ? error?.errorMessage || error?.amount?.errorMessage : ''}
              status={error?.errorMessage || error?.amount?.errorMessage ? 'error' : undefined}
              prefix="$"
            />
          </Stack>
          <Stack testID={`${testID || field.name}-frequency-container`} flex={1} flexBasis={0} width="100%">
            <Select
              ref={field.ref}
              width="100%"
              size={size}
              testID={`${testID || field.name}-frequency-input`}
              disabled={disabled}
              value={frequency ?? ''}
              error={error?.errorMessage || error?.frequency?.errorMessage}
              items={[
                { value: 'WEEKLY', label: t('lending.common.repaymentFrequency.weekly') },
                { value: 'FORTNIGHTLY', label: t('lending.common.repaymentFrequency.fortnightly') },
                { value: 'MONTHLY', label: t('lending.common.repaymentFrequency.monthly') },
                { value: 'YEARLY', label: t('lending.common.repaymentFrequency.annually') },
              ]}
              itemToValue={(item) => {
                return item?.value ?? '';
              }}
              itemToLabel={(item) => {
                return item?.label ?? '';
              }}
              onValueChange={(value) => {
                field.onChange({
                  ...field.value,
                  frequency: value,
                });
              }}
              placeholder="Select frequency"
            />
          </Stack>
        </XStack>
      </YStack>
    </Fieldset>
  );
}
