'use client';

import { z } from 'zod';
import { Fieldset } from 'tamagui';
import { useTsController } from '@ts-react/form';
import type { DefaultValues } from 'react-hook-form';
import type { TypeaheadFieldProps } from '../TypeaheadField';
import { TypeaheadField } from '../TypeaheadField';

export const createInstitutionFieldItemSchema = (params?: z.RawCreateParams) =>
  z.object(
    {
      institution: z.string(),
    },
    params ?? {
      required_error: 'Please select a financial institution',
    }
  );

export type InstitutionFieldSchema = ReturnType<typeof createInstitutionFieldItemSchema>;
export type InstitutionFieldItem = z.infer<InstitutionFieldSchema>;
export type InstitutionFieldProps = TypeaheadFieldProps<InstitutionFieldItem>;

export function InstitutionField(props: InstitutionFieldProps): JSX.Element {
  const { field, formState } = useTsController<InstitutionFieldProps>();

  const initialSearchValue =
    (formState.defaultValues?.[field.name] as DefaultValues<InstitutionFieldItem> | undefined)?.institution ?? '';

  return (
    <Fieldset>
      <TypeaheadField<InstitutionFieldItem> {...props} initialSearchValue={initialSearchValue} />
    </Fieldset>
  );
}
