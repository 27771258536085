import { Svg, Path } from 'react-native-svg';
import type { IconProps } from '@tamagui/helpers-icon';
import { themed } from '@tamagui/helpers-icon';
import { memo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- from tamagui/lucide-icons
const Icon = (props: any) => {
  const { color = 'black', size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" stroke="none" {...otherProps}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.004A5 5 0 008.186 2.77a5 5 0 00-5.413 5.4 5 5 0 000 7.656 5 5 0 005.412 5.4 5 5 0 007.642 0 4.998 4.998 0 005.402-5.414 4.998 4.998 0 000-7.628 4.998 4.998 0 00-5.415-5.414A5 5 0 0012 1.004zm3.707 9.703a1 1 0 00-1.414-1.414L11 12.586l-1.293-1.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
        fill={color}
      />
    </Svg>
  );
};

Icon.displayName = 'BadgeCheckFilled';

export const BadgeCheckFilled = memo<IconProps>(themed(Icon));
