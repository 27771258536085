import { createTsForm } from '@ts-react/form';
import { Form } from 'tamagui';
import type { FormProps, TamaguiComponent } from 'tamagui';
import { forwardRef } from 'react';
import { Button } from '../Button';
import { FormWrapper } from './FormWrapper';
import { mapping } from './mapping';
import { flattenRenderedElements, getEmptyFieldProps, mergeObjects } from './utils';

function FormComponent(props: FormProps) {
  return (
    /* Wrapping component with Tamagui's Form component
    This allows us to use Form.Trigger within our FormComponent's (ultimately SchemaForm's) children, e.g.:

    ```
      <SchemaForm>
        {(fields) => (
          <Form.Trigger asChild>
            <Button /> // This will submit the form
          </Form.Trigger>
        )}
      </SchemaForm>
    ```

    This isn't too useful though, as you can also submit the form using the `useForm` hook, which is a wrapper over react-hook-form's useForm hook.
    If you want a submit button outside of SchemaForm, you can't use Form.Trigger at all since it's not within SchemaForm, you'll need to use useForm

    ```
      const form = useForm(schema)

      <SchemaForm
        form={form}
      >
        {(fields) => (
            <Button onPress={() => {
                void form.handleSubmit(onSubmit)()
              }} 
            /> // This will submit the form
        )}
      </SchemaForm>
    ```

    Passing props to individual fields:

    All individual fields can receive certain props, like size, testID and some others, and can be passed like this:

    ```
      <SchemaForm
          form={form}
          schema={TestFormSchema}
          props={{
            customerNumber: {
              size: '$sm', // Custom size for customerNumber field, given `form` has a customerNumber field
            },
          }}
        />
    ```


    */
    <Form asChild {...props}>
      <FormWrapper tag="form">{props.children}</FormWrapper>
    </Form>
  );
}

// Creates a reusable form component which can map zod schemas fields to components
// https://react-ts-form.com/docs/api/api-docs/createtsform
const SchemaFormBase = createTsForm(mapping, {
  FormComponent,
});

export type SchemaFormProps = React.ComponentProps<typeof SchemaFormBase>;

// Looks redundant, but we need to assert `typeof SchemaFormBase` for the type inference of defaultValues, props, etc, to work
export const SchemaForm = forwardRef<TamaguiComponent, SchemaFormProps>(
  // Wrapping component to add additional padding for the form's body and footer
  function SchemaFormComponent({ children, renderAfter, schema, props, formProps, ...rest }, _ref) {
    const propsInjectedWithDisabled = mergeObjects(
      getEmptyFieldProps(schema, { disabled: !!formProps?.disabled }),
      props ?? {} // This will override the value of disabled if also in props, as it is more important/specific
    ) as SchemaFormProps['props'];

    return (
      <SchemaFormBase
        formProps={formProps}
        props={propsInjectedWithDisabled}
        schema={schema}
        // Wrap renderAfter with FormWrapper.Footer
        renderAfter={renderAfter ? (vars) => <FormWrapper.Footer>{renderAfter(vars)}</FormWrapper.Footer> : undefined}
        {...rest}
      >
        {/* Wrap children with FormWrapper.Body */}
        {/* Need to follow the same logic as the original FormChildren component - https://github.com/iway1/react-ts-form/blob/205892db84e4405328115b1de741a7a29d5f9e30/src/createSchemaForm.tsx#L588 */}
        {(fields) => {
          return (
            <FormWrapper.Body>
              {children ? children(fields) : flattenRenderedElements(fields)}
              {/* need this to cause enter key to submit form */}
              <Form.Trigger asChild>
                <Button style={{ display: 'none' }}>Submit</Button>
              </Form.Trigger>
            </FormWrapper.Body>
          );
        }}
      </SchemaFormBase>
    );
  }
) as typeof SchemaFormBase;
