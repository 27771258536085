// THIS IS AN AUTOGENERATED FILE. REGENERATE USING THE TOKENS2CODE FIGMA PLUGIN

import type { createTokens } from 'tamagui';
import {
  componentTokensColor,
  componentTokensRadius,
  componentTokensSize,
  componentTokensSpace,
  semanticTokensColor,
  semanticTokensRadius,
  semanticTokensSize,
  semanticTokensSpace,
} from './tokens';

const defaultSemanticTokenColors = semanticTokensColor.light;
const defaultComponentTokenColors = componentTokensColor.light;

export const designSystemsTokens = {
  color: {
    ...defaultSemanticTokenColors,
    ...defaultComponentTokenColors,
  },
  space: {
    true: semanticTokensSpace['2xl'],
    ...semanticTokensSpace,
    ...componentTokensSpace,
  },
  size: {
    true: semanticTokensSize['2xl'],
    ...semanticTokensSize,
    ...componentTokensSize,
  },
  radius: {
    true: semanticTokensRadius.lg,
    ...semanticTokensRadius,
    ...componentTokensRadius,
  },
  zIndex: {
    true: 1,
  },
} satisfies Parameters<typeof createTokens>['0'];

export const designSystemThemes = {
  light: {
    ...semanticTokensColor.light,
    ...componentTokensColor.light,
    background: semanticTokensColor.light['background/app'],
    backgroundFocus: semanticTokensColor.light['other/focus'],
    color: semanticTokensColor.light['text/app'],
    colorFocus: semanticTokensColor.light['other/focus'],
    colorHover: semanticTokensColor.light['text/primary-hover'],
  },
  dark: {
    ...semanticTokensColor.dark,
    ...componentTokensColor.dark,
    background: semanticTokensColor.dark['background/app'],
    backgroundFocus: semanticTokensColor.dark['other/focus'],
    color: semanticTokensColor.dark['text/app'],
    colorFocus: semanticTokensColor.dark['other/focus'],
    colorHover: semanticTokensColor.dark['text/primary-hover'],
  },
} as const;
