import { Stack } from 'tamagui';
import { Text } from '../../Text';
import { TableBase } from '../TableBase';

// TODO - Uplift to a basic reusable version when required that can be without edit

// Basic Responsive table with edit button
export const BasicResponsiveTableWithEditButton = ({
  headers,
  data,
  onEditClicked,
  sizeRatio,
}: {
  headers: string[];
  data: JSX.Element[][];
  onEditClicked: (rowIndex: number) => void;
  sizeRatio?: number[];
}) => {
  return (
    <Stack width="100%">
      <TableBase>
        <TableBase.Head>
          <TableBase.Row>
            {headers.map((header, index) => (
              <TableBase.Header
                key={index}
                width={sizeRatio?.[index] ? `${sizeRatio[index]}%` : `${90 / headers.length}%`}
              >
                <Text variant="bodyMediumEm">{header}</Text>
              </TableBase.Header>
            ))}
            <TableBase.Data width="10%" />
          </TableBase.Row>
        </TableBase.Head>

        <TableBase.Body>
          {data.map((rowData: JSX.Element[], rowIndex: number) => (
            <TableBase.Row key={rowIndex}>
              {rowData.map((cellData: JSX.Element, cellIndex: number) => (
                <TableBase.Data
                  key={cellIndex}
                  width={sizeRatio?.[cellIndex] ? `${sizeRatio[cellIndex]}%` : `${90 / headers.length}%`}
                >
                  {cellData}
                </TableBase.Data>
              ))}
              <TableBase.Data width="10%" height="100%" alignItems="center" justifyContent="center">
                <TableBase.EditButton
                  onPress={() => {
                    onEditClicked(rowIndex);
                  }}
                  testID={`edit-button-${rowIndex}`}
                />
              </TableBase.Data>
            </TableBase.Row>
          ))}
        </TableBase.Body>
      </TableBase>
    </Stack>
  );
};
