import type { ForwardedRef, PropsWithoutRef } from 'react';
import { forwardRef } from 'react';

export interface TaskRef {
  onSubmit: () => Promise<void> | void;
}

export function withTaskRef<Props extends object>(
  Component: (_: Props, __: ForwardedRef<TaskRef>) => JSX.Element,
  displayName?: string
) {
  const ComponentWithTaskRef = forwardRef<TaskRef, Props>(Component);

  ComponentWithTaskRef.displayName = displayName || Component.name;

  return {
    multiTask: (props: PropsWithoutRef<Props>, ref?: ForwardedRef<TaskRef>) => {
      return <ComponentWithTaskRef {...props} ref={ref} />;
    },
    normal: ComponentWithTaskRef,
  };
}
