import { Svg, Path } from 'react-native-svg';
import type { IconProps } from '@tamagui/helpers-icon';
import { themed } from '@tamagui/helpers-icon';
import { memo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- from tamagui/lucide-icons
const Icon = (props: any) => {
  const { color = 'black', size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" stroke="none" {...otherProps}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4a3 3 0 00-3 3v10a3 3 0 003 3h16a3 3 0 003-3V7a3 3 0 00-3-3H4zm17 5H3v2h18V9z"
        fill={color}
      />
    </Svg>
  );
};

Icon.displayName = 'CreditCardFilled';

export const CreditCardFilled = memo<IconProps>(themed(Icon));
