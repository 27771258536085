'use client';
import { useStringFieldInfo, useTsController } from '@ts-react/form';
import { useId } from 'react';
import { Fieldset } from 'tamagui';
import type { TextAreaProps } from '../../TextArea';
import { TextArea } from '../../TextArea';

export type TextAreaFieldProps = Pick<TextAreaProps, 'size' | 'onBlur' | 'disabled' | 'placeholder'> & {
  onChange?: (value: string) => void;
  maxLength?: number;
  autoFocus?: boolean;
  testID?: string;
};

export function TextAreaField(props: TextAreaFieldProps): JSX.Element {
  const { testID, onChange, onBlur, size = '$lg', disabled, maxLength: maxLengthOverride, autoFocus = false } = props;
  const { field, error, formState } = useTsController<string>();
  const { isSubmitting } = formState;
  const zodFieldInfo = useStringFieldInfo();
  const { placeholder, maxLength } = zodFieldInfo;
  const id = useId();
  const isDisabled = disabled || isSubmitting;

  return (
    <Fieldset>
      <TextArea
        ref={field.ref}
        id={id}
        size={size}
        // eslint-disable-next-line jsx-a11y/no-autofocus -- From input
        autoFocus={autoFocus}
        testID={`${testID || field.name}-input`}
        disabled={isDisabled}
        maxLength={maxLengthOverride || maxLength}
        onBlur={(e) => {
          field.onBlur();
          onBlur?.(e);
        }}
        onChangeText={(text) => {
          field.onChange(text);
          onChange?.(text);
        }}
        placeholder={placeholder}
        value={field.value ?? ''}
        error={error?.errorMessage}
      />
    </Fieldset>
  );
}
