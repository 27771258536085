'use client';

import { z } from 'zod';
import { Fieldset } from 'tamagui';
import type { TypeaheadFieldProps } from '../TypeaheadField';
import { TypeaheadField } from '../TypeaheadField';

export const createOccupationFieldItemSchema = (params?: z.RawCreateParams) =>
  z.object(
    {
      anzscoCode: z.string(),
      name: z.string(),
    },
    params ?? {
      required_error: 'Please select an occupation',
    }
  );

export type OccupationFieldSchema = ReturnType<typeof createOccupationFieldItemSchema>;
export type OccupationFieldItem = z.infer<OccupationFieldSchema>;
export type OccupationFieldProps = TypeaheadFieldProps<OccupationFieldItem>;

export function OccupationField(props: OccupationFieldProps): JSX.Element {
  return (
    <Fieldset>
      <TypeaheadField<OccupationFieldItem> {...props} />
    </Fieldset>
  );
}
